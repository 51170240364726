/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchClassProductOrServicesEnhanced = /* GraphQL */ `
  query SearchClassProductOrServicesEnhanced(
    $filter: AWSJSON
    $sort: AWSJSON
    $limit: Int
    $nextToken: [String]
  ) {
    searchClassProductOrServicesEnhanced(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const searchBrandsEnhanced = /* GraphQL */ `
  query SearchBrandsEnhanced(
    $filter: AWSJSON
    $sort: AWSJSON
    $limit: Int
    $nextToken: [String]
  ) {
    searchBrandsEnhanced(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getBrandType = /* GraphQL */ `
  query GetBrandType($id: ID!) {
    getBrandType(id: $id) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBrandTypes = /* GraphQL */ `
  query ListBrandTypes(
    $filter: ModelBrandTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBrandTypes = /* GraphQL */ `
  query SyncBrandTypes(
    $filter: ModelBrandTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBrandTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClassTitle = /* GraphQL */ `
  query GetClassTitle($id: ID!) {
    getClassTitle(id: $id) {
      id
      name
      description
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      items {
        items {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listClassTitles = /* GraphQL */ `
  query ListClassTitles(
    $filter: ModelClassTitleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassTitles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchClassTitles = /* GraphQL */ `
  query SearchClassTitles(
    $filter: SearchableClassTitleFilterInput
    $sort: SearchableClassTitleSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchClassTitles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const syncClassTitles = /* GraphQL */ `
  query SyncClassTitles(
    $filter: ModelClassTitleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClassTitles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getClassProductOrService = /* GraphQL */ `
  query GetClassProductOrService($id: ID!) {
    getClassProductOrService(id: $id) {
      id
      name
      baseNumber
      classID
      status
      _score
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      class {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listClassProductOrServices = /* GraphQL */ `
  query ListClassProductOrServices(
    $filter: ModelClassProductOrServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassProductOrServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listClassProductOrServicesByClass = /* GraphQL */ `
  query ListClassProductOrServicesByClass(
    $classID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClassProductOrServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassProductOrServicesByClass(
      classID: $classID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listClassProductOrServicesByClassAndStatus = /* GraphQL */ `
  query ListClassProductOrServicesByClassAndStatus(
    $classID: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassProductOrServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassProductOrServicesByClassAndStatus(
      classID: $classID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchClassProductOrServices = /* GraphQL */ `
  query SearchClassProductOrServices(
    $filter: SearchableClassProductOrServiceFilterInput
    $sort: SearchableClassProductOrServiceSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchClassProductOrServices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const syncClassProductOrServices = /* GraphQL */ `
  query SyncClassProductOrServices(
    $filter: ModelClassProductOrServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClassProductOrServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getFee = /* GraphQL */ `
  query GetFee($id: ID!) {
    getFee(id: $id) {
      id
      amount
      concept
      type
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFees = /* GraphQL */ `
  query ListFees(
    $filter: ModelFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        concept
        type
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const feesByTypeAndStatus = /* GraphQL */ `
  query FeesByTypeAndStatus(
    $type: FeeType
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feesByTypeAndStatus(
      type: $type
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        concept
        type
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFees = /* GraphQL */ `
  query SyncFees(
    $filter: ModelFeeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amount
        concept
        type
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrademarkPreApplicationAnonymous = /* GraphQL */ `
  query GetTrademarkPreApplicationAnonymous($id: ID!) {
    getTrademarkPreApplicationAnonymous(id: $id) {
      id
      name
      email
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      applicantName
      applicantSurname
      applicantSecondSurname
      applicantKind
      countryCallingCode
      phoneNumber
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productsServices {
        items {
          id
          productServiceID
          trademarkPreApplicationAnonymousID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listTrademarkPreApplicationAnonymouss = /* GraphQL */ `
  query ListTrademarkPreApplicationAnonymouss(
    $filter: ModelTrademarkPreApplicationAnonymousFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrademarkPreApplicationAnonymouss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTrademarkPreApplicationAnonymous = /* GraphQL */ `
  query SyncTrademarkPreApplicationAnonymous(
    $filter: ModelTrademarkPreApplicationAnonymousFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrademarkPreApplicationAnonymous(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrademarkPreApplication = /* GraphQL */ `
  query GetTrademarkPreApplication($id: ID!) {
    getTrademarkPreApplication(id: $id) {
      id
      name
      email
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      applicantName
      applicantSurname
      applicantSecondSurname
      applicantKind
      countryCallingCode
      phoneNumber
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productsServices {
        items {
          id
          productServiceID
          trademarkPreApplicationID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const listTrademarkPreApplications = /* GraphQL */ `
  query ListTrademarkPreApplications(
    $filter: ModelTrademarkPreApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrademarkPreApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTrademarkPreApplications = /* GraphQL */ `
  query SyncTrademarkPreApplications(
    $filter: ModelTrademarkPreApplicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrademarkPreApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrademarkApplicationAnonymousPs = /* GraphQL */ `
  query GetTrademarkApplicationAnonymousPs($id: ID!) {
    getTrademarkApplicationAnonymousPS(id: $id) {
      id
      productServiceID
      trademarkPreApplicationAnonymousID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      trademarkPreApplicationAnonymous {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listTrademarkApplicationAnonymousPSs = /* GraphQL */ `
  query ListTrademarkApplicationAnonymousPSs(
    $filter: ModelTrademarkApplicationAnonymousPSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrademarkApplicationAnonymousPSs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productServiceID
        trademarkPreApplicationAnonymousID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productService {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkPreApplicationAnonymous {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listProductsServicesByTrademarkPreApplicationAnonymous = /* GraphQL */ `
  query ListProductsServicesByTrademarkPreApplicationAnonymous(
    $trademarkPreApplicationAnonymousID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTrademarkApplicationAnonymousPSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsServicesByTrademarkPreApplicationAnonymous(
      trademarkPreApplicationAnonymousID: $trademarkPreApplicationAnonymousID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productServiceID
        trademarkPreApplicationAnonymousID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productService {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkPreApplicationAnonymous {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTrademarkApplicationAnonymousPs = /* GraphQL */ `
  query SyncTrademarkApplicationAnonymousPs(
    $filter: ModelTrademarkApplicationAnonymousPSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrademarkApplicationAnonymousPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productServiceID
        trademarkPreApplicationAnonymousID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productService {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkPreApplicationAnonymous {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrademarkApplicationPs = /* GraphQL */ `
  query GetTrademarkApplicationPs($id: ID!) {
    getTrademarkApplicationPS(id: $id) {
      id
      productServiceID
      trademarkPreApplicationID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      trademarkPreApplication {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const listTrademarkApplicationPSs = /* GraphQL */ `
  query ListTrademarkApplicationPSs(
    $filter: ModelTrademarkApplicationPSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrademarkApplicationPSs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productServiceID
        trademarkPreApplicationID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productService {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkPreApplication {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listProductsServicesByTrademarkPreApplication = /* GraphQL */ `
  query ListProductsServicesByTrademarkPreApplication(
    $trademarkPreApplicationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTrademarkApplicationPSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsServicesByTrademarkPreApplication(
      trademarkPreApplicationID: $trademarkPreApplicationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productServiceID
        trademarkPreApplicationID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productService {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkPreApplication {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTrademarkApplicationPs = /* GraphQL */ `
  query SyncTrademarkApplicationPs(
    $filter: ModelTrademarkApplicationPSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrademarkApplicationPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productServiceID
        trademarkPreApplicationID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productService {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkPreApplication {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrademarkApplication = /* GraphQL */ `
  query GetTrademarkApplication($id: ID!) {
    getTrademarkApplication(id: $id) {
      id
      brandDataID
      trademarkPreApplicationID
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      trademarkPreApplication {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandData {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandClasses {
        items {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const listTrademarkApplications = /* GraphQL */ `
  query ListTrademarkApplications(
    $filter: ModelTrademarkApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrademarkApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandDataID
        trademarkPreApplicationID
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        trademarkPreApplication {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTrademarkApplications = /* GraphQL */ `
  query SyncTrademarkApplications(
    $filter: ModelTrademarkApplicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrademarkApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        brandDataID
        trademarkPreApplicationID
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        trademarkPreApplication {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getBrandData = /* GraphQL */ `
  query GetBrandData($id: ID!) {
    getBrandData(id: $id) {
      id
      name
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      hasTransliteration
      transliteration
      hasTranslation
      translation
      hasBeenUsed
      dateOfInitialUse
      applicantID
      sameAddressForEstablishment
      establishmentDomicile {
        country
        postalCode
        state
        county
        suburb
        street
        externalNumber
        internalNumber
      }
      mustBill
      invoiceforApplicant
      applicantBillingID
      sameAddressForBilling
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      brandClasses {
        items {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      applicant {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
      applicantBilling {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listBrandDatas = /* GraphQL */ `
  query ListBrandDatas(
    $filter: ModelBrandDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listBrandDataByApplicant = /* GraphQL */ `
  query ListBrandDataByApplicant(
    $applicantID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBrandDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandDataByApplicant(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listBrandDataByApplicantBilling = /* GraphQL */ `
  query ListBrandDataByApplicantBilling(
    $applicantBillingID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBrandDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandDataByApplicantBilling(
      applicantBillingID: $applicantBillingID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBrandData = /* GraphQL */ `
  query SyncBrandData(
    $filter: ModelBrandDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBrandData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      brandName
      applicantName
      applicantSurname
      applicantSecondSurname
      typeID
      brandDataID
      classID
      trademarkApplicationID
      hasBeenRegisteredAbroad
      registrationDateAbroad
      registrationNumberAbroad
      countryOfRegistrationAbroad
      brandStage
      brandStatus
      trademarkStage
      requestNumber
      certificate
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      _score
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      class {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
      trademarkApplication {
        id
        brandDataID
        trademarkPreApplicationID
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        trademarkPreApplication {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandData {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      productsServices {
        items {
          id
          productServiceID
          brandID
          owner
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentRequests {
        items {
          id
          brandID
          paymentTransactionID
          concept
          description
          amount
          impiRights
          status
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notifications {
        items {
          id
          title
          body
          brandID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listBrandsByBrandData = /* GraphQL */ `
  query ListBrandsByBrandData(
    $brandDataID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandsByBrandData(
      brandDataID: $brandDataID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listBrandByTrademarkApplication = /* GraphQL */ `
  query ListBrandByTrademarkApplication(
    $trademarkApplicationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandByTrademarkApplication(
      trademarkApplicationID: $trademarkApplicationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listBrandsByOwnerAndTrademarkStage = /* GraphQL */ `
  query ListBrandsByOwnerAndTrademarkStage(
    $owner: String
    $trademarkStage: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandsByOwnerAndTrademarkStage(
      owner: $owner
      trademarkStage: $trademarkStage
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchBrands = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: SearchableBrandSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const syncBrands = /* GraphQL */ `
  query SyncBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBrands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getBrandProductsServices = /* GraphQL */ `
  query GetBrandProductsServices($id: ID!) {
    getBrandProductsServices(id: $id) {
      id
      productServiceID
      brandID
      owner
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listBrandProductsServicess = /* GraphQL */ `
  query ListBrandProductsServicess(
    $filter: ModelBrandProductsServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandProductsServicess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productServiceID
        brandID
        owner
        createdAt
        updatedAt
        deleted
        _version
        _deleted
        _lastChangedAt
        productService {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listProductsServicesByBrandByDeleted = /* GraphQL */ `
  query ListProductsServicesByBrandByDeleted(
    $brandID: ID
    $deleted: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBrandProductsServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsServicesByBrandByDeleted(
      brandID: $brandID
      deleted: $deleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productServiceID
        brandID
        owner
        createdAt
        updatedAt
        deleted
        _version
        _deleted
        _lastChangedAt
        productService {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBrandProductsServices = /* GraphQL */ `
  query SyncBrandProductsServices(
    $filter: ModelBrandProductsServicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBrandProductsServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productServiceID
        brandID
        owner
        createdAt
        updatedAt
        deleted
        _version
        _deleted
        _lastChangedAt
        productService {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getApplicant = /* GraphQL */ `
  query GetApplicant($id: ID!) {
    getApplicant(id: $id) {
      id
      name
      surname
      secondSurname
      kind
      rfc
      nationality
      countryCallingCode
      phoneNumber
      extension
      domicile {
        country
        postalCode
        state
        county
        suburb
        street
        externalNumber
        internalNumber
      }
      email
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brands {
        items {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      invoicedBrands {
        items {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listApplicants = /* GraphQL */ `
  query ListApplicants(
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncApplicants = /* GraphQL */ `
  query SyncApplicants(
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncApplicants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getPaymentTransaction = /* GraphQL */ `
  query GetPaymentTransaction($id: ID!) {
    getPaymentTransaction(id: $id) {
      id
      status
      transactionType
      chargeReferenceID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chargeReference {
        id
        openpayChargeID
        paymentTransactionID
        orderID
        amount
        reference
        method
        barcodeUrl
        documentUrl
        dueDate
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const listPaymentTransactions = /* GraphQL */ `
  query ListPaymentTransactions(
    $filter: ModelPaymentTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPaymentTransactions = /* GraphQL */ `
  query SyncPaymentTransactions(
    $filter: ModelPaymentTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPaymentTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getOpenPayChargeReference = /* GraphQL */ `
  query GetOpenPayChargeReference($id: ID!) {
    getOpenPayChargeReference(id: $id) {
      id
      openpayChargeID
      paymentTransactionID
      orderID
      amount
      reference
      method
      barcodeUrl
      documentUrl
      dueDate
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOpenPayChargeReferences = /* GraphQL */ `
  query ListOpenPayChargeReferences(
    $filter: ModelOpenPayChargeReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenPayChargeReferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        openpayChargeID
        paymentTransactionID
        orderID
        amount
        reference
        method
        barcodeUrl
        documentUrl
        dueDate
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const openPayChargesByOrderId = /* GraphQL */ `
  query OpenPayChargesByOrderId(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOpenPayChargeReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    openPayChargesByOrderID(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        openpayChargeID
        paymentTransactionID
        orderID
        amount
        reference
        method
        barcodeUrl
        documentUrl
        dueDate
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOpenPayChargeReferences = /* GraphQL */ `
  query SyncOpenPayChargeReferences(
    $filter: ModelOpenPayChargeReferenceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOpenPayChargeReferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        openpayChargeID
        paymentTransactionID
        orderID
        amount
        reference
        method
        barcodeUrl
        documentUrl
        dueDate
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPaymentRequest = /* GraphQL */ `
  query GetPaymentRequest($id: ID!) {
    getPaymentRequest(id: $id) {
      id
      brandID
      paymentTransactionID
      concept
      description
      amount
      impiRights
      status
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      Brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const listPaymentRequests = /* GraphQL */ `
  query ListPaymentRequests(
    $filter: ModelPaymentRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brandID
        paymentTransactionID
        concept
        description
        amount
        impiRights
        status
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        Brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listPaymentRequestsByBrand = /* GraphQL */ `
  query ListPaymentRequestsByBrand(
    $brandID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentRequestsByBrand(
      brandID: $brandID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandID
        paymentTransactionID
        concept
        description
        amount
        impiRights
        status
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        Brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPaymentRequests = /* GraphQL */ `
  query SyncPaymentRequests(
    $filter: ModelPaymentRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPaymentRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        brandID
        paymentTransactionID
        concept
        description
        amount
        impiRights
        status
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        Brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getOpenPayWebhookNotification = /* GraphQL */ `
  query GetOpenPayWebhookNotification($id: ID!) {
    getOpenPayWebhookNotification(id: $id) {
      id
      type
      verification_code
      transaction {
        id
        creation_date
        amount
        status
        description
        conciliated
        transaction_type
        error_message
        card {
          id
          type
          brand
          card_number
          holder_name
          expiration_month
          expiration_year
          allow_charges
          allow_payouts
          creation_date
          bank_name
          bank_code
        }
        method
        authorization
        order_id
        operation_type
        operation_date
        customer_id
        due_date
        currency
      }
      event_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOpenPayWebhookNotifications = /* GraphQL */ `
  query ListOpenPayWebhookNotifications(
    $filter: ModelOpenPayWebhookNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenPayWebhookNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        verification_code
        transaction {
          id
          creation_date
          amount
          status
          description
          conciliated
          transaction_type
          error_message
          method
          authorization
          order_id
          operation_type
          operation_date
          customer_id
          due_date
          currency
        }
        event_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOpenPayWebhookNotifications = /* GraphQL */ `
  query SyncOpenPayWebhookNotifications(
    $filter: ModelOpenPayWebhookNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOpenPayWebhookNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        verification_code
        transaction {
          id
          creation_date
          amount
          status
          description
          conciliated
          transaction_type
          error_message
          method
          authorization
          order_id
          operation_type
          operation_date
          customer_id
          due_date
          currency
        }
        event_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      title
      body
      brandID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        body
        brandID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationsByBrandAndCreatedAt = /* GraphQL */ `
  query NotificationsByBrandAndCreatedAt(
    $brandID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByBrandAndCreatedAt(
      brandID: $brandID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        body
        brandID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationsByOwnerAndCreatedAt = /* GraphQL */ `
  query NotificationsByOwnerAndCreatedAt(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByOwnerAndCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        body
        brandID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        body
        brandID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      type
      brandID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        brandID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const actionsByBrandAndCreatedAt = /* GraphQL */ `
  query ActionsByBrandAndCreatedAt(
    $brandID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionsByBrandAndCreatedAt(
      brandID: $brandID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        brandID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const actionsByOwnerAndCreatedAt = /* GraphQL */ `
  query ActionsByOwnerAndCreatedAt(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionsByOwnerAndCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        brandID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncActions = /* GraphQL */ `
  query SyncActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        brandID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
