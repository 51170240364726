import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Link, makeStyles } from '@material-ui/core'
import { Copyright } from '../components'
import { VERSION } from '../config'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  part1: {
    marginBottom: '1rem',
    padding: '1rem 0rem',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[700],
    width: '100%',
  },
  part1Link: {
    color: theme.palette.grey[100],
  },
  part2: {
    color: theme.palette.grey[400],
    width: '100%',
    textAlign: 'center',
  },
  version: {
    fontSize: '0.6rem',
  },
}))

const Footer = (props) => {
  const classes = useStyles()
  const { name, site, variant } = props
  return (
    <>
      <div className={classes.part1}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant={variant}
              align="center"
              gutterBottom
              color="textSecondary">
              <Link
                className={classes.part1Link}
                href="http://www.tmsolutions.mx/contacto-tm-solutions/">
                Contacto
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant={variant}
              align="center"
              gutterBottom
              color="textSecondary">
              <Link
                className={classes.part1Link}
                href="http://www.tmsolutions.mx/tyc/">
                Términos y Condiciones
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant={variant}
              align="center"
              gutterBottom
              color="textSecondary">
              <Link
                className={classes.part1Link}
                href="http://www.tmsolutions.mx/aviso-de-privacidad/">
                Aviso de Privacidad
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div className={classes.part2}>
        <Copyright name={name} site={site} variant="caption" align="center" />

        <Typography
          variant={variant}
          align="center"
          gutterBottom
          className={classes.version}>
          {' - v'}
          {VERSION}
        </Typography>
        <br />
      </div>
    </>
  )
}

Footer.propTypes = {
  name: PropTypes.string,
  site: PropTypes.string,
  variant: PropTypes.string,
}

Footer.defaultProps = {
  name: 'Trademark Solutions',
  site: 'http://www.tmsolutions.mx',
  variant: 'caption',
}

export default Footer
