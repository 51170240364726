import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  Typography,
  Button,
  Divider,
} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import InfoIcon from '@material-ui/icons/Info'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import clsx from 'clsx'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import { useNavigate } from '@reach/router'
import Title from '../common/text/Title'
import { mapIcon } from '../common'
import { useLayoutContext } from '../context'
import { useInfoModal } from '../../hooks'
import settingsTypes from './settingsTypes'

const useStyles = makeStyles((theme) => ({
  container: {},
  cardsGrid: {
    // justifyContent: 'space-around',
    // justifyContent: 'space-evenly',
    // justifyContent: 'space-between',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  cardItem: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  card: {
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    minWidth: '220px',
    maxWidth: '220px',
    [theme.breakpoints.up('lg')]: {
      minWidth: '280px',
      maxWidth: '280px',
    },
  },
  cardContent: {
    minHeight: '120px',
    maxHeight: '120px',
    [theme.breakpoints.up('lg')]: {
      minHeight: '140px',
      maxHeight: '140px',
    },
    backgroundColor: '#f9f9f9',
    color: theme.palette.primary.main,
  },
  cardContentIcon: {
    fontSize: 75,
  },
  cardAction: {
    backgroundColor: theme.palette.primary.contrastText,
    minHeight: '30px',
    maxHeight: '30px',
  },
  infoButton: {
    minWidth: '100%',
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
  },
  cardContentTrypography: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  cardHelperText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.light,
  },
  infoTitle: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  back: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

const Settings = () => {
  const classes = useStyles()
  const { setLoading } = useLayoutContext()
  const navigate = useNavigate()

  const { openModal } = useInfoModal()

  const openInformationModal = ({ title, text }) => {
    openModal({
      title: '',
      content: (
        <Grid container spacing={1} className={classes.popoverContent}>
          <Grid item xs={12}>
            <Typography className={classes.infoTitle}>{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.infoText}>{text}</Typography>
          </Grid>
        </Grid>
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  const renderInfoModal = ({ title, text }) => {
    return (
      <Button
        size="small"
        onClick={() => openInformationModal({ title, text })}
        startIcon={<InfoIcon className={clsx(classes.infoButton)} />}
        fullWidth
        className={clsx(classes.infoButton)}>
        <Typography className={classes.infoText}>
          {I18n.get('More information')}
        </Typography>
      </Button>
    )
  }

  renderInfoModal.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }

  useEffect(() => {
    setLoading(false)
  }, [setLoading])

  const handleSettingTypeSelection = (settingsType) => {
    setLoading(true)
    setTimeout(() => {
      navigate(settingsType.to)
    }, 300)
  }

  const handleReturn = () => {
    navigate('/')
  }

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                className={clsx(classes.back)}
                onClick={() => handleReturn()}>
                <ArrowBackIosIcon />
              </Grid>
              <Grid
                item
                className={clsx(classes.back)}
                onClick={() => handleReturn()}>
                <Typography>Regresar</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Title>
              <SettingsIcon fontSize="inherit" /> Configuración
            </Title>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Container className={classes.container} xl="true">
              <Grid container direction="row" className={classes.cardsGrid}>
                {settingsTypes.map((settingsType) => {
                  const { Icon: BrandIcon } = mapIcon(settingsType)
                  return (
                    <Grid
                      key={settingsType.id}
                      item
                      xs={10}
                      sm={5}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classes.cardItem}>
                      <Card className={classes.card}>
                        <CardActionArea
                          onClick={() =>
                            handleSettingTypeSelection(settingsType)
                          }>
                          <CardContent className={classes.cardContent}>
                            {BrandIcon && (
                              <BrandIcon className={classes.cardContentIcon} />
                            )}
                            <Typography
                              className={classes.cardContentTrypography}>
                              {settingsType.name}
                            </Typography>
                            <Typography className={classes.cardHelperText}>
                              {settingsType.helperText}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions className={classes.cardAction}>
                          {renderInfoModal({
                            title: settingsType.name,
                            text: settingsType.helperPopOverText,
                          })}
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Settings
