import React from 'react'
import { Router, useLocation } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { TrademarkRegistrationFlowPage } from '../registration'
import {
  TrademarkDetailsPage,
  Notifications,
  Trademarks,
  PaymentRequestPage,
} from '../account'
import { withMainLayout } from '../../layout'

import { useLayoutContext } from '../../components/context'

const useStyles = makeStyles(() => ({
  enabled: {
    display: 'block',
  },
  disabled: {
    display: 'none',
  },
}))

const HomePage = () => {
  const classes = useStyles()
  const {
    showNotifications,
    showTrademarks,
    setShowTrademarks,
    setShowNotifications,
    showTrademarkDetail,
    setShowTrademarkDetail,
  } = useLayoutContext()

  const location = useLocation()

  const evaluateLocationPath = (path) => {
    const pathStatus =
      path.includes('/paymentRequest/') ||
      path.includes('/detail/') ||
      path.includes('/trademarks') ||
      path.includes('/notifications')

    if (
      pathStatus &&
      !path.includes('/paymentRequest/') &&
      path.includes('/detail/')
    ) {
      setShowTrademarkDetail(true)
    }

    if (pathStatus && path.includes('/trademarks')) {
      setShowTrademarks(true)
    }

    if (pathStatus && path.includes('/notifications')) {
      setShowNotifications(true)
    }
    if (!pathStatus) {
      setShowTrademarks(false)
      setShowTrademarkDetail(false)
      setShowNotifications(false)
    }
    return pathStatus
  }
  evaluateLocationPath(location.pathname)
  return (
    <>
      <div
        className={
          showNotifications ||
          showTrademarks ||
          showTrademarkDetail ||
          evaluateLocationPath(location.pathname)
            ? classes.disabled
            : classes.enabled
        }>
        <TrademarkRegistrationFlowPage path="/" />
      </div>
      <Router>
        <Notifications path="notifications" />
        <Trademarks path="trademarks" />
        <TrademarkDetailsPage path="detail/:trademarkId" />
        <PaymentRequestPage path="detail/:trademarkId/paymentRequest/:paymentRequestId" />
      </Router>
    </>
  )
}

export default withMainLayout(HomePage)
