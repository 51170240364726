import React from 'react'
import { Router } from '@reach/router'
import { HomePage } from '../pages/home'
import { SettingsPage } from '../pages/settings'

const MainRoutes = () => (
  <Router>
    <HomePage path="/" default />
    <SettingsPage path="settings/*" />
  </Router>
)

export default MainRoutes
