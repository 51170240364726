import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useNavigate } from '@reach/router'
import clsx from 'clsx'
import _ from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import { I18n } from '@aws-amplify/core'
import {
  Grid,
  Typography,
  Paper,
  Chip,
  Card,
  Stepper,
  Step,
  StepLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {
  TRADEMARK_LIFECYCLE_STAGES,
  PAYMENT_TX_STATUS_PAID,
  PAYMENT_TX_STATUS_NOT_PAID,
  BRAND_STAGE_RECEIVED,
  brandClassColors,
  kindOfPerson,
  VALUE_NOT_AVAILABLE,
  COUNTRY_CODE_MEXICO,
  paymentRequestConcepts,
  paymentRequestStatus,
  voucherTypes,
  emailContactMessages,
  MAILTO_ADDRESS,
} from '../../config/constants'
import {
  getBrandById,
  getCountries,
  getDataFromPostalCode,
  getPaymentVoucher,
} from '../../services'
import { useLayoutContext } from '../context'
import { openFileAttachment, getFileFromS3 } from '../../services/registration'
// eslint-disable-next-line import/no-named-as-default
import Notification from './Notification'
// eslint-disable-next-line import/no-named-as-default
import VoucherView from './VoucherView'
import LoaderOverlay from '../common/LoaderOverlay'
import { dateFormat, countries, FormField } from '../common'

const useStyles = makeStyles((theme) => ({
  trademarkDetailsCardWeb: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  trademarkDetailsCardMobile: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mobileMainDetailContainer: {
    marginTop: theme.spacing(0),
  },
  mainDetailContainer: {
    marginTop: theme.spacing(2),
  },
  brandNameContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  brandName: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  brandSubtitle: {
    fontWeight: 600,
  },
  brandDataText: {
    fontWeight: 600,
  },
  brandDataTextContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  detailsFlexBox: {
    display: 'flex',
  },
  detailsContainer: {
    alignItems: 'stretch',
    justifyContent: 'align-items',
  },
  accordionTitle: {
    fontWeight: 600,
  },
  mobileStepLabel: {
    fontSize: '10px',
  },
  activeStep: {
    backgroundColor: '#3CBB44',
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  switchContainer: {
    marginTop: theme.spacing(1),
  },
  switch: {
    marginTop: theme.spacing(-1),
  },
  addressBox: {
    padding: theme.spacing(1),
  },
  addressTitle: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  mobileTextMargin: {
    marginLeft: theme.spacing(2),
  },
  mobileDetailsContainer: {
    justifyContent: 'center',
  },
  mobileTimelineContainer: {},
  back: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  fileChip: {
    backgroundColor: theme.palette.primary.light,
    cursor: 'pointer',
  },
  inactiveStep: {},
  divider: {
    height: theme.spacing(2),
  },
  MuiStepIconActive: {
    color: '#3CBB44',
  },
  paymentRequestCard: {
    padding: theme.spacing(2),
  },
  paymentRequestConceptLabel: {
    flex: 1,
    width: '100%',
  },
  active: {
    color: '#3CBB44 !important',
  },
  centerMobileDetails: {
    justifyContent: 'center',
  },
  noFiles: {
    fontWeight: 600,
  },
  date: {
    fontSize: '12px',
  },
  dateMobile: {
    fontSize: '12px',
    width: '100%',
  },
  fileChipsMobile: {
    marginBottom: theme.spacing(2),
  },
  notificationsContainer: {
    maxHeight: theme.spacing(50),
    overflow: 'scroll',
  },
  paymentRequestsContainer: {
    maxHeight: theme.spacing(50),
    overflow: 'scroll',
  },
  servicesContainer: {
    padding: theme.spacing(2),
    height: theme.spacing(20),
    maxHeight: theme.spacing(20),
    overflow: 'scroll',
  },
  voucherBtn: {
    width: '100%',
  },
  pending: {
    backgroundColor: '#E3CB1F',
  },
  accepted: {
    backgroundColor: '#3CBB44',
  },
  rejected: {
    backgroundColor: '#E3522B',
  },
  chipText: {
    fontWeight: 'bold',
    color: '#FFF',
  },
}))

export const TrademarkDetail = () => {
  const classes = useStyles()
  const theme = useTheme()
  const params = useParams()
  const navigate = useNavigate()
  const {
    setShowTrademarkDetail,
    setShowTrademarks,
    showTrademarkDetail,
    setLoading,
    loading,
    setEmailContactStatus,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const [expanded, setExpanded] = useState(false)
  const [trademarkDetailsExpanded, setTrademarkDetailsExpanded] = useState(true)
  const [trademark, setTrademark] = useState(undefined)
  const [paymentRequests, setPaymentRequests] = useState(undefined)
  const [countryOptions, setCountryOptions] = useState(null)
  const [stateOptions, setStateOptions] = useState(undefined)
  const [countyOptions, setCountyOptions] = useState(undefined)
  const [suburbOptions, setSuburbOptions] = useState(undefined)
  const [nationalityOptions, setNationalityOptions] = React.useState(null)
  // eslint-disable-next-line no-unused-vars
  const [establishmentStateOptions, setEstablishmentStateOptions] = useState(
    null
  )
  // eslint-disable-next-line no-unused-vars
  const [establishmentCountyOptions, setEstablishmentCountyOptions] = useState(
    null
  )
  // eslint-disable-next-line no-unused-vars
  const [establishmentSuburbOptions, setEstablishmentSuburbOptions] = useState(
    null
  )
  const [billingStateOptions, setBillingStateOptions] = useState(undefined)
  const [billingCountyOptions, setBillingCountyOptions] = useState(undefined)
  const [billingSuburbOptions, setBillingSuburbOptions] = useState(undefined)
  const [voucherViewEnabled, setVoucherViewEnabled] = useState(false)
  const [voucherUrl, setVoucherUrl] = useState('')
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  const retrievePostalCodeData = async (postalCode, addressType) => {
    setLoading(true)
    // eslint-disable-next-line no-empty
    const setApplicantOptionsEmpty = () => {
      setStateOptions([])
      setCountyOptions([])
      setSuburbOptions([])
    }

    const setEstablishmentOptionsEmpty = () => {
      setEstablishmentStateOptions([])
      setEstablishmentCountyOptions([])
      setEstablishmentSuburbOptions([])
    }

    const setBillingOptionsEmpty = () => {
      setBillingStateOptions([])
      setBillingCountyOptions([])
      setBillingSuburbOptions([])
    }

    if (postalCode) {
      const postalCodeData = await getDataFromPostalCode({ postalCode })
      if (!Array.isArray(postalCodeData) || postalCodeData.length === 0) {
        if (addressType === 'applicant') setApplicantOptionsEmpty()
        if (addressType === 'establishment') setEstablishmentOptionsEmpty()
        if (addressType === 'billing') setBillingOptionsEmpty()
      } else {
        const finalStateOptions = postalCodeData.map((p) => {
          return {
            value: p?.idEstado,
            label: p?.estado,
          }
        })

        const finalCountyOptions = postalCodeData.map((p) => {
          return {
            value: p?.idMunicipio,
            label: p?.municipio,
          }
        })

        const finalSuburbOptions = _.chain(postalCodeData)
          .map((p) => {
            return {
              value: p?.idColonia,
              label: p?.colonia,
            }
          })
          .orderBy((o) => o?.label)
          .value()

        const setApplicantOptionsValue = () => {
          setStateOptions([finalStateOptions[0]])
          setCountyOptions([finalCountyOptions[0]])
          setSuburbOptions(finalSuburbOptions)
        }

        const setEstablishmentOptionsValue = () => {
          setEstablishmentStateOptions([finalStateOptions[0]])
          setEstablishmentCountyOptions([finalCountyOptions[0]])
          setEstablishmentSuburbOptions(finalSuburbOptions)
        }

        const setBillingOptionsValue = () => {
          setBillingStateOptions([finalStateOptions[0]])
          setBillingCountyOptions([finalCountyOptions[0]])
          setBillingSuburbOptions(finalSuburbOptions)
        }

        if (addressType === 'applicant') setApplicantOptionsValue()
        if (addressType === 'establishment') setEstablishmentOptionsValue()
        if (addressType === 'billing') setBillingOptionsValue()
      }
    } else {
      if (addressType === 'applicant') setApplicantOptionsEmpty()
      if (addressType === 'establishment') setEstablishmentOptionsEmpty()
      if (addressType === 'billing') setBillingOptionsEmpty()
    }
    setLoading(false)
  }

  const fetchCountries = useMemo(
    () =>
      _.throttle(async (request, callback) => {
        const data = await getCountries()
        const sortedData = _.sortBy(data, (item) => {
          return item?.name
        })
        callback(sortedData)
      }, 1000),
    []
  )

  const fetchTrademarkData = async () => {
    const loadedTrademark = await getBrandById(params?.trademarkId)
    setTrademark(loadedTrademark)
    setPaymentRequests(loadedTrademark?.paymentRequests?.items)
    setContactSpeedDialEnabled(true)
    const email = emailContactMessages.authenticatedUserBrandDetail(
      loadedTrademark?.id,
      loadedTrademark?.brandData?.name,
      loadedTrademark?.owner
    )

    setEmailContactStatus({
      email: MAILTO_ADDRESS,
      subject: email?.subject,
      body: email?.message,
    })
    if (
      loadedTrademark?.brandData?.applicant?.domicile?.country ===
      COUNTRY_CODE_MEXICO
    ) {
      retrievePostalCodeData(
        loadedTrademark?.brandData?.applicant?.domicile?.postalCode,
        'applicant'
      )
    }
    if (
      loadedTrademark?.brandData?.establishmentDomicile &&
      !loadedTrademark?.brandData?.sameAddresForEstablishment
    ) {
      if (
        loadedTrademark?.brandData?.establishmentDomicile?.country ===
        COUNTRY_CODE_MEXICO
      ) {
        retrievePostalCodeData(
          loadedTrademark?.brandData?.establishmentDomicile?.domicile
            ?.postalCode,
          'establishment'
        )
      }
    }
    if (
      loadedTrademark?.brandData?.applicantBilling?.domicile?.country ===
      COUNTRY_CODE_MEXICO
    ) {
      retrievePostalCodeData(
        loadedTrademark?.brandData?.applicantBilling?.domicile?.postalCode,
        'billing'
      )
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    fetchCountries({}, (countries) => {
      const cOptions = countries?.map((c) => {
        return {
          value: c?.code,
          label: c?.name,
        }
      })
      const nOptions = _.chain(countries)
        .sortBy((item) => item.nationality)
        .map((c) => {
          return {
            value: c?.code,
            label: c?.nationality,
          }
        })
        .value()
      setCountryOptions(cOptions)
      setNationalityOptions(nOptions)
    })
  }, [fetchCountries])

  useEffect(() => {
    if (showTrademarkDetail && params) fetchTrademarkData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    setTrademarkDetailsExpanded(false)
  }

  const handleReturn = () => {
    setContactSpeedDialEnabled(false)
    setShowTrademarkDetail(false)
    setShowTrademarks(true)
    navigate('/trademarks')
  }

  const handleFileDownload = async (s3File) => {
    const generatedFile = await getFileFromS3({
      key: s3File.key,
      identityID: s3File.identityID,
      level: s3File.level,
      expires: 60,
      download: true,
    })
    openFileAttachment(
      generatedFile.Body,
      s3File.name || `${s3File?.key.substring(33, s3File?.key.length)}`,
      generatedFile.ContentType
    )
  }

  // eslint-disable-next-line consistent-return
  const evaluateBrandStage = (currentBrandStage, paymentStatus) => {
    if (paymentStatus !== PAYMENT_TX_STATUS_PAID) {
      return BRAND_STAGE_RECEIVED
    }
    if (paymentStatus === PAYMENT_TX_STATUS_PAID) {
      return currentBrandStage
    }
  }

  const showVoucherButton = () => {
    const show =
      (trademark?.trademarkApplication?.paymentTransaction?.transactionType ===
        voucherTypes.store ||
        trademark?.trademarkApplication?.paymentTransaction?.transactionType ===
          voucherTypes.bank) &&
      trademark?.trademarkApplication?.paymentTransaction?.status ===
        PAYMENT_TX_STATUS_NOT_PAID
    return show
  }

  const handlePaymentRequestView = (paymentRequestId) => {
    navigate(`detail/${params?.trademarkId}/paymentRequest/${paymentRequestId}`)
  }

  const handleRetrieveVoucher = async () => {
    setLoading(true)
    const voucher = await getPaymentVoucher({
      id: trademark?.trademarkApplication?.paymentTransactionID,
      type:
        trademark?.trademarkApplication?.paymentTransaction?.transactionType,
    })
    setVoucherUrl(voucher?.documentUrl)
    setVoucherViewEnabled(true)
  }

  // eslint-disable-next-line no-shadow
  const renderTimeline = (trademark) => {
    const currentStage = evaluateBrandStage(
      trademark?.brandStage,
      trademark?.trademarkApplication?.paymentTransaction?.status
    )
    return (
      <Stepper
        activeStep={TRADEMARK_LIFECYCLE_STAGES.findIndex((stage) => {
          return stage.stage === currentStage
        })}
        orientation="vertical">
        {TRADEMARK_LIFECYCLE_STAGES.map((stage) => (
          <Step key={stage.stage}>
            <StepLabel
              StepIconProps={{
                classes: { completed: classes.active },
              }}>
              {stage.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderMobileTimeline = (trademark) => {
    const currentStage = evaluateBrandStage(
      trademark?.brandStage,
      trademark?.trademarkApplication?.paymentTransaction?.status
    )
    return (
      <Stepper
        activeStep={TRADEMARK_LIFECYCLE_STAGES.findIndex((stage) => {
          return stage.stage === currentStage
        })}
        alternativeLabel>
        {TRADEMARK_LIFECYCLE_STAGES.map((stage) => (
          <Step key={stage.stage}>
            <StepLabel
              StepIconProps={{
                classes: { completed: classes.active },
              }}>
              <span classes={clsx(classes.mobileStepLabel)}>
                {stage.stage === currentStage ? stage.label : ''}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderTrademarkMainDetails = (trademark) => {
    const { class: trademarkClass } = trademark
    return (
      <Grid
        container
        className={clsx(
          isMobile || isTablet
            ? classes.mobileMainDetailContainer
            : classes.mainDetailContainer
        )}>
        <Grid item className={isMobile ? classes.centerMobileDetails : null}>
          <Grid item container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                className={clsx(classes.brandNameContainer)}
                variant={isMobile ? 'h6' : 'h6'}
                gutterBottom>
                <span className={clsx(classes.brandName)}>
                  {trademark?.brandData?.name}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Chip
                label={trademarkClass?.name}
                style={{
                  backgroundColor: '#fff',
                  border: `1px solid ${brandClassColors[trademark.classID]}`,
                  color: brandClassColors[trademark.classID],
                }}
              />
              <div className={classes.divider} />
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant={isMobile ? 'h7' : 'h6'} gutterBottom>
                Tipo:
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                variant={isMobile ? 'h7' : 'h6'}
                className={clsx(classes.brandDataText)}
                gutterBottom>
                {trademark?.brandData?.type?.name}
              </Typography>
              {isMobile ? <div className={classes.divider} /> : null}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant={isMobile ? 'h7' : 'h6'} gutterBottom>
                Número de Solicitud:
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                variant={isMobile ? 'h7' : 'h6'}
                className={clsx(classes.brandDataText)}
                gutterbottom>
                {trademark?.requestNumber ?? VALUE_NOT_AVAILABLE}
              </Typography>
              {isMobile ? <div className={classes.divider} /> : null}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant={isMobile ? 'h7' : 'h6'} gutterBottom>
                Número de Certificado:
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                variant={isMobile ? 'h7' : 'h6'}
                className={clsx(classes.brandDataText)}
                gutterBottom>
                {trademark?.certificate ?? VALUE_NOT_AVAILABLE}
              </Typography>
              {isMobile ? <div className={classes.divider} /> : null}
            </Grid>

            {
              /* eslint-disable-next-line no-nested-ternary */
              showVoucherButton() ? (
                /* eslint-disable-next-line no-nested-ternary */
                !isMobile ? (
                  <Grid item xs={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => handleRetrieveVoucher()}>
                      Consultar Nota de Pago
                    </Button>
                  </Grid>
                ) : null
              ) : null
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderTrademarkDetailsCardWeb = (trademark) => {
    return (
      <Grid container justify="center">
        <Grid item xs={6} className={classes.detailsFlexBox}>
          {renderTrademarkMainDetails(trademark)}
        </Grid>
        <Grid item xs={3}>
          {renderTimeline(trademark)}
        </Grid>
      </Grid>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderTrademarkDetailsCardMobile = (trademark) => {
    return (
      <div className={clsx(classes.mobileDetailsContainer)}>
        <Grid container>
          <Grid item xs={12} className={classes.detailsFlexBox}>
            {renderTrademarkMainDetails(trademark)}
          </Grid>
        </Grid>
        <div className={clsx(classes.mobileTimelineContainer)}>
          {renderMobileTimeline(trademark)}
        </div>
        {
          /* eslint-disable-next-line no-nested-ternary */
          showVoucherButton() ? (
            /* eslint-disable-next-line no-nested-ternary */
            isMobile ? (
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  className={clsx(classes.voucherBtn)}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => handleRetrieveVoucher()}>
                  Consultar Nota de Pago
                </Button>
              </Grid>
            ) : null
          ) : null
        }
      </div>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderApplicantDetailsContent = (trademark) => {
    const isNationalApplicant =
      trademark?.brandData?.applicant?.domicile?.country ===
        COUNTRY_CODE_MEXICO ?? VALUE_NOT_AVAILABLE

    const isNationalEstablishment =
      trademark?.brandData?.establishmentDomicile?.country ===
        COUNTRY_CODE_MEXICO ?? VALUE_NOT_AVAILABLE

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            Nombre del Solicitante:{' '}
            <span className={clsx(classes.brandDataText)}>
              {`${trademark?.brandData?.applicant?.name} ${trademark?.brandData?.applicant?.surname} ${trademark?.brandData?.applicant?.secondSurname}`}
            </span>
          </Typography>
          <Typography>
            Tipo de persona:{' '}
            <span className={clsx(classes.brandDataText)}>
              {
                // eslint-disable-next-line no-nested-ternary
                trademark?.brandData?.applicant?.kind === kindOfPerson[0].value
                  ? I18n.get(kindOfPerson[0].label)
                  : I18n.get(kindOfPerson[1].label)
              }
            </span>
          </Typography>
          <Typography>
            {trademark?.brandData?.applicant?.kind === kindOfPerson[1].value ? (
              <>
                <span>Razón social: </span>
                <span className={clsx(classes.brandDataText)}>
                  {trademark?.brandData?.name ?? VALUE_NOT_AVAILABLE}
                </span>
              </>
            ) : null}
          </Typography>
          <Typography>
            Nacionalidad:{' '}
            <span className={clsx(classes.brandDataText)}>
              {nationalityOptions?.find((nationality) => {
                return (
                  nationality.value ===
                  trademark?.brandData?.applicant?.nationality
                )
              })?.label ?? VALUE_NOT_AVAILABLE}
            </span>
          </Typography>
          <Typography>
            Teléfono:{' '}
            <span>
              {trademark?.brandData?.applicant?.phoneNumber
                ? `
              (+
              ${
                countries.find((country) => {
                  return (
                    country.code ===
                    trademark?.brandData?.applicant?.countryCallingCode
                  )
                })?.phone
              }) ${trademark?.brandData?.applicant?.phoneNumber}`
                : VALUE_NOT_AVAILABLE}
              &nbsp;
            </span>
          </Typography>
          <Paper className={clsx(classes.addressBox)}>
            <Grid item container>
              <Grid item xs={12}>
                <Typography className={clsx(classes.addressTitle)} gutterBottom>
                  Domicilio
                </Typography>
              </Grid>
              <div className={classes.divider} />
              <Grid item xs={12} md={6} lg={6}>
                <Typography>
                  País:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {countryOptions?.find((country) => {
                      return (
                        country.value ===
                        trademark?.brandData?.applicant?.domicile?.country
                      )
                    })?.label ?? VALUE_NOT_AVAILABLE}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography>
                  Estado:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isNationalApplicant
                        ? stateOptions
                          ? stateOptions[0].label
                          : VALUE_NOT_AVAILABLE
                        : trademark?.brandData?.applicant?.domicile?.state
                    }
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography>
                  Municipio:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isNationalApplicant
                        ? countyOptions
                          ? countyOptions[0].label
                          : VALUE_NOT_AVAILABLE
                        : trademark?.brandData?.applicant?.domicile?.county
                    }
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} lg={5}>
                <Typography>
                  Calle:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {trademark?.brandData?.applicant?.domicile
                      ? trademark?.brandData?.applicant?.domicile?.street
                      : VALUE_NOT_AVAILABLE}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Typography>
                  No. Ext:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {trademark?.brandData?.applicant?.domicile
                      ? trademark?.brandData?.applicant?.domicile
                          ?.externalNumber
                      : VALUE_NOT_AVAILABLE}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Typography>
                  No. Int:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {trademark?.brandData?.applicant?.domicile
                      ? trademark?.brandData?.applicant?.domicile
                          ?.internalNumber
                      : VALUE_NOT_AVAILABLE}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography>
                  Colonia:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isNationalApplicant
                        ? suburbOptions
                          ? suburbOptions[0].label
                          : VALUE_NOT_AVAILABLE
                        : trademark?.brandData?.applicant?.domicile?.suburb
                    }
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <div className={classes.divider} />
          {trademark?.brandData?.hasBeenUsed &&
          trademark?.brandData?.establishmentDomicile ? (
            <>
              <Paper className={clsx(classes.addressBox)}>
                <Grid item container>
                  <Grid item xs={12}>
                    <Typography
                      className={clsx(classes.addressTitle)}
                      gutterBottom>
                      Domicilio del Establecimiento
                    </Typography>
                  </Grid>
                  <div className={classes.divider} />
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography>
                      País:{' '}
                      <span className={clsx(classes.brandDataText)}>
                        {countryOptions?.find((country) => {
                          return (
                            country.value ===
                            trademark?.brandData?.establishmentDomicile?.country
                          )
                        })?.label ?? VALUE_NOT_AVAILABLE}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography>
                      Estado:{' '}
                      <span className={clsx(classes.brandDataText)}>
                        {
                          // eslint-disable-next-line no-nested-ternary
                          isNationalEstablishment
                            ? stateOptions
                              ? stateOptions[0].label
                              : VALUE_NOT_AVAILABLE
                            : trademark?.brandData?.establishmentDomicile?.state
                        }
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography>
                      Municipio:{' '}
                      <span className={clsx(classes.brandDataText)}>
                        {
                          // eslint-disable-next-line no-nested-ternary
                          isNationalEstablishment
                            ? countyOptions
                              ? countyOptions[0].label
                              : VALUE_NOT_AVAILABLE
                            : trademark?.brandData?.establishmentDomicile
                                ?.county
                        }
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <Typography>
                      Calle:{' '}
                      <span className={clsx(classes.brandDataText)}>
                        {trademark?.brandData?.establishmentDomicile
                          ? trademark?.brandData?.establishmentDomicile?.street
                          : VALUE_NOT_AVAILABLE}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <Typography>
                      No. Ext:{' '}
                      <span className={clsx(classes.brandDataText)}>
                        {trademark?.brandData?.establishmentDomicile
                          ? trademark?.brandData?.establishmentDomicile
                              ?.externalNumber
                          : VALUE_NOT_AVAILABLE}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <Typography>
                      No. Int:{' '}
                      <span className={clsx(classes.brandDataText)}>
                        {trademark?.brandData?.establishmentDomicile
                          ? trademark?.brandData?.establishmentDomicile
                              ?.internalNumber
                          : VALUE_NOT_AVAILABLE}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography>
                      Colonia:{' '}
                      <span className={clsx(classes.brandDataText)}>
                        {
                          // eslint-disable-next-line no-nested-ternary
                          isNationalEstablishment
                            ? suburbOptions
                              ? suburbOptions[0].label
                              : VALUE_NOT_AVAILABLE
                            : trademark?.brandData?.establishmentDomicile
                                ?.suburb
                        }
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </>
          ) : null}
          <div className={classes.divider} />
          <Grid item container>
            <Grid item xs={12} md={3} lg={3}>
              <Typography>Correo electrónico:</Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
              <Typography className={clsx(classes.brandDataText)}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  trademark?.brandData?.applicant?.email
                    ? trademark?.brandData?.applicant?.email
                    : VALUE_NOT_AVAILABLE
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderFiles = (files) => {
    if (!files || files.length === 0)
      return (
        <Typography variant="subtitle2" className={clsx(classes.noFiles)}>
          No hay ningún archivo adjunto...
        </Typography>
      )
    return (
      <Grid container spacing={1}>
        {files.map((file) => {
          return (
            <Grid key={file.key} item xs={12}>
              <Chip
                label={file?.name || file?.key.substring(33, file?.key.length)}
                className={clsx(classes.fileChip)}
                onClick={() => handleFileDownload(file)}
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  // eslint-disable-next-line no-unused-vars
  const renderDocumentationFiles = (files) => {
    if (!files || files.length === 0)
      return (
        <Typography variant="subtitle2" className={clsx(classes.noFiles)}>
          No hay ningún archivo adjunto...
        </Typography>
      )
    return (
      <Grid container spacing={1}>
        {files.map((file) => {
          return (
            <Grid key={file.key} item xs={12}>
              <Chip
                label={file?.name || file?.key.substring(30, file?.key.length)}
                className={clsx(classes.fileChip)}
                onClick={() => handleFileDownload(file)}
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderTrademarkDocumentsContent = (trademark) => {
    return (
      <Grid container>
        {trademark?.files && trademark?.files?.length > 0 ? (
          trademark?.files?.map((file) => {
            return (
              <Grid
                item
                key={file?.key}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className={clsx(isMobile ? classes.fileChipsMobile : null)}>
                <Chip
                  label={
                    file?.name || file?.key.substring(30, file?.key.length)
                  }
                  className={clsx(classes.fileChip)}
                  onClick={() => handleFileDownload(file)}
                />
              </Grid>
            )
          })
        ) : (
          <Typography>Aun no hay ningún documento.</Typography>
        )}
      </Grid>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderTrademarkDetailsContent = (trademark) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography className={clsx(classes.brandDataTextContainer)}>
            Marca:
            <span className={clsx(classes.brandDataText)}>
              {trademark?.brandData?.name ?? VALUE_NOT_AVAILABLE}
            </span>
          </Typography>
          <Typography>
            Tipo:{' '}
            <span className={clsx(classes.brandDataText)}>
              {trademark?.brandData?.type?.name ?? VALUE_NOT_AVAILABLE}
            </span>
          </Typography>
          <div className={clsx(classes.divider)} />
          <Typography>Archivos adjuntos:</Typography>
          {renderFiles(trademark?.brandData?.files)}
          <div className={clsx(classes.divider)} />
          {trademark?.brandData?.hasTransliteration ? (
            <Grid item xs={12} md={12}>
              <FormField
                id="transliteration"
                name="transliteration"
                label="Trasliteración"
                required
                variant="outlined"
                size="small"
                fullWidth
                // className={clsx(classes.margin, classes.noLabel)}
                multiline
                InputProps={{
                  value:
                    trademark?.brandData?.transliteration ??
                    VALUE_NOT_AVAILABLE,
                }}
                rows="4"
              />
            </Grid>
          ) : null}
          {trademark?.brandData?.hasTranslation ? (
            <>
              <div className={classes.divider} />
              <Grid item xs={12} md={12}>
                <FormField
                  id="translation"
                  name="translation"
                  label="Traducción"
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  // className={clsx(classes.margin, classes.noLabel)}
                  multiline
                  InputProps={{
                    value:
                      trademark?.brandData?.translation ?? VALUE_NOT_AVAILABLE,
                  }}
                  rows="4"
                />
              </Grid>
            </>
          ) : null}
          <Grid
            item
            container
            spacing={2}
            className={clsx(classes.switchContainer)}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography>
                Declaración de uso previo:{' '}
                <span className={classes.brandDataText}>
                  {trademark?.brandData?.hasBeenUsed
                    ? `Existe uso previo`
                    : `No existe uso previo`}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {trademark?.brandData?.dateOfInitialUse ? (
          <Typography>
            Fecha de inicio de uso:{' '}
            <span className={clsx(classes.brandDataText)}>
              {dateFormat(trademark?.brandData?.dateOfInitialUse, 'date')}
            </span>
          </Typography>
        ) : null}
      </Grid>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderBillingDetailsContent = (trademark) => {
    const isNationalBilling =
      trademark?.brandData?.applicantBilling?.domicile?.country ===
      COUNTRY_CODE_MEXICO

    return (
      <Grid container>
        <Grid item>
          <Typography>
            Tipo de persona:{' '}
            <span className={clsx(classes.brandDataText)}>
              {trademark?.brandData?.applicantBilling?.kind ===
              kindOfPerson[0].value
                ? I18n.get(kindOfPerson[0].label)
                : I18n.get(kindOfPerson[1].label)}
            </span>
          </Typography>
          <Typography>
            Razón Social:
            {trademark?.brandData?.applicantBilling?.kind ===
            kindOfPerson[1].value ? (
              <span className={clsx(classes.brandDataText)}>
                {trademark?.brandData?.name}
              </span>
            ) : (
              <span className={clsx(classes.brandDataText)}>
                {`${trademark?.brandData?.applicant?.name} ${trademark?.brandData?.applicant?.surname} ${trademark?.brandData?.applicant?.secondSurname}`}
              </span>
            )}
          </Typography>
          <Typography>
            Nacionalidad:{' '}
            <span className={clsx(classes.brandDataText)}>
              {nationalityOptions?.find((nationality) => {
                return (
                  nationality.value ===
                  trademark?.brandData?.applicantBilling?.nationality
                )
              })?.label ?? VALUE_NOT_AVAILABLE}
            </span>
          </Typography>
          <Typography>
            Teléfono:{' '}
            <span>
              {trademark?.brandData?.applicantBilling?.phoneNumber
                ? `
              (+
              ${
                countries.find((country) => {
                  return (
                    country.code ===
                    trademark?.brandData?.applicantBilling?.countryCallingCode
                  )
                })?.phone
              }) ${trademark?.brandData?.applicantBilling?.phoneNumber}`
                : VALUE_NOT_AVAILABLE}
              &nbsp;
            </span>
          </Typography>
          <Paper className={clsx(classes.addressBox)}>
            <Grid item container>
              <Grid item xs={12}>
                <Typography className={clsx(classes.addressTitle)}>
                  Domicilio de Facturación
                </Typography>
              </Grid>
              <div className={classes.divider} />
              <Grid item xs={12} md={6} lg={6}>
                <Typography>
                  País:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {countryOptions?.find((country) => {
                      return (
                        country.value ===
                        trademark?.brandData?.applicantBilling?.domicile
                          ?.country
                      )
                    })?.label ?? VALUE_NOT_AVAILABLE}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography>
                  Estado:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isNationalBilling
                        ? billingStateOptions
                          ? billingStateOptions[0].label
                          : VALUE_NOT_AVAILABLE
                        : trademark?.brandData?.applicantBilling?.domicile
                            ?.state
                    }
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography>
                  Municipio:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isNationalBilling
                        ? billingCountyOptions
                          ? billingCountyOptions[0].label
                          : VALUE_NOT_AVAILABLE
                        : trademark?.brandData?.applicantBilling?.domicile
                            ?.county
                    }
                  </span>
                </Typography>
              </Grid>
              <div className={classes.divider} />
              <Grid item xs={12} md={5} lg={5}>
                <Typography>
                  Calle:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {trademark?.brandData?.applicantBilling?.domicile
                      ? trademark?.brandData?.applicantBilling?.domicile?.street
                      : VALUE_NOT_AVAILABLE}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Typography>
                  No. Ext:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {trademark?.brandData?.applicantBilling?.domicile
                      ? trademark?.brandData?.applicantBilling?.domicile
                          ?.externalNumber
                      : VALUE_NOT_AVAILABLE}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Typography>
                  No. Int:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {trademark?.brandData?.applicantBilling?.domicile
                      ? trademark?.brandData?.applicantBilling?.domicile
                          ?.internalNumber
                      : VALUE_NOT_AVAILABLE}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography>
                  Colonia:{' '}
                  <span className={clsx(classes.brandDataText)}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isNationalBilling
                        ? billingSuburbOptions
                          ? billingSuburbOptions[0].label
                          : VALUE_NOT_AVAILABLE
                        : trademark?.brandData?.applicantBilling?.domicile
                            ?.suburb
                    }
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <div className={classes.divider} />
          <Grid item container>
            <Grid item xs={12} md={3} lg={3}>
              <Typography>Correo electrónico:</Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
              <Typography className={clsx(classes.brandDataText)}>
                {trademark?.brandData?.applicantBilling?.email
                  ? trademark?.brandData?.applicantBilling?.email
                  : VALUE_NOT_AVAILABLE}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderPaymentRequestsAccordion = () => {
    const sortedPaymentRequests = _.orderBy(
      paymentRequests,
      'createdAt',
      'desc'
    )
    return (
      <Grid container>
        <Grid item xs={12} className={clsx(classes.paymentRequestsContainer)}>
          {sortedPaymentRequests.map((paymentRequest) => {
            return (
              <>
                <Card
                  key={paymentRequest?.id}
                  className={clsx(classes.paymentRequestCard)}
                  variant="outlined">
                  <Grid container spacing={1}>
                    {isMobile ? (
                      <Grid item container spacing={1}>
                        <Grid item xs={4} container>
                          <Grid
                            item
                            xs={12}
                            className={clsx(classes.dateMobile)}>
                            {dateFormat(paymentRequest?.createdAt, 'datetime')}
                          </Grid>
                          <Grid item xs={12}>
                            <Chip
                              size="sm"
                              className={clsx(
                                classes[
                                  paymentRequest?.status
                                    ? paymentRequest?.status
                                    : 'pending'
                                ],
                                classes.chipText
                              )}
                              label={
                                paymentRequest?.status
                                  ? paymentRequestStatus[paymentRequest?.status]
                                      .label
                                  : 'Pendiente'
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={8} container>
                          <Grid item xs={12}>
                            <Typography
                              className={clsx(
                                classes.paymentRequestConceptLabel
                              )}>
                              {
                                paymentRequestConcepts[paymentRequest?.concept]
                                  ?.label
                              }
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              fullwidth="true"
                              onClick={() =>
                                handlePaymentRequestView(paymentRequest?.id)
                              }>
                              Ver Detalle
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item container spacing={1}>
                        <Grid item xs={3} sm={2} md={3} lg={2}>
                          <span className={clsx(classes.date)}>
                            {dateFormat(paymentRequest?.createdAt, 'datetime')}
                          </span>
                        </Grid>
                        <Grid item xs={5} sm={6} md={6} lg={4}>
                          {
                            paymentRequestConcepts[paymentRequest?.concept]
                              ?.label
                          }
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            fullwidth="true"
                            onClick={() =>
                              handlePaymentRequestView(paymentRequest?.id)
                            }>
                            Ver Detalle
                          </Button>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Chip
                            className={clsx(
                              classes[
                                paymentRequest?.status
                                  ? paymentRequest?.status
                                  : 'pending'
                              ],
                              classes.chipText
                            )}
                            label={
                              paymentRequest?.status
                                ? paymentRequestStatus[paymentRequest?.status]
                                    .label
                                : 'Pendiente'
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Card>
                <div className={clsx(classes.divider)} />
              </>
            )
          })}
        </Grid>
      </Grid>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderNotificationsAccordion = (trademark) => {
    const sortedNotifications = _.orderBy(
      trademark?.notifications?.items,
      'createdAt',
      'desc'
    )
    if (sortedNotifications?.notifications?.items?.length === 0)
      return <Typography>No hay ninguna notificación...</Typography>
    return (
      <Grid container className={clsx(classes.notificationsContainer)}>
        {sortedNotifications?.map((notification) => {
          return (
            <Grid key={notification?.id} item xs={12} sm={12}>
              <Notification notification={notification} />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  const renderSelectedProductsServices = (productsServices) => {
    const orderedProductsServices = _.chain(productsServices)
      .sortBy((ps) => ps.productService.name)
      .value()
    return (
      <Paper className={clsx(classes.servicesContainer)}>
        {orderedProductsServices.map((productService) => {
          return (
            <Typography
              key={productService.id}
              className={classes.brandDataText}>
              - {productService.productService.name}
            </Typography>
          )
        })}
      </Paper>
    )
  }

  // eslint-disable-next-line no-shadow
  const renderClassDetailsContent = (trademark) => {
    const { class: trademarkClass } = trademark
    return (
      <div className={classes.detailsContainer}>
        <Chip
          label={trademarkClass?.name}
          style={{
            backgroundColor: '#fff',
            border: `1px solid ${brandClassColors[trademark.classID]}`,
            color: brandClassColors[trademark.classID],
          }}
        />
        <div className={classes.divider} />
        <Typography>Servicios/Productos Seleccionados:</Typography>
        {renderSelectedProductsServices(trademark?.productsServices?.items)}
        <div className={classes.divider} />
        <Typography>
          Datos de la prioridad:{' '}
          <span className={classes.brandDataText}>
            {trademark?.hasBeenRegisteredAbroad
              ? `Has solicitado esta marca en el extranjero`
              : `No has solicitado esta marca en el extranjero`}
          </span>
        </Typography>
        {trademark?.hasBeenRegisteredAbroad ? (
          <>
            <Typography>
              Fecha de presentación de registro:{' '}
              <span className={classes.brandDataText}>
                {dateFormat(trademark?.registrationDateAbroad, 'date')}
              </span>
            </Typography>
            <Typography>
              Número de certificado:{' '}
              <span className={classes.brandDataText}>
                {trademark?.registrationNumberAbroad}
              </span>
            </Typography>
            <Typography>
              País de registro extranjero:{' '}
              <span className={classes.brandDataText}>
                {countryOptions?.find((country) => {
                  return (
                    country.value === trademark?.countryOfRegistrationAbroad
                  )
                })?.label ?? VALUE_NOT_AVAILABLE}
              </span>
            </Typography>
          </>
        ) : null}
      </div>
    )
  }

  const generateAccordion = (title, content, panel) => {
    return (
      <Accordion
        expanded={
          panel === 'trademarkPanel'
            ? expanded === panel || (trademarkDetailsExpanded && !isMobile)
            : expanded === panel
        }
        onChange={handleChange(panel)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${panel}bh-content`}
          id={`${panel}bh-header`}>
          <Typography className={classes.accordionTitle}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    )
  }

  // eslint-disable-next-line no-shadow
  const trademarkAccordionSections = (trademark) => {
    return [
      {
        id: 'trademark',
        content: generateAccordion(
          'Datos de la Marca',
          renderTrademarkDetailsContent(trademark),
          'trademarkPanel'
        ),
      },
      {
        id: 'documents',
        content: generateAccordion(
          'Documentación de la Marca',
          renderTrademarkDocumentsContent(trademark),
          'documentsPanel'
        ),
      },
      {
        id: 'class',
        content: generateAccordion(
          'Datos de la Clase',
          renderClassDetailsContent(trademark),
          'classPanel'
        ),
      },
      {
        id: 'applicant',
        content: generateAccordion(
          'Datos del Solicitante',
          renderApplicantDetailsContent(trademark),
          'applicantPanel'
        ),
      },
      {
        id: 'billing',
        content: generateAccordion(
          'Datos de Facturación',
          renderBillingDetailsContent(trademark),
          'billingPanel'
        ),
      },
      {
        id: 'paymentRequests',
        content:
          Array.isArray(paymentRequests) &&
          paymentRequests.length > 0 &&
          generateAccordion(
            'Ordenes de Pago',
            renderPaymentRequestsAccordion(),
            'paymentRequestsPanel'
          ),
      },
      {
        id: 'notifications',
        content: generateAccordion(
          'Notificaciones',
          renderNotificationsAccordion(trademark),
          'notificationsPanel'
        ),
      },
    ]
  }

  // eslint-disable-next-line no-shadow
  const renderAccordionSection = (trademark) => {
    return (
      <div>
        {trademarkAccordionSections(trademark).map((section) => {
          return section.content
        })}
      </div>
    )
  }

  const renderMobileView = (trademarkElement) => {
    return (
      <>
        <Card className={clsx(classes.trademarkDetailsCardMobile)}>
          <Grid container>
            {renderTrademarkDetailsCardMobile(trademarkElement)}
          </Grid>
        </Card>
        {renderAccordionSection(trademarkElement)}
      </>
    )
  }

  const renderWebView = (trademarkElement) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className={clsx(classes.trademarkDetailsCardWeb)}>
            {renderTrademarkDetailsCardWeb(trademarkElement)}
          </Card>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.divider} />
          {renderAccordionSection(trademarkElement)}
        </Grid>
      </Grid>
    )
  }

  return trademark ? (
    <>
      <LoaderOverlay open={loading} />
      <VoucherView
        url={voucherUrl}
        enabled={voucherViewEnabled}
        closeHandler={setVoucherViewEnabled}
      />
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            className={clsx(classes.back)}
            onClick={() => handleReturn()}>
            <ArrowBackIosIcon />
          </Grid>
          <Grid
            item
            className={clsx(classes.back)}
            onClick={() => handleReturn()}>
            <Typography>Regresar</Typography>
          </Grid>
        </Grid>
      </Grid>
      {isMobile || isTablet
        ? renderMobileView(trademark)
        : renderWebView(trademark)}
    </>
  ) : null
}

export default TrademarkDetail
