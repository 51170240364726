import React from 'react'

const icons = {
  width: 'auto',
  height: '35px',
  marginRight: '1em',
  marginLeft: '1em',
}

const longIcon = { ...icons, height: '50px' }
const smallIcon = { ...icons, height: '25px' }

export default function OpenPayLogos() {
  return (
    <div style={{ marginTop: '0.5em' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1em 1em 0.5em 1em',
          textAlign: 'center',
        }}>
        <img alt="visa" src="/visa.png" style={smallIcon} />
        <img alt="mastercard" src="/mastercard.png" style={smallIcon} />
        <img alt="amex" src="/amex.png" style={smallIcon} />
        <img alt="carnet" src="/carnet.png" style={icons} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1em 1em 0.2em 1em',
          flexWrap: 'wrap',
        }}>
        <img alt="hsbc" src="/hsbc.png" style={icons} />
        <img alt="scotia" src="/scotiabank.png" style={icons} />
        <img alt="banamex" src="/citibanamex.png" style={longIcon} />
        <img alt="azteca" src="/bancoAzteca.png" style={icons} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0em 1em 1em 1em',
          flexWrap: 'wrap',
        }}>
        <img alt="inbursa" src="/inbursa.png" style={longIcon} />
        <img alt="ixe" src="/ixe.png" style={icons} />
        <img alt="santander" src="/santander.png" style={longIcon} />
      </div>
    </div>
  )
}
