import { API } from 'aws-amplify'
import Debug from 'debug'
import {
  listBrandsByOwnerAndTrademarkStage,
  getBrand,
} from '../../graphql/custom-queries'
import { listPaymentRequestsByBrand } from '../../graphql/queries'
import { trademarkApplicationStages } from '../../config'

const debug = Debug('services:brands:brands')

const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const getBrandsByOwnerAndTrademarkStatus = async (owner) => {
  try {
    const input = {
      owner,
      trademarkStage: { eq: trademarkApplicationStages.completed },
    }
    const result = await API.graphql({
      query: listBrandsByOwnerAndTrademarkStage,
      variables: {
        ...input,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return result?.data?.listBrandsByOwnerAndTrademarkStage?.items
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const getBrandById = async (id) => {
  try {
    const result = await API.graphql({
      query: getBrand,
      variables: {
        id,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return result?.data?.getBrand
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const getBrandPaymentRequestsByBrandId = async (brandID) => {
  try {
    const paymentRequests = await API.graphql({
      query: listPaymentRequestsByBrand,
      variables: {
        brandID,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return paymentRequests?.data?.listPaymentRequestsByBrand?.items
  } catch (e) {
    debug('e', e)
    return null
  }
}
