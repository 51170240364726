import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
/* eslint-disable import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import { useNavigate } from '@reach/router'
import {
  Grid,
  Typography,
  Paper,
  Box,
  ButtonBase,
  Chip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useLayoutContext } from '../context'
import {
  PAYMENT_TX_STATUS_PAID,
  BRAND_STAGE_RECEIVED,
} from '../../config/constants'

const useStyles = makeStyles((theme) => ({
  trademarkContainer: {
    padding: theme.spacing(2),
  },
  trademarkContainerSpecialHeight: {
    height: '250px',
  },
  webTrademark: {
    marginBottom: theme.spacing(1),
    width: '100%',
    left: '0',
    '&:hover': {
      backgroundColor: '#B4D4EC',
    },
  },
  webTrademarkButton: {
    width: '100%',
  },
  mobileBox: {
    marginBottom: theme.spacing(1),
  },
  trademarkText: {
    fontWeight: 600,
  },
  trademarkTextContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mobileBrandTextContainer: {
    width: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  enabled: {
    display: 'block',
  },
  disabled: {
    display: 'none',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  received: {
    backgroundColor: theme.palette.brandStageColors.received,
  },
  paid: {
    backgroundColor: theme.palette.brandStageColors.paid,
  },
  inProcess: {
    backgroundColor: theme.palette.brandStageColors.inProcess,
  },
  presented: {
    backgroundColor: theme.palette.brandStageColors.presented,
  },
  finalized: {
    backgroundColor: theme.palette.brandStageColors.finalized,
  },
  created: {
    backgroundColor: theme.palette.brandStatusColors.created,
  },
  granted: {
    backgroundColor: theme.palette.brandStatusColors.granted,
  },
  denied: {
    backgroundColor: theme.palette.brandStatusColors.denied,
  },
  cancelled: {
    backgroundColor: theme.palette.brandStatusColors.cancelled,
  },
  divider: {
    height: theme.spacing(2),
  },
}))

export const Trademark = (props) => {
  const { trademark, isMobile } = props
  const classes = useStyles()
  const { setShowTrademarks, setShowTrademarkDetail } = useLayoutContext()
  const navigate = useNavigate()

  const navigateToPath = (path) => {
    setShowTrademarks(false)
    setShowTrademarkDetail(true)
    navigate(`detail/${path}`)
  }

  /* eslint-disable consistent-return */
  const evaluateBrandStage = (currentBrandStage, paymentStatus) => {
    if (paymentStatus !== PAYMENT_TX_STATUS_PAID) {
      return BRAND_STAGE_RECEIVED
    }
    if (paymentStatus === PAYMENT_TX_STATUS_PAID) {
      return currentBrandStage
    }
  }

  const renderTrademark = (trademarkElement) => {
    // eslint-disable no-restricted-globals
    // const mobileBrandNameTextWidth = screen.width - 5
    const currentStage = evaluateBrandStage(
      trademark?.brandStage,
      trademark?.trademarkApplication?.paymentTransaction?.status
    )
    const { class: trademarkClass } = trademarkElement
    return (
      <Grid
        container
        className={clsx(
          classes.trademarkContainer,
          !isMobile ? classes.trademarkContainerSpecialHeight : null
        )}>
        <Grid item xs={isMobile ? 10 : 12}>
          <Typography
            gutterBottom
            variant={isMobile ? 'subtitle1' : 'h6'}
            className={clsx(
              !isMobile
                ? classes.trademarkTextContainer
                : classes.mobileBrandTextContainer
            )}>
            <span className={clsx(classes.trademarkText)}>
              {trademarkElement?.brandData?.name}
            </span>
          </Typography>
          <Typography variant={isMobile ? 'subtitle1' : 'h6'} gutterBottom>
            Tipo de marca: {!isMobile ? <br /> : null}
            <span className={clsx(classes.trademarkText)}>
              {trademarkElement?.brandData?.type?.name}
            </span>
          </Typography>

          <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
            <span className={clsx(classes.trademarkText)}>
              {trademarkClass?.name}
            </span>
          </Typography>
          {!isMobile ? <div className={classes.divider} /> : null}
          <Grid item container justify="space-between">
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="subtitle2">Etapa de Marca</Typography>
              <Chip
                size="small"
                label={I18n.get(currentStage)}
                /* eslint-disable no-nested-ternary */
                className={classes[currentStage]}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="subtitle2">Estatus de Marca</Typography>
              <Chip
                size="small"
                label={I18n.get(trademark?.brandStatus)}
                className={classes[trademark.brandStatus]}
              />
            </Grid>
          </Grid>
        </Grid>
        {isMobile ? (
          <Grid item xs={2} className={clsx(classes.icon)}>
            <ArrowForwardIosIcon color="primary" />
          </Grid>
        ) : null}
      </Grid>
    )
  }

  const renderMobileTrademarkCard = (trademarkElement) => {
    return (
      <Box
        boxShadow={3}
        className={clsx(classes.mobileBox)}
        onClick={() => navigateToPath(trademarkElement?.id)}>
        {renderTrademark(trademarkElement)}
      </Box>
    )
  }

  const renderWebTrademarkCard = (trademarkElement) => {
    return (
      <ButtonBase
        className={classes.webTrademarkButton}
        onClick={() => navigateToPath(trademarkElement?.id)}
        focusRipple>
        <Paper elevation="3" className={classes.webTrademark}>
          {renderTrademark(trademarkElement)}
        </Paper>
      </ButtonBase>
    )
  }

  return isMobile
    ? renderMobileTrademarkCard(trademark)
    : renderWebTrademarkCard(trademark)
}

Trademark.propTypes = {
  trademark: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool,
}

Trademark.defaultProps = {
  isMobile: false,
}

export default Trademark
