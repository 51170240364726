import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import parseHtml from 'html-react-parser'
import { Grid, Typography, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { dateFormat } from '../common/Forms'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainContainer: {
    padding: theme.spacing(2),
    marginBottom: '10px',
  },
  mainMobileContainer: {
    padding: theme.spacing(2),
  },
  notificationContainer: {},
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {},
  textContainer: {},
  title: {},
  titleText: {},
  body: {},
  bodyText: {},
  date: {},
  dateText: {},
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  mobileNotificationRow: {
    borderTop: '1px solid #000',
  },
}))

export const Notification = (props) => {
  const classes = useStyles()
  const { notification, isMobile } = props

  return (
    <div className={clsx(classes.root)}>
      <Paper
        elevation={isMobile ? '1' : '3'}
        className={
          clsx(classes.mainContainer)
          /* isMobile ? classes.mainMobileContainer : classes.mainContainer */
          /* classes.mobileNotificationRow */
        }>
        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
            md={1}
            lg={1}
            className={clsx(classes.iconContainer)}>
            <NotificationsNoneIcon />
          </Grid>
          <Grid item xs={9} md={11} lg={11} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  {notification.title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {parseHtml(notification.body)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {dateFormat(notification?.createdAt, 'datetime')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={clsx(classes.link)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

Notification.propTypes = {
  notification: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool,
}

Notification.defaultProps = {
  isMobile: false,
}

export default Notification
