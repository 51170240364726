import React from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import clsx from 'clsx'
import { Typography, Button, IconButton, makeStyles } from '@material-ui/core'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import { isMobile } from 'react-device-detect'
import { useLayoutContext } from '../components/context'
import PopOverModal from '../components/common/PopOverModal'

const mobileResolution = window.width <= 500

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingRight: '0.7rem',
    maxHeight: '100%',
    '&>img': {
      height: '2.7rem',
    },
  },
  brand: {
    width: '100%',
    textDecoration: 'none',
  },
  headerTitle: {
    margin: 0,
    padding: 0,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bolder',
    lineHeight: '1em',
  },
  headerSubTitle: {
    marginTop: '0.3rem',
    padding: 0,
    fontWeight: 'normal',
    lineHeight: '1em',
    fontSize: '0.7rem',
    color: theme.palette.secondary.main,
    letterSpacing: '0.3rem',
    // width: '100%',
    // color: theme.palette.secondary.contrastText,
    // fontSize: '0.5em',
  },
  loginButtonContainer: {
    minWidth: theme.spacing(20),
  },
  loginButton: {
    width: '100%',
    color: theme.palette.grey[800],
    cursor: 'pointer',
    textTransform: 'none',
    fontWeight: 'bold',
  },
  mobileLoginButton: {
    color: '#000',
    cursor: 'pointer',
    textTransform: 'none',
  },
  loginPopoverButton: {
    color: theme.palette.grey[100],
    textTransform: 'none',
    float: 'right',
    cursor: 'pointer',
  },
  innerBtnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  innerLinkContainer: {
    width: '100%',
    marginTop: '5px',
  },
  registerLink: {
    fontSize: 11,
    color: '#388BF3',
    cursor: 'pointer',
  },
  home: {
    cursor: 'pointer',
  },
}))

const Header = () => {
  const classes = useStyles()
  const {
    showAuthUI,
    setShowAuthUI,
    authenticated,
    setAuthState,
    showAuthHeader,
  } = useLayoutContext()

  const activateAuthDialog = (authState, closeAction) => {
    setShowAuthUI(true)
    setAuthState(authState)
    closeAction()
  }

  const renderWebLoginButton = (closeAction) => {
    return (
      <Button
        color="secondary"
        onClick={() => activateAuthDialog('signIn', closeAction)}
        variant="contained"
        startIcon={<VpnKeyIcon />}>
        {I18n.get('Sign In')}
      </Button>
    )
  }

  const renderMobileLoginButton = (closeAction) => {
    return (
      <>
        <IconButton
          onClick={() => activateAuthDialog('signIn', closeAction)}
          color="secondary"
          variant="contained">
          <VpnKeyIcon />
        </IconButton>
      </>
    )
  }

  const renderLoginPopoverContent = (contentClass, closePopover) => {
    return (
      <div className={contentClass}>
        <div className={classes.innerBtnContainer}>
          {isMobile || mobileResolution
            ? renderMobileLoginButton(closePopover)
            : renderWebLoginButton(closePopover)}
        </div>
        <div className={classes.innerLinkContainer}>
          <Typography
            onClick={() => activateAuthDialog('signUp', closePopover)}
            className={classes.registerLink}>
            {I18n.get('No account? Sign up here')}
          </Typography>
        </div>
      </div>
    )
  }

  const renderLoginComponent = () => {
    return (
      <PopOverModal
        id="login-popover"
        buttonVariant="link"
        buttonClass={classes.loginPopoverButton}
        buttonText={I18n.get('Sign In')}
        renderContent={renderLoginPopoverContent}
      />
    )
  }

  const renderAccountAvatarIcon = () => {
    return null /* (
      <IconButton onClick={null} className={classes.loginButton}>
        <AccountBox />
      </IconButton>
    ) */
  }

  const loginComponent = showAuthUI ? () => {} : renderLoginComponent

  return (
    <>
      {/* <BrandingWatermarkIcon
        className={clsx(classes.icon, classes.home)}
        onClick={() => {
          redirectHome()
        }}
      /> */}

      <a className={classes.logo} href="/">
        <img src="/tms.png" alt="TM Solutions.png" />
      </a>
      <a href="/" className={classes.brand}>
        <Typography className={clsx(classes.headerTitle)} variant="h5" noWrap>
          TRADE MARK
        </Typography>
        <Typography className={classes.headerSubTitle}>SOLUTIONS</Typography>
      </a>

      {showAuthHeader && (
        <div className={classes.loginButtonContainer}>
          {authenticated ? renderAccountAvatarIcon() : loginComponent()}
        </div>
      )}
    </>
  )
}

export default Header
