import { API } from 'aws-amplify'
import Debug from 'debug'
import {
  OPENPAY_ERRORS,
  PAYMENT_METHOD_STORE,
  voucherTypes,
} from '../../config'

const debug = Debug('services:payment:payment')

const API_NAME = 'trademarkPlatformOpenPayApi'
const PAYMENT_ENDPOINT_CARD = '/cardPayment'
const PAYMENT_ENDPOINT_DEPOSIT = '/bankPayment'
const PAYMENT_ENDPOINT_STORE = '/storePayment'
const PAYMENT_ENDPOINT_TX = '/paymentTransaction'

export const createCardPayment = async ({
  id,
  token,
  deviceId,
  isAuthenticated,
}) => {
  try {
    const payload = {
      body: {
        id,
        token,
        deviceId,
        isAuthenticated,
      },
      headers: {},
    }
    const paymentResponse = await API.post(
      API_NAME,
      PAYMENT_ENDPOINT_CARD,
      payload
    )
    return paymentResponse
  } catch (e) {
    debug('e', e)
    const error = { errorMessage: 'Error al enviar la solicitud de pago' }
    const requestResponse = e?.request?.response
    error.errorMessage = `Ha habido un error al procesar su pago ${
      requestResponse ? `[${requestResponse?.errorCode || 0}].` : '.'
    }`

    // error.errorMessage =
    //   error.errorMessage || 'Ha habido un error al procesar su pago.'

    return error
  }
}

export const createPaymentRequestCardPayment = async ({
  id,
  token,
  deviceId,
  amount,
  name,
  email,
  isPaymentRequest,
}) => {
  try {
    const payload = {
      body: {
        id,
        token,
        deviceId,
        amount,
        name,
        email,
        isPaymentRequest,
      },
      headers: {},
    }
    const paymentRequestResponse = await API.post(
      API_NAME,
      PAYMENT_ENDPOINT_CARD,
      payload
    )
    return paymentRequestResponse
  } catch (e) {
    debug('e', e)
    const error = { errorMessage: 'Error al enviar la solicitud de pago' }
    if (e?.request) {
      const requestResponse = e.request.response
      if (requestResponse?.errorCode) {
        error.errorMessage = OPENPAY_ERRORS[requestResponse.errorCode]
      }
    }
    return error
  }
}

export const createStoreOrDepositPayment = async ({
  id,
  isAuthenticated,
  paymentMethod,
}) => {
  try {
    const payload = {
      body: {
        id,
        isAuthenticated,
      },
      headers: {},
    }
    const paymentResponse = await API.post(
      API_NAME,
      paymentMethod === PAYMENT_METHOD_STORE
        ? PAYMENT_ENDPOINT_STORE
        : PAYMENT_ENDPOINT_DEPOSIT,
      payload
    )
    return paymentResponse
  } catch (e) {
    debug('e', e)
    const error = { errorMessage: 'Error al enviar la solicitud de pago' }
    if (e?.request) {
      const requestResponse = e.request.response
      if (requestResponse?.errorCode) {
        error.errorMessage = OPENPAY_ERRORS[requestResponse.errorCode]
      }
    }
    return error
  }
}

export const createStoreOrDepositPaymentRequest = async ({
  id,
  amount,
  name,
  email,
  isPaymentRequest,
  paymentMethod,
}) => {
  try {
    const payload = {
      body: {
        id,
        amount,
        name,
        email,
        isPaymentRequest,
      },
      headers: {},
    }
    const paymentRequestResponse = await API.post(
      API_NAME,
      paymentMethod === PAYMENT_METHOD_STORE
        ? PAYMENT_ENDPOINT_STORE
        : PAYMENT_ENDPOINT_DEPOSIT,
      payload
    )
    return paymentRequestResponse
  } catch (e) {
    debug('e', e)
    const error = {
      errorMessage: 'Error al enviar la solicitud de orden de pago',
    }
    if (e) {
      const requestResponse = e.request.response
      if (requestResponse?.errorCode) {
        error.errorMessage = OPENPAY_ERRORS[requestResponse.errorCode]
      }
    }
    return error
  }
}

export const readPaymentTransactionStatus = async ({ id }) => {
  try {
    const payload = {
      headers: {},
    }
    const paymentTXResponse = await API.get(
      API_NAME,
      `${PAYMENT_ENDPOINT_TX}/${id}`,
      payload
    )
    return paymentTXResponse
  } catch (e) {
    debug('e', e)
    const error = {
      errorMessage: 'Error al recuperar el estatus de la transaccion',
    }
    return error
  }
}

export const getPaymentVoucher = async ({ id, type }) => {
  try {
    const payload = {
      headers: {},
    }
    const getPaymentVoucherResponse = await API.get(
      API_NAME,
      `${
        type === voucherTypes.bank
          ? PAYMENT_ENDPOINT_DEPOSIT
          : PAYMENT_ENDPOINT_STORE
      }/${id}`,
      payload
    )
    return getPaymentVoucherResponse
  } catch (e) {
    debug('e', e)
    const error = {
      errorMessage: 'Error al recuperar la nota de pago',
    }
    return error
  }
}
