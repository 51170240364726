import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'
import { Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  noLabel: {
    marginTop: theme.spacing(1),
  },
  selectedFiles: {
    // '& div > span': {
    '& > span': {
      display: 'none',
    },
  },
  dropzone: {
    flex: 1,
    // display: 'flex',
    // flexDirection: 'column',
    minHeight: '100px',
    alignItems: 'center',
    // padding: '20px',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: theme.palette.grey[400],
    // borderStyle: 'dashed',
    borderStyle: 'solid',
    // border: 'solid',
    // backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
    outline: 'none',
    transition: 'border .24s ease-in-out',
    // zIndex: theme.zIndex.drawer,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    '&.MuiDropzoneArea-active': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
  dropzoneError: {
    flex: 1,
    // display: 'flex',
    // flexDirection: 'column',
    minHeight: '100px',
    alignItems: 'center',
    // padding: '20px',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: theme.palette.error.main,
    // borderStyle: 'dashed',
    borderStyle: 'solid',
    // border: 'solid',
    // backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
    outline: 'none',
    // transition: 'border .24s ease-in-out',
    // zIndex: theme.zIndex.drawer,
    '&:hover': {
      borderColor: theme.palette.error.main,
      borderWidth: 2,
    },
  },
  dropzoneParagraph: {
    marginTop: 35,
    marginBottom: 0,
    fontSize: theme.typography.fontSize - 2,
    color: theme.palette.grey[600],
  },
  previewContainer: {
    // zIndex: theme.zIndex.drawer + 1200,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: theme.palette.grey[400],
    borderStyle: 'solid',
    width: '100% !important',
    margin: '0 !important',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
  previewItem: {
    // zIndex: theme.zIndex.drawer + 1201,
    paddingBottom: `${theme.spacing(1)}px !important`,
    color: theme.palette.grey[600],
  },
  previewItemDisabled: {
    '& > button': {
      display: 'none',
    },
  },
  previewImage: {
    // zIndex: theme.zIndex.drawer + 1202,
    position: 'relative',
    textAlign: 'center',
    '&:hover $smallPreviewImg': {
      opacity: 0.4,
    },
    '&:hover $removeBtn': {
      opacity: 1,
    },
    /*
    '@media((hover: hover)) and (pointer: fine)': {
    },
    '&:active $smallPreviewImg': {
      opacity: 0.4,
    },
    '&:active $removeBtn': {
      opacity: 1,
    }, */
  },
  defaultLabelClassName: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(0.65),
    paddingRight: theme.spacing(0.65),
  },
}))

const FileUpload = (props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const {
    id,
    name,
    label,
    className,
    labelClassName,
    required,
    disabled,
    variant,
    size,
    fullWidth,
    control,
    rules,
    inputProps,
    initialFiles,
    acceptedFiles,
    filesLimit,
    maxFileSize,
    dropzoneText,
    previewText,
    showAlerts,
    showPreviews,
    showPreviewsInDropzone,
    showFileNames,
    showFileNamesInPreview,
    useChipsForPreview,
    onChange,
    errors,
  } = props

  // eslint-disable-next-line no-unused-vars
  const error = _.get(errors, name)

  return (
    <FormControl
      size={size}
      variant={variant}
      className={clsx(classes.noLabel, classes.selectedFiles, className)}
      error={!!error}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ onChange: onChangeByControl, value }) => {
          return (
            <>
              <InputLabel
                id={id}
                className={clsx(
                  value?.length > 0 && classes.defaultLabelClassName,
                  labelClassName
                )}
                shrink={value?.length > 0}>
                {label}
              </InputLabel>
              <DropzoneArea
                // initialFiles={value}
                initialFiles={initialFiles}
                acceptedFiles={acceptedFiles}
                filesLimit={filesLimit}
                maxFileSize={maxFileSize}
                dropzoneText={dropzoneText}
                previewText={previewText}
                showAlerts={showAlerts}
                showPreviews={showPreviews}
                showPreviewsInDropzone={showPreviewsInDropzone}
                showFileNames={showFileNames}
                showFileNamesInPreview={showFileNamesInPreview}
                useChipsForPreview={useChipsForPreview}
                onChange={(files) => {
                  if (onChange) onChange(files)
                  // onChangeByControl(files.length > 0 ? files : undefined)
                  onChangeByControl(files)
                }}
                dropzoneClass={clsx(
                  classes.dropzone,
                  error && classes.dropzoneError
                )}
                dropzoneParagraphClass={classes.dropzoneParagraph}
                previewGridClasses={{
                  container: clsx(classes.previewContainer),
                  item: clsx(
                    classes.previewItem,
                    disabled && classes.previewItemDisabled
                  ),
                  image: clsx(classes.previewImage),
                }}
                previewGridProps={{
                  container: { spacing: 6, direction: 'row' },
                  item: {
                    xs: 12,
                    sm: 4,
                    md: 3,
                    lg: 3,
                    xl: 2,
                  },
                }}
                inputProps={inputProps}
                dropzoneProps={{
                  disabled,
                }}
                /* eslint-disable-next-line no-unused-vars */
                onDrop={(event) => {
                  enqueueSnackbar(
                    `Tu archivo está listo para ser anexado a la solicitud`,
                    {
                      preventDuplicate: true,
                      variant: 'success',
                    }
                  )
                }}
                /* eslint-disable-next-line no-unused-vars */
                onDropRejected={(event) => {
                  enqueueSnackbar(
                    `Solamente se permiten archivos GIF con un tamaño máximo de 5MB`,
                    {
                      preventDuplicate: true,
                      variant: 'warning',
                    }
                  )
                }}
              />
              {error?.message ? (
                <FormHelperText>{error.message}</FormHelperText>
              ) : null}
            </>
          )
        }}
      />
    </FormControl>
  )
}

FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  rules: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  onChange: PropTypes.func,
  errors: PropTypes.shape({}),
  initialFiles: PropTypes.arrayOf(File),
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  filesLimit: PropTypes.number,
  maxFileSize: PropTypes.number,
  dropzoneText: PropTypes.string,
  previewText: PropTypes.string,
  showAlerts: PropTypes.bool,
  showPreviews: PropTypes.bool,
  showPreviewsInDropzone: PropTypes.bool,
  showFileNames: PropTypes.bool,
  showFileNamesInPreview: PropTypes.bool,
  useChipsForPreview: PropTypes.bool,
}

FileUpload.defaultProps = {
  label: undefined,
  className: undefined,
  labelClassName: undefined,
  required: undefined,
  disabled: undefined,
  variant: 'outlined',
  size: undefined,
  fullWidth: true,
  rules: undefined,
  inputProps: undefined,
  onChange: undefined,
  errors: undefined,
  initialFiles: undefined,
  acceptedFiles: undefined,
  filesLimit: undefined,
  maxFileSize: undefined,
  dropzoneText: undefined,
  previewText: undefined,
  showAlerts: false,
  showPreviews: true,
  showPreviewsInDropzone: false,
  showFileNames: true,
  showFileNamesInPreview: true,
  useChipsForPreview: false,
}

export default FileUpload
