import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Container, Button, Grid, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import Title from '../../components/common/text/Title'
import { useTrademarkContext, useLayoutContext } from '../../components/context'
import { AuthEntry } from '../../components/security'
import { readTrademarkApplication, moveTrademarkToStage } from '../../services'
import { trademarkApplicationStages } from '../../config'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  nextButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nextButtonContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  nextButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  authEntry: {
    padding: 0,
  },
  authEntryNotAuthenticated: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(6),
    },
  },
  welcome: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
}))

const AccountCreation = () => {
  const classes = useStyles()
  const theme = useTheme()
  // eslint-disable-next-line no-unused-vars
  const {
    setLoading,
    authenticated,
    firstSignUp,
    currentUser,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const {
    setCurrentStep,
    trademarkAppID,
    setTrademarkApp,
    brand,
  } = useTrademarkContext()
  const { handleSubmit } = useForm({})

  // eslint-disable-next-line no-unused-vars
  const onSubmit = async (data) => {
    setLoading(true)
    const trademarkAppReaded = await readTrademarkApplication({
      id: trademarkAppID,
      authenticated,
    })
    const trademarkAppUpdated = await moveTrademarkToStage({
      trademarkApp: trademarkAppReaded,
      trademarkApplicationStage: trademarkApplicationStages.supplementalData,
      includeBrands: true,
      alterBrandData: true,
      authenticated,
    })
    setTrademarkApp({ ...trademarkAppUpdated })

    setContactSpeedDialEnabled(false)
    setCurrentStep((prevActiveStep) => prevActiveStep + 1)
    setLoading(false)
  }

  const renderNextButtons = () => {
    return (
      <div className={classes.nextButtons}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          className={classes.nextButtonContainer}>
          <Grid
            item
            xs={10}
            sm={4}
            md={3}
            lg={2}
            className={classes.nextButton}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Siguiente
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>Crear cuenta</Title>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={clsx(classes.welcome)}>
                Bienvenido {currentUser?.username}
              </Typography>
            </Grid>
          </Grid>

          {authenticated && renderNextButtons()}
        </Container>
      </div>
      {!authenticated ? (
        <Container className={clsx(classes.authEntry)}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              className={clsx(classes.authEntryNotAuthenticated)}>
              <AuthEntry
                // authState="signUp"
                authState={
                  authenticated
                    ? localStorage.getItem('amplify-authenticator-authState')
                    : 'signUp'
                }
                customAuthData={{ username: brand?.generalData?.email }}
                theme={theme}
                firstSignUp={firstSignUp}
                showCancelButton={false}
              />
            </Grid>
          </Grid>
        </Container>
      ) : null}
    </form>
  )
}

export default AccountCreation
