import React, { useState, useEffect } from 'react'
import { useNavigate } from '@reach/router'
import clsx from 'clsx'
import _ from 'lodash'
import {
  Slide,
  Container,
  Grid,
  Typography,
  Box,
  Divider,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useSnackbar } from 'notistack'
import { TrademarkList } from '../../components/account'
import { useLayoutContext, useTrademarkContext } from '../../components/context'
import { getBrandsByOwnerAndTrademarkStatus } from '../../services'
import { Title } from '../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  main: {
    justifyContent: 'center',
  },
  mainBox: {
    maxHeight: '100vh',
  },
  title: {
    padding: theme.spacing(2),
    marginBottom: '10px',
    color: theme.palette.primary.main,
  },
  trademarks: {
    maxHeight: '100vh',
    overflow: 'auto',
  },
  close: {
    marginTop: '5px',
  },
  separator: {
    height: '20px',
  },
  back: {
    cursor: 'pointer',
  },
}))

const Trademarks = () => {
  const [loadedTrademarks, setLoadedTrademarks] = useState(undefined)
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const {
    showTrademarks,
    setShowTrademarks,
    setShowTrademarkDetail,
    setShowNotifications,
    currentUser,
    setContactSpeedDialEnabled,
    contactSpeedtDialEnabled,
  } = useLayoutContext()
  const { reconfigureSpeedDialValues } = useTrademarkContext()
  const { enqueueSnackbar } = useSnackbar()
  // eslint-disable-next-line no-shadow
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const sortTrademarksByDateAndClass = (trademarks) => {
    console.log('trademarks: ', trademarks)
    return _.chain(trademarks)
      .orderBy(
        (trademark) => {
          return trademark?.brandData?.name
        },
        ['asc']
      )
      .orderBy('classID', ['asc'])
      .orderBy('createdAt', ['desc'])
      .value()
  }

  useEffect(() => {
    const fetchTrademarks = async () => {
      const fetchedTrademarks = await getBrandsByOwnerAndTrademarkStatus(
        currentUser.username
      )
      setLoadedTrademarks(sortTrademarksByDateAndClass(fetchedTrademarks))
    }
    if (currentUser && showTrademarks) {
      fetchTrademarks()
    }
  }, [currentUser, showTrademarks, contactSpeedtDialEnabled])

  const handleEmptyTrademarks = () => {
    enqueueSnackbar('No hay ninguna marca registrada', {
      preventDuplicate: true,
      variant: 'warning',
    })
    setShowTrademarks(false)
  }

  const handleReturn = () => {
    reconfigureSpeedDialValues()
    setContactSpeedDialEnabled(true)
    setShowTrademarks(false)
    setShowTrademarkDetail(false)
    setShowNotifications(false)
    navigate('/')
  }

  const render = loadedTrademarks
  if (!render) return null

  return (
    <>
      {(!loadedTrademarks || loadedTrademarks.length === 0) &&
      showTrademarks ? (
        handleEmptyTrademarks()
      ) : (
        <Slide
          mountOnEnter
          unmountOnExit
          in={showTrademarks}
          timeout={{ appear: 300, enter: 300, exit: 300 }}>
          {isMobileDevice ? (
            <Box
              bgcolor="white"
              top={0}
              p={2}
              position="absolute"
              zIndex="tooltip"
              className={classes.mainBox}>
              <Grid container className={classes.main}>
                <Grid container item xs={12} className={classes.title}>
                  <Grid item xs={12}>
                    <HighlightOffIcon onClick={() => handleReturn()} />
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className={classes.close}>
                    <Title>Marcas Registradas</Title>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.trademarks}>
                  <TrademarkList
                    trademarks={loadedTrademarks}
                    isMobile={isMobileDevice}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Container className={classes.root}>
              <Grid container className={classes.main}>
                <Grid item xs={12} className={classes.title}>
                  <Grid container>
                    <Grid
                      item
                      className={clsx(classes.back)}
                      onClick={() => handleReturn()}>
                      <ArrowBackIosIcon />
                    </Grid>
                    <Grid
                      item
                      className={clsx(classes.back)}
                      onClick={() => handleReturn()}>
                      <Typography>Regresar</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid item xs={12}>
                    <Title>Marcas Registradas</Title>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.trademarks}>
                  <TrademarkList
                    trademarks={loadedTrademarks}
                    isMobile={isMobileDevice}
                  />
                </Grid>
              </Grid>
            </Container>
          )}
        </Slide>
      )}
    </>
  )
}

export default Trademarks
