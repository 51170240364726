import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { TrademarkDetail } from '../../components/account'
import { withMainAuthenticator } from '../../components/security'

export const TrademarkDetailsPage = () => {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <TrademarkDetail />
        </Grid>
        <Grid item />
      </Grid>
    </Container>
  )
}

export default withMainAuthenticator(TrademarkDetailsPage)
