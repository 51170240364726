import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useMediaQuery, useTheme, makeStyles } from '@material-ui/core'
import { useLayoutContext } from '../context/LayoutContext'

const useStyles = makeStyles((theme) => ({
  voucherViewWeb: {
    height: theme.spacing(120),
    width: '100%',
  },
  voucherViewMobile: {
    height: '100vh',
    width: '100%',
  },
}))

export const VoucherView = (props) => {
  const { url, enabled, closeHandler } = props
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  const isTablet = useMediaQuery(useTheme().breakpoints.down('sm'))
  const { setLoading } = useLayoutContext()
  const classes = useStyles()

  const handleClose = () => {
    closeHandler(false)
  }

  return (
    <div>
      <Dialog
        fullScreen={isMobile || isTablet}
        fullWidth={!isMobile && !isTablet}
        maxWidth="md"
        open={enabled}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <iframe
            className={clsx(
              isMobile || isTablet
                ? classes.voucherViewMobile
                : classes.voucherViewWeb
            )}
            key="store-voucher-reference-document"
            title="pdf"
            src={url}
            type="application/pdf"
            onLoad={() => setLoading(false)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

VoucherView.propTypes = {
  url: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default VoucherView
