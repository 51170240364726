export const searchProductsAndServicesQuery = /* GraphQL */ `
  query searchProductsAndServices(
    $filter: SearchableClassProductOrServiceFilterInput
    $sort: SearchableClassProductOrServiceSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchClassProductOrServices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        classID
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        baseNumber
        status
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      total
    }
  }
`

export const searchProductsAndServicesEnhancedQuery = /* GraphQL */ `
  query SearchClassProductOrServicesEnhanced(
    $filter: AWSJSON
    $sort: AWSJSON
    $limit: Int
    $nextToken: [String]
  ) {
    searchClassProductOrServicesEnhanced(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        classID
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        _score
        baseNumber
        status
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      total
    }
  }
`

export const getTrademarkApplication = /* GraphQL */ `
  query GetTrademarkApplication($id: ID!) {
    getTrademarkApplication(id: $id) {
      id
      brandDataID
      trademarkPreApplicationID
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      trademarkPreApplication {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          level
          identityID
          name
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          items {
            id
            productServiceID
            productService {
              id
              name
              classID
              class {
                id
                name
                description
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              baseNumber
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            trademarkPreApplicationID
            owner
            createdAt
            updatedAt
            _version
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandData {
        id
        name
        typeID
        files {
          bucket
          region
          key
          level
          identityID
          name
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          domicile {
            country
            postalCode
            state
            county
            suburb
            street
            externalNumber
            internalNumber
          }
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          domicile {
            country
            postalCode
            state
            county
            suburb
            street
            externalNumber
            internalNumber
          }
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandClasses {
        items {
          id
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          productsServices(deleted: { eq: "false" }) {
            items {
              id
              productServiceID
              productService {
                id
                name
                classID
                class {
                  id
                  name
                  description
                  status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                baseNumber
                status
                createdAt
                updatedAt
                _version
                _deleted
              }
              brandID
              owner
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          class {
            id
            name
            description
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`

export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      brandDataID
      brandData {
        id
        name
        typeID
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        files {
          bucket
          region
          key
          level
          identityID
          name
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          domicile {
            country
            postalCode
            state
            county
            suburb
            street
            externalNumber
            internalNumber
          }
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          domicile {
            country
            postalCode
            state
            county
            suburb
            street
            externalNumber
            internalNumber
          }
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      classID
      class {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      trademarkApplicationID
      trademarkApplication {
        id
        brandDataID
        trademarkPreApplicationID
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        brandData {
          id
          name
          typeID
          files {
            bucket
            region
            key
            level
            identityID
            name
          }
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      productsServices(deleted: { eq: "false" }) {
        items {
          id
          productServiceID
          productService {
            id
            name
            classID
            class {
              id
              name
              description
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            baseNumber
            status
            createdAt
            updatedAt
            _version
            _deleted
          }
          brandID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      hasBeenRegisteredAbroad
      registrationDateAbroad
      registrationNumberAbroad
      countryOfRegistrationAbroad
      brandStage
      brandStatus
      trademarkStage
      requestNumber
      certificate
      files {
        bucket
        region
        key
        level
        identityID
        name
      }
      paymentRequests {
        items {
          id
          brandID
          paymentTransactionID
          concept
          description
          amount
          impiRights
          status
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notifications {
        items {
          id
          title
          body
          brandID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listBrandsByOwnerAndTrademarkStage = /* GraphQL */ `
  query ListBrandsByOwnerAndTrademarkStage(
    $owner: String
    $trademarkStage: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandsByOwnerAndTrademarkStage(
      owner: $owner
      trademarkStage: $trademarkStage
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          paymentTransaction {
            id
            status
          }
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          type {
            id
            name
          }
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
