import React, { useState, useEffect } from 'react'
import { Container, useMediaQuery, useTheme } from '@material-ui/core'
import { useParams } from '@reach/router'
import { isMobile } from 'react-device-detect'
import { getPaymentRequestById } from '../../services'
import { PaymentRequest } from '../../components/account'

const PaymentRequestPage = () => {
  const [paymentRequest, setPaymentRequest] = useState(undefined)

  const params = useParams()

  // eslint-disable-next-line no-unused-vars
  const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('xs'))

  const fetchPaymentRequestData = async (paymentRequestID) => {
    const loadedPaymentRequest = await getPaymentRequestById(paymentRequestID)
    setPaymentRequest(loadedPaymentRequest)
  }

  useEffect(() => {
    fetchPaymentRequestData(params.paymentRequestId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const render = paymentRequest
  if (!render) return null

  return isMobile ? (
    <PaymentRequest paymentRequest={paymentRequest} />
  ) : (
    <Container>
      <PaymentRequest paymentRequest={paymentRequest} />
    </Container>
  )
}

export default PaymentRequestPage
