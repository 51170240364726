import React, { useEffect } from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import PropTypes from 'prop-types'
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import clsx from 'clsx'
import { mapIcon, brandTypes, BrandTypeModal } from '../../components'
import { useLayoutContext } from '../../components/context/LayoutContext'
import { useInfoModal } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  container: {},
  cardsGrid: {
    // justifyContent: 'space-around',
    // justifyContent: 'space-evenly',
    // justifyContent: 'space-between',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  cardItem: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  card: {
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    minWidth: '220px',
    maxWidth: '220px',
    [theme.breakpoints.up('lg')]: {
      minWidth: '280px',
      maxWidth: '280px',
    },
  },
  cardContent: {
    minHeight: '120px',
    maxHeight: '120px',
    [theme.breakpoints.up('lg')]: {
      minHeight: '140px',
      maxHeight: '140px',
    },
    backgroundColor: '#f9f9f9',
    color: theme.palette.primary.main,
  },
  cardContentIcon: {
    fontSize: 75,
  },
  cardAction: {
    backgroundColor: theme.palette.primary.contrastText,
    minHeight: '30px',
    maxHeight: '30px',
  },
  infoButton: {
    minWidth: '100%',
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
  },
  cardContentTrypography: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  cardHelperText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.light,
  },
}))

const BrandTypeSelection = (props) => {
  const classes = useStyles()
  const { openModal } = useInfoModal()

  const openInformationModal = ({ id, title, text, images }) => {
    openModal({
      title: '',
      content: (
        <BrandTypeModal id={id} title={title} text={text} images={images} />
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  const renderInfoModal = ({ id, title, text, images }) => {
    return (
      <Button
        size="small"
        onClick={() => openInformationModal({ id, title, text, images })}
        startIcon={<InfoIcon className={clsx(classes.infoButton)} />}
        fullWidth
        className={clsx(classes.infoButton)}>
        <Typography className={classes.infoText}>
          {I18n.get('What is this type of brand?')}
        </Typography>
      </Button>
    )
  }

  renderInfoModal.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
  }
  const { setFirstSignUp, setContactSpeedDialEnabled } = useLayoutContext()

  useEffect(() => {
    setContactSpeedDialEnabled(false)
  }, [setContactSpeedDialEnabled])

  const handleBrandTypeSelection = (event, brandType) => {
    setFirstSignUp(false)
    const { onCardClick } = props
    if (onCardClick) {
      onCardClick(brandType)
    }
  }

  // https://stackoverflow.com/questions/46656476/rendering-empty-space-in-react
  return (
    <Container className={classes.container} xl="true">
      <div style={{ textAlign: 'center' }}>
        <h2>Selecciona el tipo de registro que deseas hacer</h2>
      </div>
      <Grid container direction="row" className={classes.cardsGrid}>
        {brandTypes.map((brandType) => {
          const { Icon: BrandIcon } = mapIcon(brandType)
          return (
            <Grid
              key={brandType.id}
              item
              xs={10}
              sm={5}
              md={4}
              lg={4}
              xl={4}
              className={classes.cardItem}>
              <Card className={classes.card}>
                <CardActionArea
                  onClick={(e) => handleBrandTypeSelection(e, brandType.id)}>
                  <CardContent className={classes.cardContent}>
                    {BrandIcon && (
                      <BrandIcon className={classes.cardContentIcon} />
                    )}
                    <Typography className={classes.cardContentTrypography}>
                      {brandType.name}
                    </Typography>
                    <Typography className={classes.cardHelperText}>
                      {brandType.helperText}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardAction}>
                  {renderInfoModal({
                    id: brandType.id,
                    title: brandType.name,
                    text: brandType.helperPopOverText,
                    images: brandType.helperImages,
                  })}
                </CardActions>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}

BrandTypeSelection.propTypes = {
  onCardClick: PropTypes.func,
}
BrandTypeSelection.defaultProps = {
  onCardClick: () => {},
}

export default BrandTypeSelection
