import React from 'react'
import { PropTypes } from 'prop-types'
import clsx from 'clsx'
/* eslint-disable import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import {
  Container,
  Typography,
  Paper,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { Trademark } from './Trademark'

const useStyles = makeStyles((theme) => ({
  trademarkListContainer: {
    padding: theme.spacing(2),
    overflowY: 'scroll',
    height: '100vh',
  },
  trademark: {
    height: '100%',
  },
}))

const TrademarkList = (props) => {
  const { trademarks, isMobile } = props
  const classes = useStyles()

  const renderWebView = () => {
    return (
      <Grid container spacing={2}>
        {trademarks.map((trademark) => {
          return (
            <Grid
              key={trademark?.id}
              item
              xs={4}
              className={clsx(classes.trademark)}>
              <Trademark
                key={trademark.id}
                trademark={trademark}
                isMobile={isMobile}
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  const renderMobileView = () => {
    return (
      <>
        {trademarks.map((trademark) => {
          return (
            <Trademark
              key={trademark.id}
              trademark={trademark}
              isMobile={isMobile}
            />
          )
        })}
      </>
    )
  }

  const renderTrademarks = (trademarkList) => {
    if (!trademarkList || trademarkList.length === 0)
      return (
        <Paper elevation="3">
          <Typography>
            {I18n.get('There are no registered trademarks')}
          </Typography>
        </Paper>
      )
    return isMobile ? renderMobileView() : renderWebView()
  }

  return isMobile ? (
    renderTrademarks(trademarks)
  ) : (
    <Container className={classes.trademarkListContainer}>
      {renderTrademarks(trademarks)}
    </Container>
  )
}

TrademarkList.propTypes = {
  trademarks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobile: PropTypes.bool,
}

TrademarkList.defaultProps = {
  isMobile: false,
}

export default TrademarkList
