import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import parseHtml from 'html-react-parser'
import { useLayoutContext, useTrademarkContext } from '../context'
import {
  whatsappContactMessages,
  WHATSAPP_PHONE,
  AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE,
} from '../../config'
import { useInfoModal } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  mainMaterialLayoutContainer: {
    marginTop: theme.spacing(2),
    border: '1px solid #EEEEEE',
    borderRadius: '3px',
    boxShadow: '0 5px 5px rgba(0,0,0,0.12)',
    // padding: '30px',
    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      paddingBottom: theme.spacing(3),
    },
  },
  forgotPasswordText: {
    fontSize: 11,
    color: '#808081',
  },
  resetPasswordLink: {
    fontSize: 10,
    color: '#388BF3',
    cursor: 'pointer',
  },
  title: {
    fontSize: 25,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
    fontWeight: 500,
  },
  smallSpacing: {
    paddingTop: '15px',
  },
  mediumSpacing: {
    paddingTop: '30px',
  },
  largeSpacing: {
    paddingTop: '45px',
  },
  backToLoginLink: {
    fontSize: 11,
    color: '#388BF3',
    cursor: 'pointer',
  },
  skipLink: {
    fontSize: 10,
    color: '#388BF3',
    cursor: 'pointer',
  },
  radioLabel: {
    marginTop: '15px',
  },
  subtext: {
    fontSize: 11,
    color: '#808081',
  },
  subtextLink: {
    fontSize: 10,
    color: '#388BF3',
    cursor: 'pointer',
  },
  enabled: {
    display: 'block',
  },
  disabled: {
    display: 'none',
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'left',
  },
}))

// eslint-disable-next-line import/prefer-default-export
export const withMaterialStyles = (BaseComponent, customClasses) => (props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { openModal } = useInfoModal()
  const {
    setShowAuthUI,
    setSignUpAuthData,
    signUpAuthData,
    authState,
    setWhatsappContactStatus,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const { reconfigureSpeedDialValues } = useTrademarkContext()

  useEffect(() => {
    setContactSpeedDialEnabled(true)
    const whatsapp = whatsappContactMessages.unauthenticatedUserAuth(authState)

    setWhatsappContactStatus({
      number: WHATSAPP_PHONE,
      text: whatsapp,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [whatsappContactMessages, setWhatsappContactStatus, authState])

  // eslint-disable-next-line no-unused-vars
  const triggerNotification = (notificationMessage) => {
    enqueueSnackbar(notificationMessage, {
      preventDuplicate: true,
      variant: 'success',
    })
  }

  const cancelAction = () => {
    reconfigureSpeedDialValues()
  }

  const openPasswordInformation = () => {
    openModal({
      title: 'Formato de seguridad para contraseña',
      content: (
        <div className={classes.infoText}>
          {parseHtml(AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE)}
        </div>
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  return (
    <BaseComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      notificationTrigger={triggerNotification}
      openPasswordInformation={openPasswordInformation}
      classes={customClasses || classes}
      authToggle={setShowAuthUI}
      setSignUpAuthData={setSignUpAuthData}
      signUpAuthData={signUpAuthData}
      cancelAction={cancelAction}
    />
  )
}
