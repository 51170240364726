import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import { useForm } from 'react-hook-form'
// eslint-disable-next-line no-unused-vars
import { useParams, useNavigate } from '@reach/router'
import Card from 'react-credit-cards'
import {
  Button,
  Dialog,
  Container,
  Card as MuiCard,
  Grid,
  Link,
  Paper,
  Typography,
  InputAdornment,
  Slide,
  Divider,
  Hidden,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined'
import CardPayment from 'payment'
// import { Auth } from 'aws-amplify'
import { useSnackbar } from 'notistack'
import {
  TAX_RATE,
  paymentMethods,
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_DEPOSIT,
  PAYMENT_METHOD_STORE,
  acceptedCards,
  CARD_ISSUER_AMEX,
  OPENPAY_MERCHANT_ID,
  OPENPAY_PUBLIC_API_KEY,
  OPENPAY_IS_SANBOX,
  PAYMENT_TX_STATUS_PAID,
  emailContactMessages,
  MAILTO_ADDRESS,
} from '../../config'
import {
  FormField,
  FormFieldMultipleOptions,
  mapIcon,
  NumberFormatCustom,
  cardExpiryFormat,
  CardMasterIcon,
  CardVisaIcon,
  CardAmexIcon,
  currencyFormat,
} from '../common'
import { useTrademarkContext, useLayoutContext } from '../context'
import {
  createPaymentRequestCardPayment,
  createStoreOrDepositPaymentRequest,
  readPaymentTransactionStatus,
} from '../../services'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
  subtitle: {
    fontWeight: 600,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    maxHeight: '100vh',
    overflow: 'scroll',
  },
  subcontainer: {
    display: 'flex',
    justifyContent: 'left',
  },
  paymentSummary: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  purchaseSummary: {
    textAlign: 'right',
  },
  divider: {
    height: theme.spacing(2),
  },
  btn: {
    width: '100%',
  },
  webDialog: {
    maxWidth: '100%',
  },
  customPaper: {
    overflowY: 'scroll',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  nextButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nextButtonContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  nextButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1.75),
    cursor: 'pointer',
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableTitle: {
    flex: '1 1 100%',
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    padding: theme.spacing(1),
  },
  tableHeaders: {
    fontSize: '0.9rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.95 rem',
    },
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.2),
    },
  },
  tableText: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.2),
    },
  },
  emphasizedText: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.85rem',
    },
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.2),
    },
  },
  invoiceTotal: {
    fontSize: '0.85rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.9rem',
    },
    fontWeight: 800,
  },
  noBorder: {
    border: 0,
  },
  paymentMethod: {
    marginTop: theme.spacing(3),
  },
  paymentMethods: {
    textAlign: 'left',
  },
  paymentMethodIcon: {
    fontSize: theme.typography.fontSize,
    marginRight: theme.typography.fontSize,
  },
  card: {
    '& > div > div > div, & > div > div > div > div': {
      [theme.breakpoints.down('xs')]: {
        width: 279,
        height: 175,
      },
    },
  },
  cardIssuer: {
    maxHeight: theme.typography.fontSize,
  },
  openpayLogoContainer: {
    verticalAlign: 'middle',
    textAlign: 'right',
  },
  openpayLogo: {
    border: 'none',
    padding: '0.25rem',
    borderRadius: '0.25rem',
    maxHeight: theme.spacing(4),
  },
  referenceButton: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  // https://www.smashingmagazine.com/2014/02/making-embedded-content-work-in-responsive-design/
  // https://benmarshall.me/responsive-iframes/
  // https://stackoverflow.com/questions/17838607/making-an-iframe-responsive
  referencePDFContainer: {
    overflow: 'hidden',
    // aspect ratio
    paddingBottom: '75% !important',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '75% !important',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '100% !important',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '120% !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '140% !important',
    },
    position: 'relative',
    '& > iframe': {
      border: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentRequestCheckout = (props) => {
  const { register, handleSubmit, control, errors, watch } = useForm()
  const { generatedPayment } = useTrademarkContext()
  const {
    setLoading,
    setEmailContactStatus,
    currentUser,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const paymentMethod = watch('paymentMethod')
  // const currentFieldValues = watch(['paymentMethod'])
  const cardNumber = watch('cardNumber')
  const cardholderName = watch('cardholderName')
  const cardExpirationDate = watch('cardExpirationDate')
  const cardCVCCode = watch('cardCVCCode')
  const [cardFocused, setCardFocused] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [validCard, setValidCard] = useState(false)
  const [cardIssuer, setCardIssuer] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [paymentRequestRefUrl, setPaymentRequestRefUrl] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [referenceKey, setReferenceKey] = useState(null)
  const [paymentCompleted, setPaymentCompleted] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))
  const isTabletDevice = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    active,
    paymentRequest,
    handler,
    totalAmount,
    serviceFeeTotal,
    serviceTaxAmount,
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const params = useParams()

  useEffect(() => {
    setContactSpeedDialEnabled(true)
    const email = emailContactMessages.authenticatedUserPaymentRequest(
      params.trademarkId,
      paymentRequest?.id,
      currentUser.username
    )

    setEmailContactStatus({
      email: MAILTO_ADDRESS,
      subject: email?.subject,
      body: email?.message,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [emailContactMessages, setEmailContactStatus])

  const handleClose = () => {
    handler(false)
  }

  const handleCancel = async () => {
    handler(false)
    navigate(-1)
  }

  const handlePayment = async (data) => {
    // eslint-disable-next-line no-use-before-define
    await onSubmit(data)
  }

  const handlePaymentCompleted = () => {
    setLoading(false)
    handler(false)
    setPaymentCompleted(true)
    navigate(-1)
  }

  const handleReferenceRendered = () => {
    setLoading(false)
    setPaymentCompleted(true)
  }

  // eslint-disable-next-line no-unused-vars
  const handleCardCallback = ({ issuer, maxLength }, isValid) => {
    setValidCard(isValid)
  }

  const handleInputCardFieldsFocused = ({ name }) => {
    setCardFocused(name)
  }

  const icons = {
    width: '30px',
    height: '25px',
  }

  /* const disabledOptions = paymentRequestRefUrl

  const disabledPaymentCardOptions = generatedPayment */

  const paymentMethodsOptions = paymentMethods.map((option) => {
    const { Icon } = mapIcon(option)
    return {
      value: option.value,
      label: option.label,
      icon: <Icon className={classes.paymentMethodIcon} />,
    }
  })

  const openpayLogo = () => {
    return (
      <Link href="https://www.openpay.mx/" rel="noreferrer" target="_blank">
        <img
          alt="openpay"
          src="openpay_color.png"
          className={classes.openpayLogo}
        />
      </Link>
    )
  }

  const configureOpenPay = () => {
    // eslint-disable-next-line no-undef
    OpenPay.setId(OPENPAY_MERCHANT_ID)
    // eslint-disable-next-line no-undef
    OpenPay.setApiKey(OPENPAY_PUBLIC_API_KEY)
    if (OPENPAY_IS_SANBOX === 'true') {
      // eslint-disable-next-line no-undef
      OpenPay.setSandboxMode(true)
    }
  }

  const validateCard = ({ cn, ced, cvv }) => {
    let valid = true
    // eslint-disable-next-line no-undef
    if (!OpenPay.card.validateCardNumber(cn)) {
      valid = false
      setLoading(false)
      enqueueSnackbar('El número de tarjeta no es válido', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    // eslint-disable-next-line no-undef
    if (valid && !OpenPay.card.validateCVC(cvv, cn)) {
      valid = false
      setLoading(false)
      enqueueSnackbar('El código de verificación no es válido', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    const expirationMonth = ced.substring(0, 2)
    const expirationYear = ced.substring(2, 4)
    if (
      valid &&
      // eslint-disable-next-line no-undef
      !OpenPay.card.validateExpiry(expirationMonth, expirationYear)
    ) {
      valid = false
      setLoading(false)
      enqueueSnackbar('La fecha de expiración no es válida', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    return valid
  }

  const createOpenPayCard = async ({ cn, chn, ced, cvv }) => {
    return new Promise((resolve, reject) => {
      const expirationMonth = ced.substring(0, 2)
      const expirationYear = ced.substring(2, 4)
      const cardData = {
        card_number: cn,
        holder_name: chn,
        expiration_year: expirationYear,
        expiration_month: expirationMonth,
        cvv2: cvv,
      }
      try {
        // eslint-disable-next-line no-undef
        OpenPay.token.create(
          cardData,
          (response) => {
            resolve(response)
          },
          (e) => {
            reject(e)
          }
        )
      } catch (err) {
        reject(err)
      }
    })
  }

  const retrievePaymentTXStatusWithDelay = async ({ id, delayInms }) => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        resolve(await readPaymentTransactionStatus({ id }))
      }, delayInms)
    })
  }

  const retrievePaymentTXStatus = async ({
    id,
    maxAttempts = 3,
    delayInms = 2000,
  }) => {
    let paymentStatusResponse = null
    let retrieveStatus = true
    for (
      let attempt = 0;
      attempt < maxAttempts && retrieveStatus;
      attempt += 1
    ) {
      // eslint-disable-next-line no-await-in-loop
      paymentStatusResponse = await retrievePaymentTXStatusWithDelay({
        id,
        delayInms,
      })
      if (
        paymentStatusResponse?.status &&
        paymentStatusResponse?.status === PAYMENT_TX_STATUS_PAID
      ) {
        retrieveStatus = false
      }
    }
    return paymentStatusResponse
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const {
      cardNumber: cn,
      cardholderName: chn,
      cardExpirationDate: ced,
      cardCVCCode: cvv,
    } = data

    configureOpenPay()

    let deviceSessionId
    if (paymentMethod === PAYMENT_METHOD_CARD) {
      // eslint-disable-next-line no-undef
      deviceSessionId = OpenPay?.deviceData?.setup('paymentRequestForm', '')
      if (!deviceSessionId) {
        enqueueSnackbar('Error al contactar al servicio de pagos', {
          preventDuplicate: true,
          variant: 'error',
        })
        return
      }
      const valid = validateCard({ cn, ced, cvv })
      if (!valid) {
        return
      }
    }
    if (paymentMethod === PAYMENT_METHOD_CARD) {
      // eslint-disable-next-line no-undef
      // const deviceSessionId = OpenPay?.deviceData?.setup('paymentForm', '')
      // const valid = validateCard({ cn, ced, cvv })
      // if (valid) {
      try {
        const createdCardResponse = await createOpenPayCard({
          cn,
          chn,
          ced,
          cvv,
        })
        const paymentResponse = await createPaymentRequestCardPayment({
          id: paymentRequest?.id,
          token: createdCardResponse.data.id,
          deviceId: deviceSessionId,
          amount: Math.round((totalAmount + Number.EPSILON) * 100) / 100,
          name: paymentRequest?.owner,
          email: paymentRequest?.owner,
          isPaymentRequest: true,
        })
        if (paymentResponse?.errorMessage) {
          setLoading(false)
          enqueueSnackbar(paymentResponse?.errorMessage, {
            preventDuplicate: true,
            variant: 'error',
          })
        }
        if (paymentResponse?.paymentRequest) {
          // setPaymentTransactionID(paymentTXID)
          // Invoke at least 3 times in order to retrieve status.
          // eslint-disable-next-line no-unused-vars
          const paymentStatus = await retrievePaymentTXStatus({
            id: paymentResponse?.paymentRequest?.paymentTransactionID,
            maxAttempts: 3,
            delayInms: 3000,
          })
          enqueueSnackbar('Pago procesado correctamente', {
            preventDuplicate: true,
            variant: 'success',
          })
          handlePaymentCompleted()
        }
      } catch (e) {
        setLoading(false)
        enqueueSnackbar(
          'Ocurrió un error a la hora de procesar la transacción',
          {
            preventDuplicate: true,
            variant: 'error',
          }
        )
      }
    }
    if (
      paymentMethod === PAYMENT_METHOD_DEPOSIT ||
      paymentMethod === PAYMENT_METHOD_STORE
    ) {
      const paymentResponse = await createStoreOrDepositPaymentRequest({
        id: paymentRequest?.id,
        amount: Math.round((totalAmount + Number.EPSILON) * 100) / 100,
        name: paymentRequest.owner,
        email: paymentRequest.owner,
        isPaymentRequest: true,
        paymentMethod,
      })
      if (paymentResponse?.errorMessage) {
        setLoading(false)
        enqueueSnackbar(paymentResponse?.errorMessage, {
          preventDuplicate: true,
          variant: 'error',
        })
      }
      setPaymentRequestRefUrl(paymentResponse?.documentUrl)
    }
  }

  const renderPaymentMethodCard = () => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.card}>
            <Grid container spacing={2}>
              <Hidden xsDown>
                <Grid item sm={12} />
              </Hidden>
              <Grid item xs={12} sm={12}>
                <Card
                  number={cardNumber || ''}
                  name={cardholderName || ''}
                  expiry={cardExpirationDate || ''}
                  cvc={cardCVCCode || ''}
                  acceptedCards={acceptedCards}
                  placeholders={{
                    name: 'Titular',
                  }}
                  locale={{
                    valid: 'Vence hasta',
                  }}
                  focused={cardFocused}
                  callback={handleCardCallback}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Hidden smDown>
                <Grid item md={3} />
              </Hidden>
              <Grid item xs={4} md={2}>
                <CardVisaIcon style={icons} />
              </Grid>
              <Grid item xs={4} md={2}>
                <CardMasterIcon style={icons} />
              </Grid>
              <Grid item xs={4} md={2}>
                <CardAmexIcon style={icons} />
              </Grid>
              <Hidden smDown>
                <Grid item md={3} />
              </Hidden>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <FormField
                  id="cardNumber"
                  name="cardNumber"
                  label="Número de tarjeta"
                  type="tel"
                  required
                  /* disabled={disabledPaymentCardOptions} */
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="cc-number"
                  className={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Confirma tu tarjeta',
                    },
                    minLength: {
                      value: 13,
                      message: 'Debe ser mayor o igual a 13 caracteres',
                    },
                    maxLength: {
                      value: 19,
                      message: 'Debe ser menor o igual a 19 caracteres',
                    },
                  }}
                  errors={errors}
                  inputProps={{
                    // eslint-disable-next-line no-nested-ternary
                    format: cardIssuer
                      ? cardIssuer === CARD_ISSUER_AMEX
                        ? '#### ###### #####'
                        : '#### #### #### #######'
                      : '#### #### #### ####',
                    // mask: '•',
                  }}
                  onFocus={() =>
                    handleInputCardFieldsFocused({ name: 'number' })
                  }
                  onBlur={() => handleInputCardFieldsFocused({ name: null })}
                  onChange={(e) => {
                    const issuer = CardPayment.fns.cardType(e?.target?.value)
                    setCardIssuer(issuer)
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: (
                      <InputAdornment position="end">
                        {cardIssuer ? (
                          <img
                            alt="cardIssuer"
                            src={`${cardIssuer}.png`}
                            className={classes.cardIssuer}
                          />
                        ) : (
                          <CreditCardOutlinedIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  id="cardholderName"
                  name="cardholderName"
                  label="Titular"
                  type="text"
                  required
                  /* disabled={disabledPaymentCardOptions} */
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="cc-number"
                  className={clsx(classes.margin, classes.noLabel)}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Confirma el titular de tu tarjeta',
                    },
                    minLength: {
                      value: 2,
                      message: 'Debe ser mayor o igual a 2 caracteres',
                    },
                    maxLength: {
                      value: 60,
                      message: 'Debe ser menor o igual a 60 caracteres',
                    },
                  })}
                  errors={errors}
                  inputProps={{
                    minLength: 2,
                    maxLength: 60,
                  }}
                  onFocus={() => handleInputCardFieldsFocused({ name: 'name' })}
                  onBlur={() => handleInputCardFieldsFocused({ name: null })}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  id="cardExpirationDate"
                  name="cardExpirationDate"
                  label="Vence hasta"
                  type="text"
                  required
                  /* disabled={disabledPaymentCardOptions} */
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="cc-number"
                  className={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Confirma la fecha de expiración de tu tarjeta',
                    },
                    minLength: {
                      value: 4,
                      message: 'Debe ser mayor o igual a 4 caracteres',
                    },
                    maxLength: {
                      value: 4,
                      message: 'Debe ser menor o igual a 4 caracteres',
                    },
                  }}
                  errors={errors}
                  inputProps={{
                    format: cardExpiryFormat,
                    placeholder: 'MM/YY',
                  }}
                  onFocus={() =>
                    handleInputCardFieldsFocused({ name: 'expiry' })
                  }
                  onBlur={() => handleInputCardFieldsFocused({ name: null })}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  id="cardCVCCode"
                  name="cardCVCCode"
                  label="CVV | CVC"
                  /* type={disabledPaymentCardOptions ? 'password' : 'text'} */
                  required
                  /* disabled={disabledPaymentCardOptions} */
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="cc-number"
                  className={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message:
                        'Confirma el código de verificación de tu tarjeta',
                    },
                    minLength: {
                      value: cardIssuer === CARD_ISSUER_AMEX ? 4 : 3,
                      message:
                        cardIssuer === CARD_ISSUER_AMEX
                          ? 'Debe ser igual a 4 caracteres'
                          : 'Debe ser igual a 3 caracteres',
                    },
                    maxLength: {
                      value: 4,
                      message: 'Debe ser menor o igual a 4 caracteres',
                    },
                  }}
                  errors={errors}
                  inputProps={{
                    // eslint-disable-next-line no-nested-ternary
                    format: cardIssuer === CARD_ISSUER_AMEX ? '####' : '###',
                    mask: '•',
                  }}
                  onFocus={() => handleInputCardFieldsFocused({ name: 'cvc' })}
                  onBlur={() => handleInputCardFieldsFocused({ name: null })}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderPaymentMethodDeposit = ({ referenceType }) => {
    return (
      <Grid item xs={12}>
        {paymentRequestRefUrl && (
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Hidden xsDown>
                <Grid item sm={1} md={2} lg={3} />
              </Hidden>
              <Grid
                item
                xs={12}
                sm={10}
                md={8}
                lg={6}
                className={clsx(classes.referencePDFContainer)}>
                <iframe
                  key={`${referenceType}-${referenceKey}`}
                  title="pdf"
                  src={paymentRequestRefUrl}
                  type="application/pdf"
                  onLoad={() => handleReferenceRendered()}
                />
              </Grid>
              <Hidden xsDown>
                <Grid item sm={1} md={2} lg={3} />
              </Hidden>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }

  renderPaymentMethodDeposit.propTypes = {
    referenceType: PropTypes.oneOf([
      PAYMENT_METHOD_DEPOSIT,
      PAYMENT_METHOD_STORE,
    ]).isRequired,
  }

  const renderPaymentForm = () => {
    return (
      <form
        id="paymentRequestForm"
        noValidate
        onSubmit={handleSubmit(handlePayment)}>
        <div className={classes.root}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} className={clsx(classes.paymentMethod)}>
                <Grid item xs={12}>
                  Método de pago
                </Grid>
                <Grid item xs={12} className={clsx(classes.paymentMethods)}>
                  <FormFieldMultipleOptions
                    id="paymentMethod"
                    name="paymentMethod"
                    label="Selecciona tu método de pago"
                    type="radio"
                    required
                    variant="outlined"
                    size="small"
                    fullWidth
                    /* disabled={disabledOptions} */
                    row={!isMobileDevice && !isTabletDevice}
                    className={clsx(classes.margin, classes.noLabel)}
                    options={paymentMethodsOptions}
                    errors={errors}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Selecciona un método de pago',
                      },
                    }}
                    value={paymentMethod}
                  />
                </Grid>
              </Grid>
              {paymentMethod === PAYMENT_METHOD_CARD &&
                renderPaymentMethodCard()}
              {paymentMethod === PAYMENT_METHOD_DEPOSIT &&
                renderPaymentMethodDeposit({
                  referenceType: PAYMENT_METHOD_DEPOSIT,
                })}
              {paymentMethod === PAYMENT_METHOD_STORE &&
                renderPaymentMethodDeposit({
                  referenceType: PAYMENT_METHOD_STORE,
                })}
              {!generatedPayment && (
                <Grid item xs={12} className={classes.openpayLogoContainer}>
                  {openpayLogo()}
                </Grid>
              )}
            </Grid>
            <div className={classes.divider} />
            {paymentCompleted ? (
              <Grid item container spacing={2}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Button
                    value="false"
                    variant="contained"
                    className={clsx(classes.btn)}
                    onClick={() => handleCancel()}>
                    Regresar
                  </Button>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            ) : (
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    value="false"
                    variant="contained"
                    className={clsx(classes.btn)}
                    onClick={() => handleCancel()}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    value="true"
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={clsx(classes.btn)}>
                    Pagar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Container>
        </div>
      </form>
    )
  }

  return (
    <>
      {isMobile ? (
        <Dialog
          fullScreen
          open={active}
          onClose={handleClose}
          TransitionComponent={Transition}>
          <MuiCard className={clsx(classes.container)}>
            <Grid container spacing={2} className={clsx(classes.subcontainer)}>
              <Grid item xs={12}>
                <Typography
                  className={clsx(classes.title)}
                  color="primary"
                  variant="h6">
                  Resumen del Pago
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  variant="outlined"
                  className={clsx(classes.paymentSummary)}>
                  <Grid container spacing={2}>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={8} md={7} lg={7}>
                        <Typography
                          className={clsx(
                            classes.subtitle,
                            classes.purchaseSummary
                          )}>
                          Derechos del IMPI
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={4} md={5} lg={5}>
                        <Typography
                          className={clsx(
                            classes.subtitle,
                            classes.purchaseSummary
                          )}>
                          Honorarios
                        </Typography>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={8} md={7} lg={7}>
                        <Typography className={clsx(classes.purchaseSummary)}>
                          {currencyFormat(paymentRequest?.impiRights, '$', 2)}{' '}
                          MXN
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={5} lg={5}>
                        <Typography className={clsx(classes.purchaseSummary)}>
                          {currencyFormat(paymentRequest?.amount, '$', 2)} MXN
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.divider} />
                  <Divider />
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={2}>
                      <Typography className={clsx(classes.subtitle)}>
                        Subtotal
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(paymentRequest?.impiRights, '$', 2)} MXN
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(paymentRequest?.amount, '$', 2)} MXN
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.divider} />
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={2}>
                      <Typography className={clsx(classes.subtitle)}>
                        {`IVA ${TAX_RATE * 100}%`}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(serviceTaxAmount, '$', 2)} MXN
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.divider} />
                  <Grid container spacing={2}>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={7} md={7} lg={7}>
                        <Typography
                          className={clsx(
                            classes.subtitle,
                            classes.purchaseSummary
                          )}>
                          Total derechos IMPI
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={5} md={5} lg={5}>
                        <Typography
                          className={clsx(
                            classes.subtitle,
                            classes.purchaseSummary
                          )}>
                          Total honorarios
                        </Typography>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={7} md={7} lg={7}>
                        <Typography className={clsx(classes.purchaseSummary)}>
                          {currencyFormat(paymentRequest?.impiRights, '$', 2)}{' '}
                          MXN
                        </Typography>
                      </Grid>
                      <Grid item xs={5} md={5} lg={5}>
                        <Typography className={clsx(classes.purchaseSummary)}>
                          {currencyFormat(serviceFeeTotal, '$', 2)} MXN
                        </Typography>
                      </Grid>
                    </Grid>
                    <div className={classes.divider} />
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={8} md={7} lg={7}>
                        <Typography className={clsx(classes.subtitle)}>
                          TOTAL
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={5} lg={5}>
                        <Typography
                          className={clsx(
                            classes.purchaseSummary,
                            classes.subtitle
                          )}>
                          {currencyFormat(totalAmount, '$', 2)} MXN
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <div className={classes.divider} />
                {renderPaymentForm()}
                <div className={classes.divider} />
              </Grid>
            </Grid>
          </MuiCard>
        </Dialog>
      ) : (
        <Dialog
          fullWidth
          maxWidth="lg"
          classes={{ paper: classes.customPaper }}
          className={clsx(classes.webDialog)}
          open={active}
          onClose={handleClose}
          TransitionComponent={Transition}>
          <MuiCard
            className={clsx(classes.container)}
            raised="true"
            scroll="body">
            <Grid container spacing={2} className={clsx(classes.subcontainer)}>
              <Grid item xs={12}>
                <Typography
                  className={clsx(classes.title)}
                  color="primary"
                  variant="h6">
                  Resumen del Pago
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  variant="outlined"
                  className={clsx(classes.paymentSummary)}>
                  <Grid container spacing={2}>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={8} md={7} lg={7}>
                        <Typography
                          className={clsx(
                            classes.subtitle,
                            classes.purchaseSummary
                          )}>
                          Derechos del IMPI
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={4} md={5} lg={5}>
                        <Typography
                          className={clsx(
                            classes.subtitle,
                            classes.purchaseSummary
                          )}>
                          Honorarios
                        </Typography>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={8} md={7} lg={7}>
                        <Typography className={clsx(classes.purchaseSummary)}>
                          {currencyFormat(paymentRequest?.impiRights, '$', 2)}{' '}
                          MXN
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={5} lg={5}>
                        <Typography className={clsx(classes.purchaseSummary)}>
                          {currencyFormat(paymentRequest?.amount, '$', 2)} MXN
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.divider} />
                  <Divider />
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={2}>
                      <Typography className={clsx(classes.subtitle)}>
                        Subtotal
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(paymentRequest?.impiRights, '$', 2)} MXN
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(paymentRequest?.amount, '$', 2)} MXN
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.divider} />
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={2}>
                      <Typography className={clsx(classes.subtitle)}>
                        {`IVA ${TAX_RATE * 100}%`}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(serviceTaxAmount, '$', 2)} MXN
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.divider} />
                  <Grid container spacing={2}>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={7} md={7} lg={7}>
                        <Typography
                          className={clsx(
                            classes.subtitle,
                            classes.purchaseSummary
                          )}>
                          Total derechos IMPI
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={5} md={5} lg={5}>
                        <Typography
                          className={clsx(
                            classes.subtitle,
                            classes.purchaseSummary
                          )}>
                          Total honorarios
                        </Typography>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={7} md={7} lg={7}>
                        <Typography className={clsx(classes.purchaseSummary)}>
                          {currencyFormat(paymentRequest?.impiRights, '$', 2)}{' '}
                          MXN
                        </Typography>
                      </Grid>
                      <Grid item xs={5} md={5} lg={5}>
                        <Typography className={clsx(classes.purchaseSummary)}>
                          {currencyFormat(serviceFeeTotal, '$', 2)} MXN
                        </Typography>
                      </Grid>
                    </Grid>
                    <div className={classes.divider} />
                    <Grid item container spacing={2} xs={12}>
                      <Grid item xs={8} md={7} lg={7}>
                        <Typography className={clsx(classes.subtitle)}>
                          TOTAL
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={5} lg={5}>
                        <Typography
                          className={clsx(
                            classes.purchaseSummary,
                            classes.subtitle
                          )}>
                          {currencyFormat(totalAmount, '$', 2)} MXN
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <div className={classes.divider} />
                {renderPaymentForm()}
                <div className={classes.divider} />
              </Grid>
            </Grid>
          </MuiCard>
        </Dialog>
      )}
    </>
  )
}

PaymentRequestCheckout.propTypes = {
  active: PropTypes.bool,
  paymentRequest: PropTypes.shape({}).isRequired,
  handler: PropTypes.func,
  totalAmount: PropTypes.number.isRequired,
  serviceTaxAmount: PropTypes.number.isRequired,
  serviceFeeTotal: PropTypes.number.isRequired,
}

PaymentRequestCheckout.defaultProps = {
  active: false,
  handler: undefined,
}

export default PaymentRequestCheckout
