import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import {
  Grid,
  Typography,
  Card,
  Paper,
  Divider,
  Button,
  Container,
  useMediaQuery,
  useTheme,
  Chip,
} from '@material-ui/core'
import { useParams, useNavigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {
  paymentRequestConcepts,
  paymentRequestStatus,
} from '../../config/constants'
import { currencyFormat } from '../common'
import {
  TAX_RATE,
  PAYMENT_REQUEST_STATUS_ACCEPTED,
  PAYMENT_REQUEST_STATUS_REJECTED,
  emailContactMessages,
  MAILTO_ADDRESS,
  PAYMENT_TX_STATUS_PAID,
  PAYMENT_TX_STATUS_NOT_PAID,
} from '../../config'
import {
  updatePaymentRequestStatus,
  getPaymentVoucher,
  getBrandById,
} from '../../services'
import PaymentRequestCheckout from './PaymentRequestCheckout'
// eslint-disable-next-line import/no-named-as-default
import VoucherView from './VoucherView'
import { useLayoutContext } from '../context'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: 600,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  subcontainer: {
    display: 'flex',
    justifyContent: 'left',
  },
  paymentSummary: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  purchaseSummary: {
    textAlign: 'right',
  },
  divider: {
    height: theme.spacing(2),
  },
  btn: {
    width: '100%',
  },
  voucherBtn: {},
  statusText: {
    fontWeight: 600,
  },
  back: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  voucherBtnCointainer: {
    justifyContent: 'center',
  },
  pending: {
    backgroundColor: '#E3CB1F',
  },
  accepted: {
    backgroundColor: '#3CBB44',
  },
  rejected: {
    backgroundColor: '#E3522B',
  },
  chipText: {
    fontWeight: 'bold',
    color: '#FFF',
  },
  paid: {
    backgroundColor: '#3CBB44',
  },
  notPaid: {
    backgroundColor: '#E3522B',
  },
}))

export const PaymentRequest = (props) => {
  const { paymentRequest } = props
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const params = useParams()
  const navigate = useNavigate()
  const {
    setShowNotifications,
    setShowTrademarkDetail,
    setLoading,
    setEmailContactStatus,
    currentUser,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const [openCheckout, setOpenCheckout] = React.useState(false)
  const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('xs'))
  const [voucherViewEnabled, setVoucherViewEnabled] = useState(false)
  const [voucherUrl, setVoucherUrl] = useState('')
  /* eslint-disable-next-line no-unused-vars */
  const [brand, setBrand] = useState(undefined)

  const serviceTaxAmount = paymentRequest?.amount * TAX_RATE
  const serviceFeeTotal = serviceTaxAmount + paymentRequest?.amount
  const totalCost = serviceFeeTotal + paymentRequest?.impiRights

  useEffect(() => {
    setContactSpeedDialEnabled(true)
    const email = emailContactMessages.authenticatedUserPaymentRequest(
      params.trademarkId,
      paymentRequest?.id,
      currentUser.username
    )

    setEmailContactStatus({
      email: MAILTO_ADDRESS,
      subject: email?.subject,
      body: email?.message,
    })

    async function loadBrand() {
      /* eslint-disable-next-line no-shadow */
      const brand = await getBrandById(params.trademarkId)
      setBrand(brand)
    }
    loadBrand()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [emailContactMessages, setEmailContactStatus])

  const handleReject = async () => {
    // eslint-disable-next-line no-unused-vars
    const updatedPaymentRequest = await updatePaymentRequestStatus(
      paymentRequest?.id,
      PAYMENT_REQUEST_STATUS_REJECTED,
      paymentRequest?._version
    )
    setShowNotifications(false)
    setShowTrademarkDetail(true)
    navigate(`/detail/${params.trademarkId}`)
  }

  const handleReturn = () => {
    setShowNotifications(false)
    setShowTrademarkDetail(true)
    navigate(`/detail/${params.trademarkId}`)
  }

  const handleAccept = async () => {
    const updatedPaymentRequest = await updatePaymentRequestStatus(
      paymentRequest?.id,
      PAYMENT_REQUEST_STATUS_ACCEPTED,
      paymentRequest?._version
    )
    if (updatedPaymentRequest && !updatedPaymentRequest?.error) {
      setOpenCheckout(true)
    }
  }

  const handleVoucherRetrieval = async () => {
    setLoading(true)
    const voucher = await getPaymentVoucher({
      id: paymentRequest?.paymentTransactionID,
      type: paymentRequest.paymentTransaction?.transactionType,
    })
    setVoucherUrl(voucher?.documentUrl)
    setVoucherViewEnabled(true)
  }

  console.log('PAYMENT REQUEST: ', paymentRequest)

  const enableVoucherButton =
    (paymentRequest?.paymentTransaction?.transactionType === 'bank' ||
      paymentRequest?.paymentTransaction?.transactionType === 'store') &&
    paymentRequest?.status === paymentRequestStatus.accepted.value &&
    (paymentRequest?.paymentTransaction?.status === PAYMENT_TX_STATUS_PAID ||
      (paymentRequest?.paymentTransaction?.status ===
        PAYMENT_TX_STATUS_NOT_PAID &&
        paymentRequest?.paymentTransactionID))

  const renderWithStatus = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="h6">
          Estatus de orden de pago: {isMobileDevice ? <br /> : null}
          <Chip
            className={clsx(
              classes[
                paymentRequest?.status ? paymentRequest?.status : 'pending'
              ],
              classes.chipText
            )}
            label={
              paymentRequest?.status
                ? paymentRequestStatus[paymentRequest?.status].label
                : 'Pendiente'
            }
          />
          &nbsp;&nbsp;
          <Chip
            className={clsx(
              classes[
                paymentRequest?.paymentTransaction
                  ? paymentRequest?.paymentTransaction?.status
                  : 'notPaid'
              ],
              classes.chipText
            )}
            label={
              paymentRequest?.paymentTransaction?.status ===
              PAYMENT_TX_STATUS_PAID
                ? 'Pagado'
                : 'No Pagado'
            }
          />
        </Typography>
      </Grid>
    )
  }

  const renderButtons = () => {
    return (
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <Button
            value="false"
            variant="contained"
            className={clsx(classes.btn)}
            onClick={() => handleReject()}>
            Rechazar
          </Button>
        </Grid>
        {paymentRequest?.status === paymentRequestStatus.accepted.value ? (
          <Grid item xs={6}>
            <Button
              value="true"
              variant="contained"
              color="primary"
              className={clsx(classes.btn)}
              onClick={() => setOpenCheckout(true)}>
              Continuar
            </Button>
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Button
              value="true"
              variant="contained"
              color="primary"
              className={clsx(classes.btn)}
              onClick={() => handleAccept()}>
              Aceptar
            </Button>
          </Grid>
        )}
      </Grid>
    )
  }

  const renderContent = () => {
    return (
      <>
        <VoucherView
          url={voucherUrl}
          enabled={voucherViewEnabled}
          closeHandler={setVoucherViewEnabled}
        />
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              className={clsx(classes.back)}
              onClick={() => handleReturn()}>
              <ArrowBackIosIcon />
            </Grid>
            <Grid
              item
              className={clsx(classes.back)}
              onClick={() => handleReturn()}>
              <Typography>Regresar</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Card className={clsx(classes.container)}>
          <Grid container spacing={2} className={clsx(classes.subcontainer)}>
            {paymentRequest?.status ? (
              renderWithStatus()
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6">Generar Orden de Pago</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography>
                <span className={clsx(classes.subtitle)}>Concepto</span>:{' '}
                {paymentRequestConcepts[paymentRequest?.concept].label}
              </Typography>
              <Typography>
                <span className={clsx(classes.subtitle)}>Descripción</span>:{' '}
                {paymentRequest?.description}
              </Typography>
              <Paper
                elevation={0}
                variant="outlined"
                className={clsx(classes.paymentSummary)}>
                <Grid container spacing={2}>
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={8} md={7} lg={7}>
                      <Typography
                        className={clsx(
                          classes.subtitle,
                          classes.purchaseSummary
                        )}>
                        Derechos del IMPI
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={4} md={5} lg={5}>
                      <Typography
                        className={clsx(
                          classes.subtitle,
                          classes.purchaseSummary
                        )}>
                        Honorarios
                      </Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={8} md={7} lg={7}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(paymentRequest?.impiRights, '$', 2)} MXN
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={5} lg={5}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(paymentRequest?.amount, '$', 2)} MXN
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <div className={classes.divider} />
                <Divider />
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={2}>
                    <Typography className={clsx(classes.subtitle)}>
                      Subtotal
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className={clsx(classes.purchaseSummary)}>
                      {currencyFormat(paymentRequest?.impiRights, '$', 2)} MXN
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className={clsx(classes.purchaseSummary)}>
                      {currencyFormat(paymentRequest?.amount, '$', 2)} MXN
                    </Typography>
                  </Grid>
                </Grid>
                <div className={classes.divider} />
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={2}>
                    <Typography className={clsx(classes.subtitle)}>
                      {`IVA ${TAX_RATE * 100}%`}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography className={clsx(classes.purchaseSummary)}>
                      {currencyFormat(serviceTaxAmount, '$', 2)} MXN
                    </Typography>
                  </Grid>
                </Grid>
                <div className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={7} md={7} lg={7}>
                      <Typography
                        className={clsx(
                          classes.subtitle,
                          classes.purchaseSummary
                        )}>
                        Total derechos IMPI
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={5} md={5} lg={5}>
                      <Typography
                        className={clsx(
                          classes.subtitle,
                          classes.purchaseSummary
                        )}>
                        Total honorarios
                      </Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={7} md={7} lg={7}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(paymentRequest?.impiRights, '$', 2)} MXN
                      </Typography>
                    </Grid>
                    <Grid item xs={5} md={5} lg={5}>
                      <Typography className={clsx(classes.purchaseSummary)}>
                        {currencyFormat(serviceFeeTotal, '$', 2)} MXN
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.divider} />
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={8} md={7} lg={7}>
                      <Typography className={clsx(classes.subtitle)}>
                        TOTAL
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={5} lg={5}>
                      <Typography
                        className={clsx(
                          classes.purchaseSummary,
                          classes.subtitle
                        )}>
                        {currencyFormat(totalCost, '$', 2)} MXN
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <div className={classes.divider} />
              {
                /* eslint-disable-next-line no-nested-ternary */
                enableVoucherButton ? (
                  <div className={clsx(classes.voucherBtnCointainer)}>
                    <Button
                      value="true"
                      variant="contained"
                      color="primary"
                      className={clsx(classes.voucherBtn)}
                      onClick={() => handleVoucherRetrieval()}>
                      Consultar Ficha
                    </Button>
                  </div>
                ) : paymentRequest?.status ===
                    paymentRequestStatus.accepted.value &&
                  paymentRequest?.paymentTransaction?.status ===
                    PAYMENT_TX_STATUS_NOT_PAID ? (
                  <div className={clsx(classes.voucherBtnCointainer)}>
                    <Button
                      value="true"
                      variant="contained"
                      color="primary"
                      className={clsx(classes.voucherBtn)}
                      onClick={() => setOpenCheckout(true)}>
                      {paymentRequest?.paymentTransaction?.transactionType ===
                        'bank' ||
                      paymentRequest?.paymentTransaction?.transactionType ===
                        'store'
                        ? 'Generar'
                        : 'Pagar'}
                    </Button>
                  </div>
                ) : (
                  renderButtons()
                )
              }
            </Grid>
          </Grid>
        </Card>
      </>
    )
  }

  return isMobileDevice ? (
    <>
      {renderContent()}
      <PaymentRequestCheckout
        active={openCheckout}
        paymentRequest={paymentRequest}
        handler={setOpenCheckout}
        totalAmount={totalCost}
        serviceFeeTotal={serviceFeeTotal}
        serviceTaxAmount={serviceTaxAmount}
      />
    </>
  ) : (
    <Container>
      {renderContent()}
      <PaymentRequestCheckout
        active={openCheckout}
        paymentRequest={paymentRequest}
        handler={setOpenCheckout}
        totalAmount={totalCost}
        serviceFeeTotal={serviceFeeTotal}
        serviceTaxAmount={serviceTaxAmount}
      />
    </Container>
  )
}

PaymentRequest.propTypes = {
  paymentRequest: PropTypes.shape({}),
}

PaymentRequest.defaultProps = {
  paymentRequest: undefined,
}

export default PaymentRequest
