import { Storage } from 'aws-amplify'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import { extname } from 'path'
import Debug from 'debug'
import awsConfig from '../../aws-exports'
import { getMobileOperatingSystem } from '../../getMobileOS'
import { OSTypes } from '../../config/constants'

const debug = Debug('services:registration:files')

const S3_BUCKET_REGION = awsConfig.aws_user_files_s3_bucket_region
const S3_BUCKET_NAME = awsConfig.aws_user_files_s3_bucket

export const uploadFileToS3 = async ({
  path,
  key,
  identityID,
  level,
  file,
  progressCallback,
  config,
  name,
}) => {
  try {
    const finalPath =
      path && path !== ''
        ? `${path.endsWith('/') ? path.slice(0, -1) : path}/${key}`
        : key
    const s3Ref = await Storage.put(finalPath, file, {
      level,
      progressCallback,
      contentType: file.type,
      metadata: { name: file.name, size: `${file.size}` },
      ...config,
    })
    return {
      key: s3Ref.key,
      bucket: S3_BUCKET_NAME,
      region: S3_BUCKET_REGION,
      level,
      identityID,
      name,
    }
  } catch (e) {
    debug('Error', e)
    return null
  }
}

export const uploadFilesToS3 = async (files, authenticated, currentUser) => {
  // https://github.com/aws-amplify/amplify-js/issues/1094#issuecomment-670836361
  if (!files || files.length === 0) return []
  const uploadedFiles = files.map(async (file) => {
    // const currentUserCredentials = await Auth.currentUserCredentials()
    const identityID = authenticated
      ? currentUser.username // currentUserCredentials.identityId
      : undefined
    const config = authenticated
      ? { identityId: identityID } // undefined
      : { customPrefix: { public: 'uploads/' } }
    const level = authenticated ? 'private' : undefined

    const s3FileRef = await uploadFileToS3({
      key: `${uuidv4()}${extname(file.name)}`,
      file,
      level,
      identityID,
      config,
      name: file.name,
    })

    return s3FileRef
  })
  const all = await Promise.all(uploadedFiles)
  return all.filter((f) => f)
}

export const getFileFromS3 = async ({
  key,
  identityID,
  level,
  expires = 60,
  download = false,
  config,
}) => {
  try {
    const s3Object = await Storage.get(key, {
      expires,
      level,
      identityId: identityID,
      download,
      ...config,
    })
    return s3Object
  } catch (e) {
    debug('Error', e)
    return null
  }
}

export const downloadFileFromS3 = async ({
  key,
  identityID,
  level,
  expires = 60,
  download = false,
  config,
}) => {
  const url = await getFileFromS3({
    key,
    identityID,
    level,
    expires,
    download,
    ...config,
  })
  const dataFile = await axios({
    url,
    method: 'GET',
    responseType: 'blob',
  })
  return new File([dataFile.data], dataFile?.headers['x-amz-meta-name'], {
    type: dataFile?.headers['content-type'],
  })
}

// eslint-disable-next-line consistent-return
export const openFileAttachment = (file, fileName, typeMime) => {
  if (process.browser) {
    if (navigator.msSaveBlob) {
      // For ie and Edge
      return navigator.msSaveBlob(file, fileName)
    }
    if (getMobileOperatingSystem() === OSTypes.ios) {
      saveAs(new Blob([file]), fileName)
    } else {
      const link = document.createElement('a')
      /* link.href = URL.createObjectURL(new Blob([file]), {
          type: typeMime,
        }) */
      link.href = URL.createObjectURL(new Blob([file]), {
        type: typeMime,
      })
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
}
