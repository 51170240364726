import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Container,
  Button,
  Grid,
  Hidden,
  FormControl,
  FormLabel,
  Typography,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import _ from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import { I18n } from '@aws-amplify/core'
import Title from '../../components/common/text/Title'
import { useTrademarkContext, useLayoutContext } from '../../components/context'
import {
  FormField,
  PhoneField,
  NumberFormatCustom,
  withMainAuthenticator,
} from '../../components'
import { isEmail } from '../../regex.validations'
import {
  readTrademarkApplication,
  moveTrademarkToStage,
  getCountries,
  getDataFromPostalCode,
  putBrandData,
  postApplicant,
  putApplicant,
} from '../../services'
import {
  kindOfPerson as kindOfPersonCatalog,
  PHYSICAL_PERSON,
  MORAL_PERSON,
  COUNTRY_CODE_MEXICO,
  DOMICILE_TYPE_APPLICANT,
  DOMICILE_TYPE_ESTABLISHMENT,
  DOMICILE_TYPE_APPLICANT_BILLING,
  APPLICANT_TYPE_APPLICANT,
  APPLICANT_TYPE_APPLICANT_BILLING,
  trademarkApplicationStages,
  emailContactMessages,
  MAILTO_ADDRESS,
} from '../../config'
import { useInfoModal } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  nextButtons: {
    marginTop: theme.spacing(2),
  },
  nextButtonContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  nextButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  containerOptions: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  formLabel: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    marginLeft: theme.spacing(0.5),
  },
  formOption: {
    textAlign: 'left',
    marginLeft: theme.spacing(1.5),
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'center',
  },
}))

const DomicileForm = ({
  objectName,
  sameAddressName,
  emailName,
  formLabel,
  domicileType,
  countryOptions,
  selectedCountry,
  selectedPostalCode,
  sameAddressSelected,
  control,
  trigger,
  errors,
  setValue,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const { setLoading } = useLayoutContext()
  const [findingPostalCode, setFindingPostalCode] = useState(undefined)
  const [stateOptions, setStateOptions] = useState(undefined)
  const [countyOptions, setCountyOptions] = useState(undefined)
  const [suburbOptions, setSuburbOptions] = useState(undefined)
  const [actualCountrySelected, setActualCountrySelected] = useState(undefined)
  const renderAddressFieldsAsSelects = selectedCountry === COUNTRY_CODE_MEXICO
  const renderSameDomicileField =
    domicileType === DOMICILE_TYPE_ESTABLISHMENT ||
    domicileType === DOMICILE_TYPE_APPLICANT_BILLING
  const renderEmailField =
    domicileType === DOMICILE_TYPE_APPLICANT ||
    domicileType === DOMICILE_TYPE_APPLICANT_BILLING
  const renderAddressFields =
    !sameAddressSelected || domicileType === DOMICILE_TYPE_APPLICANT

  const retrievePostalCodeData = async (postalCode) => {
    setLoading(true)
    if (postalCode) {
      setFindingPostalCode(true)
      const postalCodeData = await getDataFromPostalCode({ postalCode })
      if (!Array.isArray(postalCodeData) || postalCodeData.length === 0) {
        setStateOptions([])
        setCountyOptions([])
        setSuburbOptions([])
      } else {
        const finalStateOptions = postalCodeData.map((p) => {
          return {
            value: p.idEstado,
            label: p.estado,
          }
        })
        setValue(`${objectName}.state`, finalStateOptions[0].value)
        const finalCountyOptions = postalCodeData.map((p) => {
          return {
            value: p.idMunicipio,
            label: p.municipio,
          }
        })
        setValue(`${objectName}.county`, finalCountyOptions[0].value)
        const finalSuburbOptions = _.chain(postalCodeData)
          .map((p) => {
            return {
              value: p.idColonia,
              label: p.colonia,
            }
          })
          .orderBy((o) => o.label)
          .value()
        if (finalSuburbOptions.length === 1) {
          setValue(`${objectName}.suburb`, finalSuburbOptions[0].value)
        }
        setStateOptions([finalStateOptions[0]])
        setCountyOptions([finalCountyOptions[0]])
        setSuburbOptions(finalSuburbOptions)
        if (_.get(errors, `${objectName}.state`)) {
          await trigger(`${objectName}.state`)
        }
        if (_.get(errors, `${objectName}.county`)) {
          await trigger(`${objectName}.county`)
        }
        if (_.get(errors, `${objectName}.suburb`)) {
          await trigger(`${objectName}.suburb`)
        }
      }
      setActualCountrySelected(selectedCountry)
      setFindingPostalCode(false)
    } else {
      if (actualCountrySelected === COUNTRY_CODE_MEXICO) {
        setValue(`${objectName}.state`, '')
        setValue(`${objectName}.county`, '')
        setValue(`${objectName}.suburb`, '')
        setActualCountrySelected(selectedCountry)
      }
      setStateOptions([])
      setCountyOptions([])
      setSuburbOptions([])
    }
    setLoading(false)
  }

  const loadInitialData = async (postalCode) => {
    await retrievePostalCodeData(postalCode)
  }

  useEffect(() => {
    if (
      selectedCountry === COUNTRY_CODE_MEXICO &&
      selectedPostalCode?.length === 5
    ) {
      loadInitialData(selectedPostalCode)
    } else {
      loadInitialData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedPostalCode])

  return (
    <Grid item xs={12} md={12}>
      <FormControl
        size="small"
        variant="outlined"
        className={clsx(classes.margin, classes.noLabel, classes.formOptions)}
        required={false}
        disabled={false}
        fullWidth>
        <Container className={clsx(classes.containerOptions)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel
                component="legend"
                className={clsx(
                  classes.margin,
                  classes.noLabel,
                  classes.formLabel
                )}>
                {formLabel}
              </FormLabel>
            </Grid>
            {renderSameDomicileField && (
              <Grid item xs={12}>
                <FormField
                  id={sameAddressName}
                  name={sameAddressName}
                  type="switch"
                  label={
                    <Typography variant="body1" color="primary">
                      Utilizar el mismo domicilio del solicitante
                    </Typography>
                  }
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={clsx(classes.margin, classes.formOption)}
                  labelClassName={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: '',
                    },
                  }}
                  errors={errors}
                />
              </Grid>
            )}
            {renderAddressFields && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.country`}
                    name={`${objectName}.country`}
                    type="select"
                    label="País"
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Selecciona el país del domicilio',
                      },
                    }}
                    errors={errors}
                    options={countryOptions}
                    // onChange={async (e) => {
                    //   if (e?.target?.value === COUNTRY_CODE_MEXICO) {
                    //     if (
                    //       selectedPostalCode &&
                    //       selectedPostalCode !== '' &&
                    //       selectedPostalCode.length === 5
                    //     ) {
                    //       await retrievePostalCodeData(selectedPostalCode)
                    //     } else {
                    //       await retrievePostalCodeData(null)
                    //     }
                    //   } else {
                    //     await retrievePostalCodeData(null)
                    //   }
                    // }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.postalCode`}
                    name={`${objectName}.postalCode`}
                    label="Código postal"
                    type="text"
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa el código postal del domicilio',
                      },
                      validate: {
                        length: (value) => {
                          return (
                            selectedCountry !== COUNTRY_CODE_MEXICO ||
                            (selectedCountry === COUNTRY_CODE_MEXICO &&
                              value?.length === 5) ||
                            'Debes ingresar 5 caracteres'
                          )
                        },
                      },
                    }}
                    errors={errors}
                    // onChange={async (e) => {
                    //   // Strange Issue
                    //   const selectedValue = e?.target?.value
                    //   if (
                    //     selectedCountry === COUNTRY_CODE_MEXICO &&
                    //     selectedValue &&
                    //     selectedValue !== '' &&
                    //     selectedValue.length === 5
                    //   ) {
                    //     await retrievePostalCodeData(selectedValue)
                    //   } else {
                    //     await retrievePostalCodeData(null)
                    //   }
                    //   // Strange Issue
                    //   // const selectedValue = e?.target?.value
                    //   // if (
                    //   //   selectedCountry === COUNTRY_CODE_MEXICO &&
                    //   //   (await trigger(`${objectName}.postalCode`))
                    //   // ) {
                    //   //   if (selectedValue !== '') {
                    //   //     await retrievePostalCodeData(selectedValue)
                    //   //   } else {
                    //   //     await retrievePostalCodeData(null)
                    //   //   }
                    //   // } else {
                    //   //   await retrievePostalCodeData(null)
                    //   // }
                    // }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.state`}
                    name={`${objectName}.state`}
                    label="Entidad federativa"
                    type={renderAddressFieldsAsSelects ? 'select' : 'text'}
                    required
                    disabled={
                      renderAddressFieldsAsSelects ||
                      (renderSameDomicileField && sameAddressSelected)
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa la entidad federativa del domicilio',
                      },
                    }}
                    errors={errors}
                    IconComponent={() => {
                      return null
                    }}
                    options={stateOptions}
                    endAdornment={
                      <>
                        {findingPostalCode ? (
                          <CircularProgress
                            color="inherit"
                            size={18}
                            fontSize="smal"
                          />
                        ) : null}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.county`}
                    name={`${objectName}.county`}
                    label="Municipio"
                    type={renderAddressFieldsAsSelects ? 'select' : 'text'}
                    required
                    disabled={
                      renderAddressFieldsAsSelects ||
                      (renderSameDomicileField && sameAddressSelected)
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa el municipio del domicilio',
                      },
                    }}
                    errors={errors}
                    IconComponent={() => {
                      return null
                    }}
                    options={countyOptions}
                    endAdornment={
                      <>
                        {findingPostalCode ? (
                          <CircularProgress
                            color="inherit"
                            size={18}
                            fontSize="smal"
                          />
                        ) : null}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.suburb`}
                    name={`${objectName}.suburb`}
                    label="Colonia"
                    type={renderAddressFieldsAsSelects ? 'select' : 'text'}
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa la colonia del domicilio',
                      },
                    }}
                    errors={errors}
                    IconComponent={() => {
                      return null
                    }}
                    options={suburbOptions}
                    endAdornment={
                      <>
                        {findingPostalCode ? (
                          <CircularProgress
                            color="inherit"
                            size={18}
                            fontSize="smal"
                          />
                        ) : null}
                      </>
                    }
                  />
                </Grid>
                <Hidden xsDown>
                  <Grid item sm={6} />
                </Hidden>
                <Grid item xs={12} sm={12}>
                  <FormField
                    id={`${objectName}.street`}
                    name={`${objectName}.street`}
                    label="Calle"
                    type="text"
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa la calle del domicilio',
                      },
                    }}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormField
                    id={`${objectName}.externalNumber`}
                    name={`${objectName}.externalNumber`}
                    label={isMobileDevice ? 'Num. ext.' : 'Número exterior'}
                    type="text"
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa número exterior del domicilio',
                      },
                    }}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormField
                    id={`${objectName}.internalNumber`}
                    name={`${objectName}.internalNumber`}
                    label={isMobileDevice ? 'Num. int.' : 'Número interior'}
                    type="text"
                    required={false}
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Ingresa número interior del domicilio',
                      },
                    }}
                    errors={errors}
                  />
                </Grid>
                {renderEmailField && (
                  <Grid item xs={12} sm={6}>
                    <FormField
                      id={emailName}
                      name={emailName}
                      label="Correo electrónico"
                      type="email"
                      required
                      disabled={renderSameDomicileField && sameAddressSelected}
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Ingresa un correo electrónico',
                        },
                        minLength: {
                          value: 6,
                          message: 'Debe ser mayor o igual a 6 caracteres',
                        },
                        pattern: {
                          value: isEmail,
                          message:
                            'Debes ingresar un correo electrónico válido',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Container>
      </FormControl>
    </Grid>
  )
}

DomicileForm.propTypes = {
  objectName: PropTypes.string.isRequired,
  sameAddressName: PropTypes.string,
  emailName: PropTypes.string,
  formLabel: PropTypes.string.isRequired,
  domicileType: PropTypes.oneOf([
    DOMICILE_TYPE_APPLICANT,
    DOMICILE_TYPE_ESTABLISHMENT,
    DOMICILE_TYPE_APPLICANT_BILLING,
  ]).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  selectedCountry: PropTypes.string,
  selectedPostalCode: PropTypes.string,
  sameAddressSelected: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  trigger: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
}

DomicileForm.defaultProps = {
  selectedCountry: undefined,
  selectedPostalCode: undefined,
  sameAddressName: undefined,
  emailName: undefined,
  sameAddressSelected: undefined,
}

const ApplicantForm = ({
  objectName,
  invoiceforApplicantName,
  applicantType,
  applicantKind,
  kindOfPersonOptions,
  nationalityOptions,
  invoiceforApplicantSelected,
  control,
  errors,
}) => {
  const classes = useStyles()
  const renderInvoiceforApplicant =
    applicantType === APPLICANT_TYPE_APPLICANT_BILLING
  const renderApplicantFields = !invoiceforApplicantSelected

  return (
    <>
      {renderInvoiceforApplicant && (
        <Grid item xs={12}>
          <FormField
            id={invoiceforApplicantName}
            name={invoiceforApplicantName}
            type="switch"
            label={
              <Typography variant="body1" color="primary">
                La facturación es para el solicitante
              </Typography>
            }
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(classes.margin, classes.formOption)}
            labelClassName={clsx(classes.margin, classes.noLabel)}
            control={control}
            rules={{
              required: {
                value: false,
                message: '',
              },
            }}
            errors={errors}
          />
        </Grid>
      )}
      {renderApplicantFields && (
        <Grid item xs={12} sm={6}>
          <FormField
            id={`${objectName}.kind`}
            name={`${objectName}.kind`}
            type="select"
            required
            disabled={invoiceforApplicantSelected}
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(classes.margin)}
            label="Tipo de persona"
            labelClassName={clsx(classes.margin, classes.noLabel)}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Selecciona el tipo de persona',
              },
            }}
            value={applicantKind}
            errors={errors}
            options={kindOfPersonOptions}
          />
        </Grid>
      )}
      {renderInvoiceforApplicant && (
        <Grid item xs={12} sm={6}>
          <FormField
            id={`${objectName}.rfc`}
            name={`${objectName}.rfc`}
            label="RFC"
            type="text"
            required
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(classes.margin, classes.noLabel)}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Ingresa el rfc para facturar',
              },
              validate: {
                length: (value) => {
                  const length = applicantKind === PHYSICAL_PERSON ? 13 : 12
                  return (
                    value?.length === length ||
                    `Debes ingresar ${length} caracteres`
                  )
                },
              },
            }}
            errors={errors}
          />
        </Grid>
      )}
      {!renderInvoiceforApplicant && (
        <Hidden xsDown>
          <Grid item sm={6} />
        </Hidden>
      )}
      {renderApplicantFields && (
        <>
          {applicantKind ? (
            <>
              <Grid item xs={12} md={12}>
                <FormField
                  id={`${objectName}.name`}
                  name={`${objectName}.name`}
                  label={
                    applicantKind === PHYSICAL_PERSON
                      ? 'Nombre(s)'
                      : 'Razón social'
                  }
                  type="text"
                  required
                  disabled={invoiceforApplicantSelected}
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message:
                        applicantKind === PHYSICAL_PERSON
                          ? 'Ingresa tu nombre'
                          : 'Ingresa tu razón social',
                    },
                    minLength: {
                      value: 3,
                      message: 'Debe ser mayor o igual a 3 caracteres',
                    },
                  }}
                  errors={errors}
                />
              </Grid>
              {applicantKind === PHYSICAL_PERSON ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      id={`${objectName}.surname`}
                      name={`${objectName}.surname`}
                      label="Primer apellido"
                      type="text"
                      required
                      disabled={invoiceforApplicantSelected}
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Ingresa tu primer apellido',
                        },
                        minLength: {
                          value: 2,
                          message: 'Debe ser mayor o igual a 2 caracteres',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      id={`${objectName}.secondSurname`}
                      name={`${objectName}.secondSurname`}
                      label="Segundo apellido"
                      type="text"
                      required={false}
                      disabled={invoiceforApplicantSelected}
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: 'Ingresa tu segundo apellido',
                        },
                        minLength: {
                          value: 2,
                          message: 'Debe ser mayor o igual a 2 caracteres',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                </>
              ) : null}
            </>
          ) : null}
          <Grid item xs={12} sm={6}>
            <FormField
              id={`${objectName}.nationality`}
              name={`${objectName}.nationality`}
              type="select"
              label="Nacionalidad"
              required
              disabled={invoiceforApplicantSelected}
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: 'Selecciona la nacionalidad del solicitante',
                },
              }}
              errors={errors}
              options={nationalityOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneField
              id={`${objectName}.phoneNumber`}
              name={`${objectName}.phoneNumber`}
              label="Teléfono"
              dialCodeLabel="Código"
              key="phoneNumber"
              required
              disabled={invoiceforApplicantSelected}
              variant="outlined"
              size="small"
              fullWidth
              renderFlag
              renderDialCodeLabel
              dialCodeName={`${objectName}.countryCallingCode`}
              className={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa tu número de teléfono',
                },
              }}
              rulesForDialCode={{
                required: {
                  value: true,
                  message: 'Selecciona el código de tu país',
                },
              }}
              errors={errors}
              inputProps={{
                // eslint-disable-next-line no-nested-ternary
                format: '## #### ####',
                mask: ' ',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
        </>
      )}
    </>
  )
}

ApplicantForm.propTypes = {
  objectName: PropTypes.string.isRequired,
  invoiceforApplicantName: PropTypes.string,
  applicantType: PropTypes.oneOf([
    APPLICANT_TYPE_APPLICANT,
    APPLICANT_TYPE_APPLICANT_BILLING,
  ]).isRequired,
  applicantKind: PropTypes.oneOf([
    PHYSICAL_PERSON,
    MORAL_PERSON,
    null,
    undefined,
  ]),
  kindOfPersonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  nationalityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  invoiceforApplicantSelected: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
}

ApplicantForm.defaultProps = {
  invoiceforApplicantName: undefined,
  applicantKind: undefined,
  invoiceforApplicantSelected: undefined,
}

const FinalBrandData = () => {
  const classes = useStyles()
  const [countryOptions, setCountryOptions] = React.useState(null)
  const [nationalityOptions, setNationalityOptions] = React.useState(null)

  const kindOfPersonOptions = kindOfPersonCatalog.map((option) => {
    return {
      value: option.value,
      label: I18n.get(option.label),
    }
  })

  const {
    authenticated,
    setLoading,
    setEmailContactStatus,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const { openModal } = useInfoModal()

  const {
    setCurrentStep,
    trademarkApp,
    setTrademarkApp,
    trademarkAppID,
  } = useTrademarkContext()

  const {
    handleSubmit,
    control,
    errors,
    watch,
    reset,
    trigger,
    setValue,
  } = useForm({ defaultValues: { brandData: trademarkApp?.brandData } })
  const applicantKind = watch('brandData.applicant.kind')
  const applicantBillingKind = watch('brandData.applicantBilling.kind')
  const applicantDomicileCountry = watch('brandData.applicant.domicile.country')
  const establishmentDomicileCountry = watch(
    'brandData.establishmentDomicile.country'
  )
  const applicantBillingDomicileCountry = watch(
    'brandData.applicantBilling.domicile.country'
  )
  const sameAddressForEstablishmentWatch = watch(
    'brandData.sameAddressForEstablishment'
  )
  const sameAddressForBillingWatch = watch('brandData.sameAddressForBilling')
  const applicantDomicilePostalCode = watch(
    'brandData.applicant.domicile.postalCode'
  )
  const establishmentDomicilePostalCode = watch(
    'brandData.establishmentDomicile.postalCode'
  )
  const applicantBillingDomicilePostalCode = watch(
    'brandData.applicantBilling.domicile.postalCode'
  )
  const invoiceforApplicantWatch = watch('brandData.invoiceforApplicant')
  const mustBillWatch = watch('brandData.mustBill')

  const resetValues = ({ brandData }) => {
    // Default Values
    reset({
      brandData: {
        ...brandData,
        applicant: {
          ...brandData?.applicant,
          domicile: {
            ...brandData?.applicant?.domicile,
            country:
              brandData?.applicant?.domicile?.country || COUNTRY_CODE_MEXICO,
          },
          nationality: brandData?.applicant?.nationality || COUNTRY_CODE_MEXICO,
        },
        applicantBilling: {
          ...brandData?.applicantBilling,
          domicile: {
            ...brandData?.applicantBilling?.domicile,
            country:
              brandData?.applicantBilling?.domicile?.country ||
              COUNTRY_CODE_MEXICO,
          },
          nationality:
            brandData?.applicantBilling?.nationality || COUNTRY_CODE_MEXICO,
        },
        establishmentDomicile: {
          ...brandData?.establishmentDomicile,
          country:
            brandData?.establishmentDomicile?.country || COUNTRY_CODE_MEXICO,
        },
      },
    })
  }

  const retrieveTrademarkApplication = async () => {
    setLoading(true)
    let trademarkAppReaded
    const initialTrademarkAppReaded = await readTrademarkApplication({
      id: trademarkAppID,
      authenticated,
    })
    trademarkAppReaded = initialTrademarkAppReaded
    if (
      trademarkAppReaded.trademarkApplicationStage !==
      trademarkApplicationStages.supplementalApplicantData
    ) {
      trademarkAppReaded = await moveTrademarkToStage({
        trademarkApp: trademarkAppReaded,
        trademarkApplicationStage:
          trademarkApplicationStages.supplementalApplicantData,
        includeBrands: true,
        alterBrandData: true,
        authenticated,
      })
    }
    setTrademarkApp({ ...trademarkAppReaded })
    resetValues({ brandData: trademarkAppReaded.brandData })
    setLoading(false)
  }

  const fetchCountries = useMemo(
    () =>
      _.throttle(async (request, callback) => {
        const data = await getCountries()
        const sortedData = _.sortBy(data, (item) => {
          return item.name
        })
        callback(sortedData)
      }, 1000),
    []
  )

  useEffect(() => {
    fetchCountries({}, (countries) => {
      const cOptions = countries?.map((c) => {
        return {
          value: c?.code,
          label: c?.name,
        }
      })
      const nOptions = _.chain(countries)
        .sortBy((item) => item.nationality)
        .map((c) => {
          return {
            value: c?.code,
            label: c?.nationality,
          }
        })
        .value()
      setCountryOptions(cOptions)
      setNationalityOptions(nOptions)
    })
  }, [fetchCountries])

  // https://github.com/aws-amplify/amplify-js/issues/2903
  // https://github.com/aws/aws-sdk-js/issues/232
  // https://github.com/aws-amplify/amplify-js/issues/3236
  useEffect(() => {
    retrieveTrademarkApplication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setContactSpeedDialEnabled(true)
    const email = emailContactMessages.authenticatedUserApplicantData(
      trademarkApp?.brandData?.id,
      trademarkApp?.brandData?.name,
      trademarkApp?.owner
    )

    setEmailContactStatus({
      email: MAILTO_ADDRESS,
      subject: email?.subject,
      body: email?.message,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [emailContactMessages, setEmailContactStatus])

  const onSubmit = async (data) => {
    openModal({
      title: 'Finalizar solicitud de registro de marca',
      content: (
        <div className={classes.infoText}>
          ¿Deseas finalizar la solicitud de registro para tu marca?
        </div>
      ),
      confirmationText: 'Confirmar',
      cancellationText: 'Cerrar',
    })
      .then(async () => {
        setLoading(true)
        const {
          brandData: {
            applicant,
            applicantBilling,
            establishmentDomicile,
            mustBill,
            invoiceforApplicant,
            sameAddressForBilling,
            sameAddressForEstablishment,
          },
        } = data

        // eslint-disable-next-line no-unused-vars
        const applicantUpdated = await putApplicant({
          id: trademarkApp?.brandData?.applicant?.id,
          kind: applicant.kind,
          name: applicant.name || '',
          surname: applicant.surname || '',
          secondSurname: applicant.secondSurname || '',
          rfc: invoiceforApplicant
            ? applicantBilling.rfc || ''
            : trademarkApp?.brandData?.applicant?.rfc || '',
          nationality: applicant.nationality,
          email: applicant.email,
          domicile: applicant.domicile,
          countryCallingCode: applicant.countryCallingCode,
          phoneNumber: applicant.phoneNumber,
          extension: applicant.extension,
          _version: trademarkApp?.brandData?.applicant._version,
        })
        let applicantBillingID = trademarkApp?.brandData?.applicant?.id
        if (mustBill) {
          const updateAppplicantBilling =
            trademarkApp?.brandData?.applicantBilling?.id &&
            trademarkApp?.brandData?.applicantBilling?.id !==
              trademarkApp?.brandData?.applicant?.id
          const applicantBillingInformation = {
            id: updateAppplicantBilling
              ? trademarkApp?.brandData?.applicantBilling?.id
              : undefined,
            kind: invoiceforApplicant ? applicant.kind : applicantBilling.kind,
            name: invoiceforApplicant
              ? applicant.name || ''
              : applicantBilling.name || '',
            surname: invoiceforApplicant
              ? applicant.surname || ''
              : applicantBilling.surname || '',
            secondSurname: invoiceforApplicant
              ? applicant.secondSurname || ''
              : applicantBilling.secondSurname || '',
            rfc: applicantBilling.rfc || '',
            nationality: invoiceforApplicant
              ? applicant.nationality
              : applicantBilling.nationality,
            email: sameAddressForBilling
              ? applicant.email
              : applicantBilling.email,
            domicile: sameAddressForBilling
              ? applicant.domicile
              : applicantBilling.domicile,
            countryCallingCode: invoiceforApplicant
              ? applicant.countryCallingCode
              : applicantBilling.countryCallingCode,
            phoneNumber: invoiceforApplicant
              ? applicant.phoneNumber
              : applicantBilling.phoneNumber,
            extension: invoiceforApplicant
              ? applicant.extension
              : applicantBilling.extension,
            _version: updateAppplicantBilling
              ? trademarkApp?.brandData?.applicantBilling._version
              : undefined,
          }
          const updatedApplicantBilling = updateAppplicantBilling
            ? await putApplicant(applicantBillingInformation)
            : await postApplicant(applicantBillingInformation)
          applicantBillingID = updatedApplicantBilling?.id
        }
        // eslint-disable-next-line no-unused-vars
        const updatedBrandData = await putBrandData({
          id: trademarkApp?.brandData?.id,
          mustBill,
          invoiceforApplicant: invoiceforApplicant || false,
          sameAddressForBilling: sameAddressForBilling || false,
          sameAddressForEstablishment: sameAddressForEstablishment || false,
          establishmentDomicile: sameAddressForEstablishment
            ? applicant.domicile
            : establishmentDomicile,
          applicantBillingID,
          _version: trademarkApp?.brandData?._version,
        })
        const trademarkAppReaded = await readTrademarkApplication({
          id: trademarkApp?.id,
          authenticated,
        })
        const trademarkAppUpdated = await moveTrademarkToStage({
          trademarkApp: trademarkAppReaded,
          trademarkApplicationStage: trademarkApplicationStages.completed,
          includeBrands: true,
          alterBrandData: true,
          authenticated,
        })
        setTrademarkApp({ ...trademarkAppUpdated })
        setCurrentStep((prevActiveStep) => prevActiveStep + 1)
        setLoading(false)
      })
      .catch(() => {})
  }

  const render =
    trademarkApp && applicantKind && countryOptions && nationalityOptions
  if (!render) return null

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>Datos complementarios del solicitante</Title>
            </Grid>
            <ApplicantForm
              objectName="brandData.applicant"
              applicantType={APPLICANT_TYPE_APPLICANT}
              applicantKind={applicantKind}
              nationalityOptions={nationalityOptions}
              kindOfPersonOptions={kindOfPersonOptions}
              control={control}
              trigger={trigger}
              errors={errors}
            />
            <DomicileForm
              objectName="brandData.applicant.domicile"
              emailName="brandData.applicant.email"
              formLabel="Domicilio del solicitante"
              domicileType={DOMICILE_TYPE_APPLICANT}
              countryOptions={countryOptions}
              selectedCountry={applicantDomicileCountry}
              selectedPostalCode={applicantDomicilePostalCode}
              control={control}
              trigger={trigger}
              errors={errors}
              setValue={setValue}
            />
            {trademarkApp?.brandData?.hasBeenUsed && (
              <DomicileForm
                objectName="brandData.establishmentDomicile"
                sameAddressName="brandData.sameAddressForEstablishment"
                formLabel="Domicilio del establecimiento"
                domicileType={DOMICILE_TYPE_ESTABLISHMENT}
                countryOptions={countryOptions}
                selectedCountry={establishmentDomicileCountry}
                selectedPostalCode={establishmentDomicilePostalCode}
                sameAddressSelected={sameAddressForEstablishmentWatch}
                control={control}
                trigger={trigger}
                errors={errors}
                setValue={setValue}
              />
            )}
            <Grid item xs={12}>
              <FormField
                id="brandData.mustBill"
                name="brandData.mustBill"
                type="switch"
                label={
                  <Typography variant="body1" color="primary">
                    Deseo facturar
                  </Typography>
                }
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin, classes.formOption)}
                labelClassName={clsx(classes.margin, classes.noLabel)}
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: '',
                  },
                }}
                errors={errors}
              />
            </Grid>
            {mustBillWatch && (
              <Grid item xs={12} md={12}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={clsx(classes.margin, classes.noLabel)}
                  required={false}
                  disabled={false}
                  fullWidth>
                  <Container className={clsx(classes.containerOptions)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormLabel
                          component="legend"
                          className={clsx(
                            classes.margin,
                            classes.noLabel,
                            classes.formLabel
                          )}>
                          Datos para facturación
                        </FormLabel>
                      </Grid>
                      <ApplicantForm
                        objectName="brandData.applicantBilling"
                        invoiceforApplicantName="brandData.invoiceforApplicant"
                        applicantType={APPLICANT_TYPE_APPLICANT_BILLING}
                        applicantKind={
                          invoiceforApplicantWatch
                            ? applicantKind
                            : applicantBillingKind
                        }
                        nationalityOptions={nationalityOptions}
                        kindOfPersonOptions={kindOfPersonOptions}
                        invoiceforApplicantSelected={invoiceforApplicantWatch}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                      />
                    </Grid>
                  </Container>
                </FormControl>
              </Grid>
            )}
            {mustBillWatch && (
              <DomicileForm
                objectName="brandData.applicantBilling.domicile"
                sameAddressName="brandData.sameAddressForBilling"
                emailName="brandData.applicantBilling.email"
                formLabel="Domicilio para facturación"
                domicileType={DOMICILE_TYPE_APPLICANT_BILLING}
                countryOptions={countryOptions}
                selectedCountry={applicantBillingDomicileCountry}
                selectedPostalCode={applicantBillingDomicilePostalCode}
                sameAddressSelected={sameAddressForBillingWatch}
                control={control}
                trigger={trigger}
                errors={errors}
                setValue={setValue}
              />
            )}
          </Grid>
          <div className={classes.nextButtons}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              className={classes.nextButtonContainer}>
              <Grid
                item
                xs={10}
                sm={4}
                md={3}
                lg={2}
                className={classes.nextButton}>
                <Button
                  onClick={() => {
                    setLoading(true)
                    setTimeout(() => {
                      setCurrentStep((prevActiveStep) => prevActiveStep - 1)
                      setLoading(false)
                    }, 200)
                  }}
                  variant="contained"
                  color="secondary"
                  fullWidth>
                  Regresar
                </Button>
              </Grid>
              <Grid
                item
                xs={10}
                sm={4}
                md={3}
                lg={2}
                className={classes.nextButton}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth>
                  Finalizar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </form>
  )
}

export default withMainAuthenticator(FinalBrandData)
