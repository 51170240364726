import { API } from 'aws-amplify'
import Debug from 'debug'
import { getPaymentRequest } from '../../graphql/queries'
import { updatePaymentRequest } from '../../graphql/mutations'

const debug = Debug('services:brands:brands')

const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const getPaymentRequestById = async (id) => {
  try {
    const result = await API.graphql({
      query: getPaymentRequest,
      variables: {
        id,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return result?.data?.getPaymentRequest
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const updatePaymentRequestStatus = async (id, status, _version) => {
  try {
    const result = await API.graphql({
      query: updatePaymentRequest,
      variables: {
        input: {
          id,
          status,
          _version,
        },
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return result?.data?.updatePaymentRequest
  } catch (e) {
    debug('e', e)
    return null
  }
}
