import React from 'react'
import { PropTypes } from 'prop-types'
/* eslint-disable import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import { Container, Typography, Paper } from '@material-ui/core'
import { Notification } from './Notification'

const NotificationList = (props) => {
  const { notifications, isMobile } = props
  const renderNotifications = (notificationList) => {
    if (!notificationList || notificationList.length === 0)
      return (
        <Paper>
          <Typography>{I18n.get('There are no notifications')}</Typography>
        </Paper>
      )
    return (
      <>
        {notifications.map((notification) => {
          return (
            <Notification
              key={notification.id}
              notification={notification}
              isMobile={isMobile}
            />
          )
        })}
      </>
    )
  }

  return <Container>{renderNotifications(notifications)}</Container>
}

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobile: PropTypes.bool,
}

NotificationList.defaultProps = {
  isMobile: false,
}

export default NotificationList
