import React, { useState, useEffect } from 'react'
import { useNavigate } from '@reach/router'
import {
  Slide,
  Container,
  Grid,
  Typography,
  Box,
  Divider,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useSnackbar } from 'notistack'
import { NotificationList } from '../../components/account'
import { useLayoutContext } from '../../components/context'
import { getNotificationsByOwnerAndCreatedAt } from '../../services'
import { Title } from '../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  main: {
    justifyContent: 'center',
  },
  mainBox: {
    maxHeight: '100vh',
  },
  title: {
    padding: theme.spacing(2),
    marginBottom: '45px',
  },
  notifications: {
    maxHeight: '100vh',
    overflow: 'auto',
  },
  close: {
    marginTop: '5px',
  },
  separator: {
    height: '20px',
  },
  back: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

const Notifications = () => {
  const [loadedNotifications, setLoadedNotifications] = useState(undefined)
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const {
    showNotifications,
    setShowNotifications,
    setShowTrademarkDetail,
    setShowTrademarks,
    currentUser,
  } = useLayoutContext()
  const { enqueueSnackbar } = useSnackbar()
  // eslint-disable-next-line no-shadow
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    const fetchNotifications = async () => {
      const fetchedNotifications = await getNotificationsByOwnerAndCreatedAt(
        currentUser.username
      )
      setLoadedNotifications(fetchedNotifications)
    }
    if (showNotifications && currentUser) {
      fetchNotifications()
    }
  }, [currentUser, showNotifications])

  const handleEmptyNotifications = () => {
    enqueueSnackbar('No hay ninguna notificación', {
      preventDuplicate: true,
      variant: 'warning',
    })
    setShowNotifications(false)
  }

  const handleReturn = () => {
    setShowTrademarkDetail(false)
    setShowTrademarks(false)
    setShowNotifications(false)
    navigate('/')
  }

  const render = loadedNotifications
  if (!render) {
    return null
  }

  return (
    <>
      {(!loadedNotifications || loadedNotifications.length === 0) &&
      showNotifications ? (
        handleEmptyNotifications()
      ) : (
        <Slide
          mountOnEnter
          unmountOnExit
          in={showNotifications}
          timeout={{ appear: 300, enter: 300, exit: 100 }}>
          {isMobileDevice ? (
            <Box
              bgcolor="white"
              top={0}
              p={2}
              position="absolute"
              zIndex="tooltip"
              className={classes.mainBox}>
              <Grid container xs={12} className={classes.main}>
                <Grid container item xs={12} className={classes.title}>
                  <Grid item xs={12}>
                    <Grid item xs={2} className={classes.close}>
                      <HighlightOffIcon
                        color="primary"
                        onClick={() => handleReturn()}
                      />
                    </Grid>
                    <Divider />
                    <Title>Notificaciones</Title>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.notifications}>
                  <NotificationList
                    notifications={loadedNotifications}
                    isMobile={isMobileDevice}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Container className={classes.root}>
              <Grid container className={classes.main}>
                <Grid item xs={12} className={classes.title}>
                  <Grid
                    container
                    className={classes.back}
                    onClick={() => handleReturn()}>
                    <Grid item>
                      <ArrowBackIosIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="link">Regresar</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Title>Notificaciones</Title>
                  <div className={classes.separator} />
                </Grid>
                <Grid item xs={12} className={classes.notifications}>
                  <NotificationList
                    notifications={loadedNotifications}
                    isMobile={isMobileDevice}
                  />
                </Grid>
              </Grid>
            </Container>
          )}
        </Slide>
      )}
    </>
  )
}

export default Notifications
