import { API } from 'aws-amplify'
import Debug from 'debug'
// eslint-disable-next-line import/named
import { notificationsByOwnerAndCreatedAt } from '../../graphql/queries'

const debug = Debug('services:notifications:notifications')

const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const getNotificationsByOwnerAndCreatedAt = async (owner) => {
  try {
    const input = {
      owner,
      sortDirection: 'DESC',
    }
    const result = await API.graphql({
      query: notificationsByOwnerAndCreatedAt,
      variables: {
        ...input,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return result.data.notificationsByOwnerAndCreatedAt.items
  } catch (e) {
    debug('e', e)
    return null
  }
}
