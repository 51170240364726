import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
// import primary from '@material-ui/core/colors/blueGrey'
// import primary from '@material-ui/core/colors/blue'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#3e877d',
      main: '#005a51',
      dark: '#003029',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#e1a800',
      main: '#e1a800',
      dark: '#aa7900',
      contrastText: '#000000',
    },
    error: {
      main: red.A400,
    },
    brandStatusColors: {
      created: '#499CEE',
      granted: '#6CC75F',
      denied: '#D82911',
      cancelled: '#A3A6A7',
    },
    brandStageColors: {
      received: '#499CEE',
      paid: '#499CEE',
      inProcess: '#EE9E49',
      presented: '#499CEE',
      finalized: '#6CC75F',
    },
  },
  toast: {
    position: 'relative',
  },
})

export default theme
