export const APP_ID = process.env.REACT_APP_ID || 'portal'
export const VERSION = process.env.REACT_APP_VERSION
export const ACCEPTED_COGNITO_GROUPS =
  process.env.REACT_APP_ACCEPTED_COGNITO_GROUPS ||
  '["ADMINISTRATOR", "OPERATOR", "CUSTOMER"]'

export const brandClassColors = {
  '1': '#1E313B',
  '2': '#728793',
  '3': '#48668A',
  '4': '#251340',
  '5': '#A8A5D4',
  '6': '#3E57C7',
  '7': '#443854',
  '8': '#8FA1FF',
  '9': '#67AACC',
  '10': '#80C5CF',
  '11': '#B1C299',
  '12': '#53AD8E',
  '13': '#068055',
  '14': '#09330A',
  '15': '#383523',
  '16': '#6E756C',
  '17': '#115553',
  '18': '#04471C',
  '19': '#A1BE72',
  '20': '#448204',
  '21': '#3B262D',
  '22': '#6E0E30',
  '23': '#A17283',
  '24': '#7D4250',
  '25': '#3D156A',
  '26': '#56638A',
  '27': '#595774',
  '28': '#9D9CA9',
  '29': '#2EACEE',
  '30': '#096291',
  '31': '#B37452',
  '32': '#8A4137',
  '33': '#B55405',
  '34': '#DDB771',
  '35': '#E9BE52',
  '36': '#FB8F67',
  '37': '#D15151',
  '38': '#794F40',
  '39': '#92290F',
  '40': '#C26969',
  '41': '#3CDADD',
  '42': '#F37089',
  '43': '#73C864',
  '44': '#A766BD',
  '45': '#EE2929',
}

export const OPENPAY_ERRORS = {
  1000: 'Ocurrió un error interno en el servidor del Proveedor de Pagos.',
  1001: 'El pago no se puede realizar con las condiciones de pago dadas. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  1002: 'La llamada no está autenticada o la autenticación es incorrecta.',
  1003: 'El pago no se puede realizar con las condiciones de pago dadas. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  1004: 'Un servicio necesario para el procesamiento de la transacción no se encuentra disponible.',
  1005: 'Uno de los recursos requeridos no existe.',
  1006: 'Ya existe una transacción con el mismo ID de orden.',
  1007: 'La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta del Proveedor de Pagos no fue aceptada.',
  1008: 'Una de las cuentas requeridas en la petición se encuentra desactivada.',
  1009: 'El cuerpo de la petición es demasiado grande.',
  1010: 'La clave pública se está utilizando para realizar una solicitud que requiere la clave privada, o la clave privada se está utilizando desde el Frontend.',
  1011: 'Se solicita un recurso que está marcado como eliminado.',
  1012: 'El monto de la transacción está fuera de los limites permitidos.',
  1013: 'La operación no está permitida para el recurso.',
  1014: 'La cuenta está inactiva.',
  1015: 'No se ha obtenido respuesta desde el gateway.',
  1016: 'El email del comercio ya ha sido procesado.',
  1017: 'El gateway no se encuentra disponible en ese momento.',
  1018: 'El número de intentos de cargo es mayor al permitido.',
  1020: 'El número de decimales no está permitido para esta moneda.',
  2001: 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.',
  2002: 'La tarjeta con este número ya se encuentra registrada en el cliente.',
  2003: 'El cliente con este identificador externo (External ID) ya existe.',
  2004: 'El dígito verificador del número de tarjeta es inválido de acuerdo al algoritmo Luhn.',
  2005: 'La fecha de expiración de la tarjeta es anterior a la fecha actual.',
  2006: 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.',
  2007: 'El número de tarjeta es de prueba, solamente puede usarse en el modo de pruebas del Proveedor de Pagos.',
  2008: 'La tarjeta no es valida para puntos.',
  2009: 'El código de seguridad de la tarjeta (CVV2) es inválido.',
  2010: 'Falló la autenticación de 3D Secure.',
  2011: 'EL tipo de tarjeta no está soportado.',
  3001: 'La tarjeta fue declinada. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3002: 'La tarjeta ha expirado. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3003: 'La tarjeta no tiene fondos suficientes. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3004: 'La tarjeta ha sido identificada como una tarjeta robada. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3005: 'La tarjeta ha sido rechazada por el sistema antifraudes. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3006: 'La operación no está permitida para este cliente o esta transacción.',
  3007: 'Depreciado. La tarjeta fue declinada. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3008: 'La tarjeta no es soportada en transacciones en línea. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3009: 'La tarjeta fue reportada como perdida. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3010: 'El banco ha restringido la tarjeta. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3011: 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.',
  3012: 'Se requiere solicitar al banco autorización para realizar este pago.',
  3201: 'El comercio no maneja promociones.',
  3203: 'Promoción no válida para este tipo de tarjeta.',
  3204: 'El monto de la transacción es menor que el mínimo para la promoción.',
  3205: 'Promoción no permitida.',
  4001: 'La cuenta del Provedor de Pagos no tiene fondos suficientes.',
  4002: 'La operación no puede ser completada hasta que sean pagadas las comisiones pendientes.',
  5001: 'La orden con este identificador externo ya existe.',
  6001: 'El webhook del Provedor de Pagos ya ha sido procesado.',
  6002: 'No se ha podido conectar con el servicio de webhook del Provedor de Pagos.',
  6003: 'El servicio del Provedor de Pagos ha respondido con errores.',
}

export const PHYSICAL_PERSON = 'physical'
export const MORAL_PERSON = 'moral'

export const kindOfPerson = [
  { value: PHYSICAL_PERSON, label: 'Physical' },
  { value: MORAL_PERSON, label: 'Moral' },
]

export const TAX_RATE = 0.16

export const PAYMENT_METHOD_CARD = 'C'
export const PAYMENT_METHOD_STORE = 'T'
export const PAYMENT_METHOD_DEPOSIT = 'S'

export const paymentMethods = [
  {
    value: PAYMENT_METHOD_CARD,
    label: 'Tarjeta de crédito/débito',
    icon: 'CreditCardOutlined',
  },
  {
    value: PAYMENT_METHOD_DEPOSIT,
    label: 'Depósito',
    icon: 'AccountBalanceOutlined',
  },
  {
    value: PAYMENT_METHOD_STORE,
    label: 'Pago en tiendas',
    icon: 'StoreOutlined',
  },
]

export const CARD_ISSUER_VISA = 'visa'
export const CARD_ISSUER_MASTERCARD = 'mastercard'
export const CARD_ISSUER_AMEX = 'amex'

export const acceptedCards = [
  CARD_ISSUER_VISA,
  CARD_ISSUER_MASTERCARD,
  CARD_ISSUER_AMEX,
]

export const OPENPAY_MERCHANT_ID = process.env.REACT_APP_OPENPAY_MERCHANT_ID
export const OPENPAY_PUBLIC_API_KEY =
  process.env.REACT_APP_OPENPAY_PUBLIC_API_KEY
export const OPENPAY_IS_SANBOX = process.env.REACT_APP_OPENPAY_IS_SANBOX

export const PAYMENT_TX_STATUS_PAID = 'paid'
export const PAYMENT_TX_STATUS_NOT_PAID = 'notPaid'

export const BRAND_STAGE_RECEIVED = 'received'
export const BRAND_STAGE_PAID = 'paid'
export const BRAND_STAGE_IN_PROCESS = 'inProcess'
export const BRAND_STAGE_PRESENTED = 'presented'
export const BRAND_STAGE_FINALIZED = 'finalized'

export const FEE_TYPE_TRADEMARK_REGISTRATION = 'trademarkRegistrationFee'
export const FEE_TYPE_IMPI_RIGHTS = 'impiRightsFee'

export const FEE_STATUS_ENABLED = 'enabled'
export const FEE_STATUS_DISABLED = 'disabled'

export const AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE = `
<p>
Formato de seguridad válido para la contraseña:<br/>
- Mínimo 8 caracteres<br/>
- Al menos un caracter:<br/>
    · Minúscula<br/>
    · Mayúscula<br/>
    · Número<br/>
    · Especial: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \`
</p>
  `

export const COUNTRY_CODE_MEXICO = 'MX'

export const DOMICILE_TYPE_APPLICANT = 'APPLICANT'
export const DOMICILE_TYPE_ESTABLISHMENT = 'ESTABLISHMENT'
export const DOMICILE_TYPE_APPLICANT_BILLING = 'APPLICANT_BILLING'

export const APPLICANT_TYPE_APPLICANT = 'APPLICANT'
export const APPLICANT_TYPE_APPLICANT_BILLING = 'APPLICANT_BILLING'

export const trademarkApplicationStatuses = {
  created: 'created',
  inProgress: 'inProgress',
  completed: 'completed',
}

export const trademarkApplicationStages = {
  registration: 'registration',
  payment: 'payment',
  supplementalData: 'supplementalData',
  supplementalApplicantData: 'supplementalApplicantData',
  completed: 'completed',
}

export const TRADEMARK_LIFECYCLE_STAGES = [
  {
    stage: 'received',
    stageIndex: 0,
    label: 'Recibido',
    description: 'Solicitud Recibida',
  },
  {
    stage: 'paid',
    stageIndex: 1,
    label: 'Pagado',
    description: 'Solicitud Pagada',
  },
  {
    stage: 'inProcess',
    stageIndex: 2,
    label: 'En proceso',
    description: 'Solicitud en Proceso',
  },
  {
    stage: 'presented',
    stageIndex: 3,
    label: 'Presentada',
    description: 'Solicitud Presentada',
  },
  {
    stage: 'finalized',
    stageIndex: 4,
    label: 'Finalizada',
    description: 'Solicitud Finalizada',
  },
]

export const applicantKind = {
  physical: {
    id: 'physical',
    label: 'Físico',
  },
  moral: {
    id: 'moral',
    label: 'Moral',
  },
}

export const OSTypes = {
  android: 'Android',
  ios: 'iOS',
  windowsPhone: 'Windows Phone',
  unkown: 'unkown',
}

export const VALUE_NOT_AVAILABLE = 'N/D'

export const paymentRequestConcepts = {
  professional_services: {
    value: 'professional_services',
    label: 'Servicios Profesionales',
  },
}

export const PAYMENT_REQUEST_STATUS_ACCEPTED = 'accepted'
export const PAYMENT_REQUEST_STATUS_REJECTED = 'rejected'

export const paymentRequestStatus = {
  accepted: {
    value: 'accepted',
    label: 'Aceptada',
  },
  rejected: {
    value: 'rejected',
    label: 'Rechazada',
  },
}

export const WHATSAPP_PHONE = process.env.REACT_APP_WHATSAPP_PHONE
export const WHATSAPP_TEXT = process.env.REACT_APP_WHATSAPP_TEXT
export const MAILTO_ADDRESS = process.env.REACT_APP_MAILTO_ADDRESS
export const MAILTO_SUBJECT = process.env.REACT_APP_MAILTO_SUBJECT
export const MAILTO_BODY = process.env.REACT_APP_MAILTO_BODY

export const voucherTypes = {
  bank: 'bank',
  store: 'store',
}

export const whatsappContactMessages = {
  unauthenticatedUserBrandRegistration: (brandName, email) => {
    return brandName && email
      ? `Usuario (anónimo): ${email}, en el proceso de registro de mi marca: ${brandName}`
      : `Usuario (anónimo) en el proceso de registro de una marca`
  },
  unauthenticatedUserPayment: (brandName, email) => {
    return brandName && email
      ? `Usuario (anónimo): ${email}, en el proceso de pago de mi marca: ${brandName}`
      : `Usuario (anónimo) en el proceso de pago de una marca`
  },
  // eslint-disable-next-line no-unused-vars
  unauthenticatedUserAuth: (authState) => {
    /* return authState
      ? `Usuario (anónimo), en el proceso de ${
          authState === 'signIn' ? 'inicio de sesión ' : 'registro de cuenta '
        }de usuario`
      : `Usuario (anónimo) en el proceso de inicio/registro de cuenta de usuario` */
    return 'Usuario anónimo en proceso de autenticación/registro de cuenta'
  },
}

export const emailContactMessages = {
  authenticatedUserBrandSelection: (brandName, email) => {
    const subject = `Selección del Tipo de Marca - ${brandName}`
    const message = `Usuario: ${email}\n
      Marca: ${brandName}\n
      Proceso de selección del tipo de mi marca`
    return { subject, message }
  },
  authenticatedUserBrandRegistration: (brandName, email) => {
    if (brandName || email) {
      const subject = `Registro de Marca ${brandName ? '- brandName' : ''}`
      const message = `Usuario: ${email}\n
        ${brandName ? `Marca: ${brandName}` : ''}\n
        Registro de Marca: Paso -> Registro de marca`
      return { subject, message }
    }
    const subject = `Registro de Marca`
    const message = `Proceso de registro de mi marca`
    return { subject, message }
  },
  authenticatedUserPayment: (trademarkAppId, brandName, email) => {
    const subject = `Pago de Registro de Marca ${
      trademarkAppId ? `para la Solicitud ${trademarkAppId}` : ''
    }`
    const message = `Usuario: ${email}\n
    ${brandName ? `Marca: ${brandName}` : ''}\n
      Proceso de pago para la solicitud de registro de marca con id: ${trademarkAppId}`
    return { subject, message }
  },
  authenticatedUserBrandData: (trademarkAppId, brandName, email) => {
    const subject = `Registro de Marca: Datos de la Marca con ID: ${trademarkAppId}`
    const message = `Usuario: ${email}\n
    ${brandName ? `Marca: ${brandName}` : ''}\n
      Proceso de datos complementarios para la marca: ${trademarkAppId}`
    return { subject, message }
  },
  authenticatedUserApplicantData: (trademarkAppId, brandName, email) => {
    const subject = `Registro de Marca: Datos del Solicitante para la Marca con ID: ${trademarkAppId}`
    const message = `Usuario: ${email}\n
    ${brandName ? `Marca: ${brandName}` : ''}\n
      Proceso de llenado de datos del solicitante de la marca: ${trademarkAppId}`
    return { subject, message }
  },
  authenticatedUserSummary: (trademarkAppId, brandName, email) => {
    const subject = `Resúmen de solicitud de registro de la marca: ${trademarkAppId}`
    const message = `Usuario: ${email}\n
    ${brandName ? `Marca: ${brandName}` : ''}\n
      Resúmen de solicitud de registro de la marca: ${trademarkAppId}`
    return { subject, message }
  },
  authenticatedUserBrandDetail: (trademarkAppId, brandName, email) => {
    const subject = `Detalle de la marca: ${trademarkAppId}`
    const message = `Usuario: ${email}\n
    ${brandName ? `Marca: ${brandName}` : ''}\n
      Detalle de la marca: ${trademarkAppId}`
    return { subject, message }
  },
  authenticatedUserPaymentRequest: (brandId, paymentRequestId, email) => {
    const subject = `Solicitud de Pago: ${paymentRequestId} para la Marca: ${brandId}`
    const message = `Usuario: ${email}\n
    Solicitud de Pago con ID: ${paymentRequestId} perteneciente a la marca con ID: ${brandId}`
    return { subject, message }
  },
  authenticatedUserPaymentRequestCheckout: (
    brandId,
    paymentRequestId,
    email
  ) => {
    const subject = `Solicitud de Pago (Checkout): ${paymentRequestId} para la Marca: ${brandId}`
    const message = `Usuario: ${email}\n
      Solicitud de Pago (Checkout) con ID: ${paymentRequestId} perteneciente a la marca con ID: ${brandId}`
    return { subject, message }
  },
}
