const dict = {
  es: {
    'Sign in to your account': 'Inicia sesión en tu cuenta',
    'Sign In': 'Iniciar sesión',
    'Sign in': 'Inicio',
    'Sign Up': 'Regístrate',
    'Username *': 'Usuario *',
    Username: 'Usuario',
    'Password *': 'Contraseña *',
    Password: 'Contraseña',
    'Enter your password': 'Ingresa tu contraseña',
    'Enter your Username': 'Ingresa tu usuario',
    'No account?': '¿No tienes una cuenta?',
    'Create account': 'Crea una cuenta',
    'Create Account': 'Crear cuenta',
    'Invalid verification code provided, please try again.':
      'El código proporcionado no es válido. Por favor intenta de nuevo.',
    'Password does not conform to policy: Password must have uppercase characters':
      'La contraseña no cumple con el formato requerido: Debe contener mayúsculas.',
    'The following fields need to be filled out: Email, Password':
      'Los siguientes campos deben de llenarse: Correo electrónico, Contraseña',
    'The following fields need to be filled out: Email, Contraseña':
      'Los siguientes campos deben de llenarse: Correo electrónico, Contraseña',
    'The following fields need to be filled out: Email':
      'Los siguientes campos deben de llenarse: Correo electrónico',
    'Password did not conform with policy: Password must have symbol characters':
      'La contraseña no cumple con el formato requerido: Debe de contener uno de los siguientes símbolos -> ^ $ * . [ ] { } ( ) ? " ! @ # % & /  , > < \' : ; | _ ~ `',
    'The following fields need to be filled out: Password':
      'Los siguientes campos deben de llenarse: Contraseña',
    'The following fields need to be filled out: Contraseña':
      'Los siguientes campos deben de llenarse: Contraseña',
    'Have a code?': '¿Tienes un código?',
    'Code *': 'Código *',
    'Attempt limit exceeded, please try after some time.':
      'Se ha excedido el límite de reenvios. Por favor espere unos minutos antes de intentar de nuevo.',
    'User cannot be confirmed. Current status is CONFIRMED':
      'El usuario no puede volverse a confirmar. Ya se encuentra confirmado.',
    Confirm: 'Confirmar',
    Change: 'Cambiar',
    'Reset Password': 'Restablecer contraseña',
    'Reset password': 'Restablecer contraseña',
    'Reset your password': 'Reinicia tu contraseña',
    'Username/client id combination not found.':
      'No existe una combinación de ese usuario/contraseña',
    'Back to Sign in': 'Volver al inicio de sesión',
    'You will receive a verification code to reset your password':
      'Recibirás un código de verificación para restablecer tu contraseña',
    'Cannot reset password for the user as there is no registered/verified email or phone_number':
      'No es posible reconfigurar la contraseña para el usuario ya que no hay ningún correo registrado/verificado o un número de teléfono.',
    Submit: 'Enviar',
    Verify: 'Verificar',
    'Verify Contact': 'Verificar contacto',
    Skip: 'Omitir',
    'Lost your code?': '¿Perdiste tu código?',
    Resend: 'Reenviar',
    'You will receive a verification code':
      'Recibirás un codigo de verificación',
    Code: 'Código',
    'Resend Code': 'Reenviar código',
    'Enter the username again': 'Ingresar nuevamente el usuario',
    'Account recovery requires verified contact information':
      'La recuperación de la cuenta requiere información de contacto verificada',
    'User does not exist': 'El usuario no existe',
    'User already exists': 'El usuario ya existe',
    'Incorrect username or password':
      'Nombre de usuario o contraseña incorrecta',
    'Invalid password format': 'Formato de contraseña inválido',
    'Invalid phone number format':
      'Formato de número de teléfono inválido. Utiliza el formato de número de teléfono +12345678900',
    'Username cannot be empty': 'El nombre de usuario no puede estar vacío',
    'Custom auth lambda trigger is not configured for the user pool.':
      'No está permitido el inicio de sesión sin contraseña',
    'Incorrect username or password.': 'Usuario o contraseña incorrecto(s)',
    'Password attempts exceeded':
      'Has excedido el tiempo de reintentos, espera unos segundos',
    'Forgot your password? ': '¿Olvidaste tu contraseña?',
    'No account? ': '¿No tienes una cuenta?',
    'No account? Sign up here': '¿No tienes una cuenta? Regístrate aquí',
    'Send Code': 'Enviar código',
    'Back to Sign In': 'Regresa al inicio de sesión',
    'Passwords do not match': 'Las contraseñas no coinciden',
    'Confirm Password': 'Confirmar contraseña',
    'Current Password': 'Contraseña actual',
    Email: 'Correo electrónico',
    'Return to Login': 'Iniciar sesión',
    'New password': 'Nueva contraseña',
    'Have an account?': '¿Ya tienes cuenta?',
    'Sign Out': 'Cerrar sesión',
    'Cancel Sign In': 'Cancelar',
    'Cancel Create Account': 'Cancelar',
    'Change Password': 'Cambiar contraseña',
    'Change password': 'Cambiar contraseña',
    Settings: 'Configuración',
    'My Files': 'Mis archivos',
    Recent: 'Recientes',
    Starred: 'Favoritos',
    'Shared with me': 'Compartidos',
    Notifications: 'Notificaciones',
    Trademarks: 'Marcas registradas',
    Trash: 'Basura',
    'More information': 'Más información',
    // Registration
    'What is this type of brand?': '¿Qué es este tipo de marca?',
    'Already have an account? Login here': '¿Cliente existente? Ingresar',
    'If you are already a customer': 'Si ya eres cliente',
    'Enter here': 'Ingresa aquí',
    Physical: 'Física',
    Moral: 'Moral',
    'There are no notifications': 'No hay notificaiones',
    'There are no registered trademarks': 'No hay ninguna marca registrada',
    received: 'RECIBIDA',
    paid: 'PAGADA',
    inProcess: 'EN PROCESO',
    presented: 'PRESENTADA',
    finalized: 'FINALIZADA',
    created: 'CREADA',
    granted: 'OTORGADA',
    denied: 'RECHAZADA',
    cancelled: 'CANCELADA',
  },
}

export default dict
