import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[500],
  },
}))

export const Copyright = (props) => {
  const { name, site } = props
  const classes = useStyle()

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typography className={classes.root} align="center" {...props}>
      <Link color="inherit" href={site}>
        {name}
      </Link>{' '}
      {`Copyright ©  ${new Date().getFullYear()}`}
      {/* {'.'} */}
    </Typography>
  )
}

Copyright.propTypes = {
  name: PropTypes.string,
  site: PropTypes.string,
}

Copyright.defaultProps = {
  name: undefined,
  site: 'https://material-ui.com/',
}

export default Copyright
