import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Button,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  ListSubheader,
  ListItem,
  ListItemText,
  Chip,
  Paper,
} from '@material-ui/core'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import InfoIcon from '@material-ui/icons/Info'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import _ from 'lodash'
import { useTrademarkContext, useLayoutContext } from '../../components/context'
import {
  mapIcon,
  brandTypes,
  BrandTypeModal,
  currencyFormat,
  withMainAuthenticator,
} from '../../components'
import { readTrademarkApplication } from '../../services'
import { useInfoModal } from '../../hooks'
import {
  brandClassColors,
  emailContactMessages,
  MAILTO_ADDRESS,
} from '../../config'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
  },
  summaryContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
    marginBottom: theme.spacing(2),
  },
  brandName: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    '& > div.content > span.title': {
      textTransform: 'none',
    },
  },
  cardContent: {
    textAlign: 'left',
  },
  cardLegends: {
    fontWeight: 'bold',
  },
  nextButtons: {
    marginTop: theme.spacing(2),
  },
  nextButtonContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  nextButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  productsServices: {
    textAlign: 'left',
    overflowY: 'auto',
    listStyle: 'none',
    maxHeight: theme.spacing(50),
    [theme.breakpoints.down('lg')]: {
      maxHeight: theme.spacing(48),
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: theme.spacing(45),
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacing(35),
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: theme.spacing(30),
    },
  },
  /* Styles applied to the chips group's label elements. */
  chipsGroupLabel: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
    top: -8,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0.5),
    },
  },
  /* Styles applied to the chips group's ul elements. */
  chipsGroupUl: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: 0,
    '&': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    },
  },
  productServiceItem: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
    '& > div > span': {
      fontSize: '0.8rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.85rem',
      },
    },
  },
}))

// https://stackoverflow.com/questions/57685230/material-ui-dynamic-rule-names-with-makestyles
const useStyleClasses = makeStyles((theme) => {
  let stylesObject = {}

  Object.entries(brandClassColors).forEach(([key, value]) => {
    stylesObject = {
      ...stylesObject,
      ...{
        [`class_${key}`]: {
          color: value,
        },
        [`classChip_${key}`]: {
          color: value,
          marginLeft: theme.spacing(1),
          marginBottom: theme.spacing(1),
        },
        [`classIconChip_${key}`]: {
          color: value,
        },
      },
    }
  })
  return stylesObject
})

const RequestSummary = () => {
  const classes = useStyles()
  const styleClasses = useStyleClasses()
  const [expanded, setExpanded] = React.useState(false)
  const [selectedBrandType, setSelectedBrandType] = useState(null)

  const {
    setBrand,
    trademarkApp,
    setTrademarkApp,
    trademarkAppID,
  } = useTrademarkContext()
  const {
    authenticated,
    setLoading,
    setEmailContactStatus,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const { handleSubmit } = useForm({})

  const { openModal } = useInfoModal()

  useEffect(() => {
    setContactSpeedDialEnabled(true)
    const email = emailContactMessages.authenticatedUserSummary(
      trademarkApp?.brandData?.id,
      trademarkApp?.brandData?.name,
      trademarkApp?.owner
    )

    setEmailContactStatus({
      email: MAILTO_ADDRESS,
      subject: email?.subject,
      body: email?.message,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [emailContactMessages, setEmailContactStatus])

  const retrieveTrademarkApplication = async () => {
    setLoading(true)
    const trademarkAppReaded = await readTrademarkApplication({
      id: trademarkAppID,
      authenticated,
    })
    setTrademarkApp({ ...trademarkAppReaded })
    const brandType = brandTypes.find(
      (b) => b.id === trademarkAppReaded?.brandData?.typeID
    )
    setSelectedBrandType(brandType)
    setLoading(false)
  }

  useEffect(() => {
    retrieveTrademarkApplication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const openInformationModal = ({ id, title, text, images }) => {
    openModal({
      title: '',
      content: (
        <BrandTypeModal id={id} title={title} text={text} images={images} />
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  // eslint-disable-next-line no-unused-vars
  const onSubmit = async (data) => {
    setLoading(true)
    setTimeout(() => {
      setBrand(null)
      setLoading(false)
    }, 2000)
  }

  const render = trademarkApp && selectedBrandType
  if (!render) return null

  const { Icon: BrandIcon } = mapIcon(selectedBrandType)

  const renderProductsServices = (productsServices) => {
    if (!productsServices || productsServices.length === 0) return null
    return (
      <>
        {productsServices.map((item) => (
          <ListItem
            key={`item-${item.id}`}
            className={classes.productServiceItem}>
            <ListItemText
              primary={`- ${item.name}`}
              className={classes.productServiceItemText}
            />
          </ListItem>
        ))}
      </>
    )
  }

  const renderClasssProductsServices = (brandClass) => {
    return (
      <>
        <li key={brandClass.id}>
          <ListSubheader
            className={clsx(
              styleClasses[`class_${brandClass?.classID}`],
              classes.chipsGroupLabel
            )}
            component="div">
            <Chip
              // eslint-disable-next-line react/no-array-index-key
              key={`classID_${brandClass.id}`}
              variant="outlined"
              size="small"
              label={brandClass.class.name}
              className={clsx(styleClasses[`classChip_${brandClass?.classID}`])}
              icon={
                <ClassOutlinedIcon
                  className={clsx(
                    classes.chipIcon,
                    styleClasses[`classIconChip_${brandClass?.classID}`]
                  )}
                />
              }
            />
          </ListSubheader>
          <ul className={classes.chipsGroupUl}>
            {renderProductsServices(brandClass.productsServices)}
          </ul>
        </li>
      </>
    )
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <Container>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            className={classes.summaryContainer}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="center"
                className={classes.title}
                gutterBottom>
                ¡Tu solicitud de registro de marca ha sido exitosa!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={8}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.brandName}
                  avatar={<ClassOutlinedIcon />}
                  title={trademarkApp?.brandData?.name}
                />
                <CardContent>
                  <Grid container spacing={1} className={classes.cardContent}>
                    <Grid item xs={6} sm={6}>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        component="div"
                        className={classes.cardLegends}>
                        Tipo de marca:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        component="div">
                        <BrandIcon fontSize="inherit" />
                        {'   '}
                        {trademarkApp?.brandData?.type?.name}
                        <IconButton
                          aria-label="info"
                          size="small"
                          onClick={() => {
                            openInformationModal({
                              id: selectedBrandType.id,
                              title: selectedBrandType.name,
                              text: selectedBrandType.helperPopOverText,
                              images: selectedBrandType.helperImages,
                            })
                          }}>
                          {'   '}
                          <InfoIcon fontSize="inherit" />
                        </IconButton>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        component="div"
                        className={classes.cardLegends}>
                        Pago total realizado:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        component="div"
                        className={classes.cardLegends}>
                        {currencyFormat(
                          trademarkApp?.paymentTransaction?.chargeReference
                            ?.amount,
                          '$  ',
                          2
                        )}{' '}
                        MXN
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions disableSpacing>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    component="div"
                    className={classes.cardLegends}>
                    Producto(s) y/o servicio(s) contratado(s):
                  </Typography>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="Mostrar más">
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Paper
                      className={classes.productsServices}
                      variant="outlined">
                      {_.chain(trademarkApp?.brandClasses?.items)
                        .sortBy((bc) => Number(bc.classID))
                        .map((bc) => {
                          const productsServices = _.chain(
                            bc?.productsServices?.items
                          )
                            .map((ps) => {
                              return {
                                id: ps?.productService?.id,
                                name: ps?.productService?.name,
                              }
                            })
                            .sortBy((ps) => ps.name)
                            .value()
                          const brandClasss = {
                            id: bc.id,
                            classID: bc.classID,
                            class: bc.class,
                            productsServices,
                          }
                          return renderClasssProductsServices(brandClasss)
                        })
                        .value()}
                    </Paper>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          </Grid>
          <div className={classes.nextButtons}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              className={classes.nextButtonContainer}>
              <Grid
                item
                xs={10}
                sm={4}
                md={3}
                lg={2}
                className={classes.nextButton}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth>
                  Salir
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </form>
  )
}

export default withMainAuthenticator(RequestSummary)
