import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  useMediaQuery,
  Container,
  Button,
  Grid,
  Hidden,
  FormControl,
  FormLabel,
  Typography,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined'
import InfoIcon from '@material-ui/icons/Info'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import _ from 'lodash'
import { parse, format, subMonths, subDays, isFuture, isAfter } from 'date-fns'
import { FormField, FormFieldDateTimePicker } from '../common'
import {
  useLayoutContext,
  useTrademarkContext,
  defaultBrandClassesItems,
} from '../context'
import { useInfoModal } from '../../hooks'
import {
  postAllBrandProductsServices,
  putAllBrandProductsServices,
  removeAllBrandProductsServices,
  putBrand,
} from '../../services'
import BrandTypeModal from './BrandTypeModal'
import ProductsAndServicesSearchField from './ProductsAndServicesSearchField'

const useStyles = makeStyles((theme) => ({
  noLabel: {
    marginTop: theme.spacing(1),
  },
  containerOptions: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  formLabel: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    marginLeft: theme.spacing(0.5),
  },
  formOption: {
    textAlign: 'left',
    marginLeft: theme.spacing(1.5),
  },
  brandFieldsContainer: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  brandFieldsGrid: {
    padding: theme.spacing(0),
  },
  hidden: {
    display: 'none',
  },
  actionButtons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
  actionButtonContainer: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  actionButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  saveButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.common.white,
    },
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'center',
  },
}))

// eslint-disable-next-line no-unused-vars
const ClassForm = ({ brandClass, index, countries }) => {
  const classes = useStyles()

  const brand = {
    id: brandClass.id,
    classID: brandClass.classID,
    class: brandClass.class,
    productsServices: brandClass.productsServices?.items?.map((ps) => {
      return { ...ps.productService }
    }),
    hasBeenRegisteredAbroad: brandClass.hasBeenRegisteredAbroad,
    registrationDateAbroad: brandClass.registrationDateAbroad
      ? parse(brandClass.registrationDateAbroad, 'yyyy-MM-ddxxx', new Date())
      : undefined,
    registrationNumberAbroad: brandClass.registrationNumberAbroad,
    countryOfRegistrationAbroad: brandClass.countryOfRegistrationAbroad,
    // eslint-disable-next-line no-underscore-dangle
    _version: brandClass._version,
  }

  const {
    trademarkApp,
    setBrandClassesItems,
    setClassFormIsDirty,
  } = useTrademarkContext()
  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    formState,
    reset,
  } = useForm({
    defaultValues: { ...brand },
  })
  const { isDirty } = formState
  const { openModal } = useInfoModal()

  const hasBeenRegisteredAbroadWatched = watch('hasBeenRegisteredAbroad')

  const { authenticated, setLoading } = useLayoutContext()
  const theme = useTheme()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    setClassFormIsDirty(isDirty)
  }, [setClassFormIsDirty, isDirty])

  const openRestoreModal = () => {
    openModal({
      title: 'Restaurar información',
      content: (
        <div className={classes.infoText}>
          Los cambios generados no persistirán ¿Estás seguro de restaurar la
          información?
        </div>
      ),
      confirmationText: 'Confirmar',
      cancellationText: 'Cerrar',
    })
      .then(() => {
        reset()
      })
      .catch(() => {})
  }

  const openInformationModal = ({ id, title, text, images }) => {
    openModal({
      title: '',
      content: (
        <BrandTypeModal id={id} title={title} text={text} images={images} />
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const {
      id,
      productsServices,
      hasBeenRegisteredAbroad,
      registrationDateAbroad,
      registrationNumberAbroad,
      countryOfRegistrationAbroad,
      _version,
    } = data
    const actualProductsServices = brandClass.productsServices?.items?.map(
      (ps) => {
        return { ...ps.productService }
      }
    )
    // https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
    const deletedValues = _.differenceBy(
      actualProductsServices,
      productsServices,
      'id'
    )
    const addedValues = _.differenceBy(
      productsServices,
      actualProductsServices,
      'id'
    )
    const sameProductsServices = _.intersectionBy(
      actualProductsServices,
      productsServices,
      'id'
    )
    const productsServicesToUpdate = brandClass.productsServices?.items?.filter(
      (ps) => _.some(deletedValues, { id: ps?.productService.id })
    )
    const finalProductsServicesToUpdate = productsServicesToUpdate.map(
      // eslint-disable-next-line no-underscore-dangle
      (ps) => ({ ...ps, deleted: 'true' })
    )
    const updatedProductsServices = await putAllBrandProductsServices({
      productsAndServices: finalProductsServicesToUpdate,
    })
    // eslint-disable-next-line no-unused-vars
    const deletedProductsServices = await removeAllBrandProductsServices({
      productsAndServices: updatedProductsServices,
    })
    const addedProductsServices = await postAllBrandProductsServices({
      brandID: id,
      productsAndServices: addedValues,
    })
    const productsServicesToPreserve = brandClass.productsServices?.items?.filter(
      (ps) => _.some(sameProductsServices, { id: ps?.productService.id })
    )
    const finalProductsServices = productsServicesToPreserve.concat(
      addedProductsServices
    )
    const updatedBrand = await putBrand({
      id,
      hasBeenRegisteredAbroad,
      registrationDateAbroad: registrationDateAbroad
        ? format(registrationDateAbroad, 'yyyy-MM-ddxxx')
        : undefined,
      registrationNumberAbroad,
      countryOfRegistrationAbroad,
      // eslint-disable-next-line no-underscore-dangle
      _version,
    })

    const brandForTrademarkApp = {
      ...brandClass,
      productsServices: {
        items: finalProductsServices.map((ps) => {
          const { brand: psBrand, ...other } = ps
          return { ...other }
        }),
      },
      hasBeenRegisteredAbroad,
      registrationDateAbroad: registrationDateAbroad
        ? parse(registrationDateAbroad, 'yyyy-MM-ddxxx', new Date())
        : undefined,
      registrationNumberAbroad,
      countryOfRegistrationAbroad,
      updatedAt: updatedBrand.updatedAt,
      // eslint-disable-next-line no-underscore-dangle
      _lastChangedAt: updatedBrand._lastChangedAt,
      // eslint-disable-next-line no-underscore-dangle
      _version: updatedBrand._version,
    }
    const trademarkAppUpdated = trademarkApp
    trademarkApp.brandClasses.items[index] = brandForTrademarkApp

    setBrandClassesItems([...trademarkAppUpdated?.brandClasses?.items])
    // Reset Form Values with updated Data
    reset({
      ...brand,
      id,
      productsServices: finalProductsServices.map((ps) => {
        return { ...ps.productService }
      }),
      hasBeenRegisteredAbroad,
      registrationDateAbroad,
      registrationNumberAbroad,
      countryOfRegistrationAbroad,
      // eslint-disable-next-line no-underscore-dangle
      _version: updatedBrand._version,
    })
    setLoading(false)
  }

  // https://github.com/react-hook-form/react-hook-form/issues/1025
  // https://github.com/react-hook-form/react-hook-form/issues/1005
  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit(onSubmit)()
        e.stopPropagation()
      }}>
      <Container className={classes.brandFieldsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.brandFieldsGrid}>
            <FormField
              id="id"
              name="id"
              label="id"
              required
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel, classes.hidden)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: 'id',
                },
              }}
              errors={errors}
              type="hidden"
            />
          </Grid>
          <Grid item xs={12} className={classes.brandFieldsGrid}>
            <FormField
              id="_version"
              name="_version"
              label="_version"
              required
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel, classes.hidden)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: '_version',
                },
              }}
              errors={errors}
              type="hidden"
            />
          </Grid>
          {isDirty && (
            <Grid item xs={12} className={classes.brandFieldsGrid}>
              <Typography variant="subtitle2" color="error">
                Es necesario que confirmes los cambios para continuar
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.brandFieldsGrid}>
            <Typography variant="subtitle1" color="textPrimary">
              Clase {brand?.classID}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ProductsAndServicesSearchField
              id="productsServices"
              name="productsServices"
              // required={false}
              required
              label={
                isMobileDevice
                  ? 'Producto(s) o servicio(s)'
                  : 'Producto(s) o servicio(s) que deseas proteger'
              }
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              noOptionsText={
                isMobileDevice
                  ? 'Ingresa un término'
                  : 'Ingresa un término para mostrar las coincidencias'
              }
              GroupIcon={ClassOutlinedIcon}
              Icon={WidgetsOutlinedIcon}
              InputIcon={SearchIcon}
              freeSolo={false}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Selecciona al menos un producto o servicio',
                },
                // https://github.com/react-hook-form/react-hook-form/issues/34
                // https://github.com/react-hook-form/react-hook-form/issues/1617
                validate: (value) => {
                  return (
                    (Array.isArray(value) && value?.length > 0) ||
                    'Selecciona al menos un producto o servicio'
                  )
                },
              }}
              errors={errors}
              // multiple={false}
              multiple
              autoComplete
              disableCloseOnSelect
              includeInputInList={false}
              filterSelectedOptions={false}
              // filterSelectedOptions
              waitForFetch={200}
              classID={brand?.classID}
              authenticated={authenticated}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl
              size="small"
              variant="outlined"
              className={clsx(classes.margin, classes.noLabel)}
              required={false}
              disabled={false}
              fullWidth>
              <Container className={clsx(classes.containerOptions)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel
                      component="legend"
                      className={clsx(
                        classes.margin,
                        classes.noLabel,
                        classes.formLabel
                      )}>
                      Datos de la prioridad
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      id="hasBeenRegisteredAbroad"
                      name="hasBeenRegisteredAbroad"
                      type="switch"
                      label={
                        <>
                          <Typography variant="body1" color="primary">
                            Has solicitado esta marca en el extranjero
                            <IconButton
                              aria-label="info"
                              size="small"
                              onClick={() => {
                                openInformationModal({
                                  id: 'hasBeenRegisteredAbroad',
                                  title:
                                    'Has solicitado esta marca en el extranjero',
                                  text:
                                    'Marcar esta casilla solo si solicitaste la marca en el extranjero durante los últimos 6 meses.',
                                  images: [],
                                })
                              }}>
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Typography>
                        </>
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.formOption)}
                      labelClassName={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: '',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  {hasBeenRegisteredAbroadWatched && (
                    <Grid item xs={12} sm={6}>
                      <FormFieldDateTimePicker
                        id="registrationDateAbroad"
                        name="registrationDateAbroad"
                        type="date"
                        label="Fecha de presentación"
                        required
                        variant="dialog"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        control={control}
                        rules={{
                          required: {
                            value: hasBeenRegisteredAbroadWatched || false,
                            message: 'Ingresa la fecha en la que se registró',
                          },
                          validate: {
                            futureDate: (value) => {
                              return (
                                !isFuture(value) ||
                                'La fecha debe ser menor o igual al dia actual'
                              )
                            },
                            after6Months: (value) => {
                              return (
                                !isAfter(
                                  subDays(subMonths(new Date(), 6), 1),
                                  value
                                ) ||
                                'La fecha debe estar dentro de los últimos 6 meses'
                              )
                            },
                          },
                        }}
                        errors={errors}
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: 'start' }}
                        autoOk
                        disableFuture
                        format="dd/MM/yyyy"
                        invalidDateMessage="Fecha inválida"
                        invalidLabel="Fecha inválida"
                        mask="__/__/____"
                        cancelLabel="CANCELAR"
                        clearable
                        clearLabel="LIMPIAR"
                        okLabel="OK"
                        showTodayButton
                        todayLabel="HOY"
                        minDate={subMonths(new Date(), 6)}
                        minDateMessage="La fecha debe estar dentro de los últimos 6 meses"
                      />
                    </Grid>
                  )}
                  {hasBeenRegisteredAbroadWatched && (
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="registrationNumberAbroad"
                        name="registrationNumberAbroad"
                        label="Número"
                        required
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: hasBeenRegisteredAbroadWatched || false,
                            message: 'Ingresa el número de registro',
                          },
                        })}
                        errors={errors}
                      />
                    </Grid>
                  )}
                  {hasBeenRegisteredAbroadWatched && (
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="countryOfRegistrationAbroad"
                        name="countryOfRegistrationAbroad"
                        type="select"
                        label="País"
                        required
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        control={control}
                        rules={{
                          required: {
                            value: hasBeenRegisteredAbroadWatched || false,
                            message: 'Selecciona el país de registro',
                          },
                        }}
                        errors={errors}
                        options={countries}
                      />
                    </Grid>
                  )}
                  <Hidden xsDown>
                    <Grid item sm={6} />
                  </Hidden>
                </Grid>
              </Container>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.actionButtons}>
              {isDirty && (
                <>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.actionButtonContainer}>
                    <Grid
                      item
                      xs={10}
                      sm={4}
                      md={3}
                      lg={2}
                      className={classes.actionButton}>
                      <Button
                        onClick={() => {
                          openRestoreModal()
                        }}
                        variant="contained"
                        color="secondary"
                        fullWidth>
                        Restaurar
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      sm={4}
                      md={3}
                      lg={2}
                      className={classes.actionButton}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.saveButton}>
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </form>
  )
}

ClassForm.propTypes = {
  brandClass: PropTypes.shape({
    id: PropTypes.string,
    brandDataID: PropTypes.string,
    classID: PropTypes.string,
    trademarkApplicationID: PropTypes.string,
    hasBeenRegisteredAbroad: PropTypes.bool,
    registrationDateAbroad: PropTypes.string,
    registrationNumberAbroad: PropTypes.string,
    countryOfRegistrationAbroad: PropTypes.string,
    brandStage: PropTypes.string,
    brandStatus: PropTypes.string,
    productsServices: {
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          productServiceID: PropTypes.string,
          productService: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            classID: PropTypes.string,
            class: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
              description: PropTypes.string,
              status: PropTypes.string,
              createdAt: PropTypes.string,
              updatedAt: PropTypes.string,
              _version: PropTypes.number,
              _deleted: PropTypes.bool,
              _lastChangedAt: PropTypes.number,
            }),
            baseNumber: PropTypes.string,
            status: PropTypes.string,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
            _version: PropTypes.number,
            _deleted: PropTypes.bool,
            _lastChangedAt: PropTypes.number,
          }),
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string,
          _version: PropTypes.number,
          _deleted: PropTypes.bool,
          _lastChangedAt: PropTypes.number,
        })
      ),
      nextToken: PropTypes.string,
      startedAt: PropTypes.string,
    },
    class: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      status: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      _version: PropTypes.number,
      _deleted: PropTypes.bool,
      _lastChangedAt: PropTypes.number,
    }),
    owner: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    _version: PropTypes.number,
    _deleted: PropTypes.string,
    _lastChangedAt: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
}

ClassForm.defaultProps = {
  brandClass: defaultBrandClassesItems,
}
export default ClassForm
