/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import { SignOut } from 'aws-amplify-react'
import { navigate } from '@reach/router'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import PropTypes from 'prop-types'

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

export class MaterialSignOut extends SignOut {
  render() {
    const {
      Icon,
      setOpenedMenu,
      setCollapsedMenu,
      disableNotificationsView,
      clearData,
      hide,
      disableTrademarksAndNotifications,
    } = this.props

    if (hide && hide.includes(MaterialSignOut)) {
      return null
    }

    const authState = this.props.authState || this.state.authState
    const signedIn = authState === 'signedIn'

    if (!signedIn) {
      return null
    }

    return (
      <>
        <ListItem
          key="Sign Out"
          selected={this.props.selected}
          button
          onClick={() => {
            setOpenedMenu(false)
            setCollapsedMenu(false)
            disableNotificationsView(false)
            clearData()
            disableTrademarksAndNotifications()
            navigate('/')
            this.signOut()
          }}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={I18n.get('Sign Out')} />
        </ListItem>
      </>
    )
  }
}

MaterialSignOut.propTypes = {
  selected: PropTypes.bool.isRequired,
  Icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    null,
    undefined,
  ]),
  setOpenedMenu: PropTypes.func,
  setCollapsedMenu: PropTypes.func,
  disableNotificationsView: PropTypes.func,
  clearData: PropTypes.func,
  disableTrademarksAndNotifications: PropTypes.func,
}

MaterialSignOut.defaultProps = {
  Icon: ExitToAppIcon,
  setOpenedMenu: () => {},
  setCollapsedMenu: () => {},
  disableNotificationsView: () => {},
  clearData: () => {},
  disableTrademarksAndNotifications: () => {},
}

export default MaterialSignOut
