import React, { createContext, useContext, useState, useEffect } from 'react'
import { useStorageState } from 'react-storage-hooks'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import useInfoModal from '../../hooks/useInfoModal'
import { useLayoutContext } from './LayoutContext'
import {
  WHATSAPP_PHONE,
  MAILTO_ADDRESS,
  whatsappContactMessages,
  emailContactMessages,
} from '../../config'

export const defaultBrandData = {
  generalData: {
    brandType: null,
    brandName: null,
    brandFiles: [],
    email: null,
    searchProductsAndServices: [],
    applicantKind: 'physical',
    applicantName: null,
    applicantSurname: null,
    applicantSecondSurname: null,
    emailConfirmation: null,
    phoneNumber: null,
    phoneNumberDialCode: 'MX',
    paymentMethod: null,
  },
}

export const defaultType = {
  id: null,
  name: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
  createdAt: null,
  updatedAt: null,
}

export const defaultDomicile = {
  country: null,
  postalCode: null,
  state: null,
  county: null,
  suburb: null,
  street: null,
  externalNumber: null,
  internalNumber: null,
}

export const defaultApplicant = {
  id: null,
  name: null,
  surname: null,
  secondSurname: null,
  kind: null,
  rfc: null,
  nationality: null,
  countryCallingCode: null,
  phoneNumber: null,
  extension: null,
  domicile: defaultDomicile,
  email: null,
  owner: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
}

export const defaultClass = {
  id: null,
  name: null,
  description: null,
  status: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
}

export const defaultProductService = {
  id: null,
  name: null,
  classID: null,
  class: defaultClass,
  baseNumber: null,
  status: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
}

export const defaultProductsServicesPreAppItems = {
  id: null,
  productServiceID: null,
  productService: defaultProductService,
  trademarkPreApplicationID: null,
  owner: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
}

export const defaultProductsServicesBrandItems = {
  id: null,
  productServiceID: null,
  productService: defaultProductService,
  brandID: null,
  owner: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
}

export const defaultFiles = {
  bucket: null,
  region: null,
  key: null,
  level: null,
  identityID: null,
  name: null,
}

export const defaultChargeReference = {
  id: null,
  openpayChargeID: null,
  paymentTransactionID: null,
  orderID: null,
  amount: null,
  method: null,
  barcodeUrl: null,
  documentUrl: null,
  dueDate: null,
  owner: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
}

export const defaultPaymentTransaction = {
  id: null,
  status: null,
  transactionType: null,
  chargeReferenceID: null,
  owner: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
  chargeReference: defaultChargeReference,
}

export const defaultTrademarkPreApplication = {
  id: null,
  name: null,
  email: null,
  typeID: null,
  files: [defaultFiles],
  applicantName: null,
  applicantSurname: null,
  applicantSecondSurname: null,
  applicantKind: null,
  countryCallingCode: null,
  phoneNumber: null,
  paymentTransactionID: null,
  trademarkApplicationStatus: null,
  trademarkApplicationStage: null,
  owner: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
  type: defaultType,
  productsServices: {
    items: [defaultProductsServicesPreAppItems],
    nextToken: null,
    startedAt: null,
  },
  paymentTransaction: defaultPaymentTransaction,
}

export const defaultBrandDataApp = {
  id: null,
  name: null,
  typeID: null,
  files: [defaultFiles],
  hasTransliteration: null,
  transliteration: null,
  hasTranslation: null,
  translation: null,
  hasBeenUsed: null,
  dateOfInitialUse: null,
  applicantID: null,
  sameAddressForEstablishment: null,
  establishmentDomicile: defaultDomicile,
  mustBill: null,
  invoiceforApplicant: null,
  applicantBillingID: null,
  sameAddressForBilling: null,
  owner: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
  type: defaultType,
  applicant: defaultApplicant,
  applicantBilling: defaultApplicant,
}

export const defaultBrandClassesItems = {
  id: null,
  brandDataID: null,
  classID: null,
  trademarkApplicationID: null,
  hasBeenRegisteredAbroad: null,
  registrationDateAbroad: null,
  registrationNumberAbroad: null,
  countryOfRegistrationAbroad: null,
  brandStage: null,
  brandStatus: null,
  productsServices: {
    items: [defaultProductsServicesBrandItems],
    nextToken: null,
    startedAt: null,
  },
  class: defaultClass,
  owner: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
}

export const defaultTrademarkApp = {
  id: null,
  brandDataID: null,
  trademarkPreApplicationID: null,
  paymentTransactionID: null,
  trademarkApplicationStatus: null,
  trademarkApplicationStage: null,
  owner: null,
  createdAt: null,
  updatedAt: null,
  _version: null,
  _deleted: null,
  _lastChangedAt: null,
  trademarkPreApplication: defaultTrademarkPreApplication,
  brandData: defaultBrandDataApp,
  brandClasses: {
    items: [defaultBrandClassesItems],
    nextToken: null,
    startedAt: null,
  },
  paymentTransaction: defaultPaymentTransaction,
}

const defaultContextValue = {
  currentStep: 0,
  setCurrentStep: () => {},
  brand: defaultBrandData,
  defaultBrandData,
  setBrand: () => {},
  filesUploaded: null,
  setFilesUploaded: () => {},
  preApplicationID: null,
  setPreApplicationID: () => {},
  productsServicesCreated: null,
  setProductsServicesCreated: () => {},
  paymentTransactionID: null,
  setPaymentTransactionID: () => {},
  trademarkAppID: null,
  setTrademarkAppID: () => {},
  generatedPayment: null,
  setGeneratedPayment: () => {},
  openCancelTradeMarkRegistrationModal: () => {},
  reconfigureSpeedDialValues: () => {},
  trademarkApp: defaultTrademarkApp,
  setTrademarkApp: () => {},
  brandClassesItems: defaultBrandClassesItems,
  setBrandClassesItems: () => {},
  classFormIsDirty: null,
  setClassFormIsDirty: () => {},
  files: null,
  setFiles: () => {},
}

export const TrademarkRegistrationFlowContext = createContext(
  defaultContextValue
)

export const TrademarkRegistrationFlowContextProvider =
  TrademarkRegistrationFlowContext.Provider
export const TrademarkRegistrationFlowContextConsumer =
  TrademarkRegistrationFlowContext.Consumer

TrademarkRegistrationFlowContext.displayName = 'TrademarkRegistrationFlowCtx'

export const useTrademarkContext = () => {
  const context = useContext(TrademarkRegistrationFlowContext)
  if (!context) {
    throw new Error(`Context cannot be used outside the Provider`)
  }
  return context
}

export const TrademarkRegistrationFlowConsumer = (props) => {
  const { children } = props
  return (
    <>
      {typeof children === 'function' ? (
        <TrademarkRegistrationFlowContextConsumer>
          {(ctx) => {
            if (ctx === undefined) {
              throw new Error(
                'TrademarkRegistrationFlowContextConsumer must be used within a TrademarkRegistrationFlowConsumer'
              )
            }
            return children({ ...ctx })
          }}
        </TrademarkRegistrationFlowContextConsumer>
      ) : (
        children
      )}
    </>
  )
}

TrademarkRegistrationFlowConsumer.defaultProps = {
  children: undefined,
}

TrademarkRegistrationFlowConsumer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
}

const useStyles = makeStyles((theme) => ({
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'center',
  },
}))

export const TrademarkRegistrationFlowProvider = (props) => {
  const { children } = props
  const classes = useStyles()
  const { openModal } = useInfoModal()

  // https://github.com/soyguijarro/react-storage-hooks
  const [brandStorage, setBrandStorage] = useStorageState(localStorage, 'brand')
  const [currentStepStorage, setCurrentStepStorage] = useStorageState(
    localStorage,
    'currentStep'
  )
  const [filesUploadedStorage, setFilesUploadedStorage] = useStorageState(
    localStorage,
    'filesUploaded'
  )
  const [preApplicationIDStorage, setPreApplicationIDStorage] = useStorageState(
    localStorage,
    'preApplicationID'
  )
  const [
    productsServicesCreatedStorage,
    setProductsServicesCreatedStorage,
  ] = useStorageState(localStorage, 'productsServicesCreated')
  const [
    paymentTransactionIDStorage,
    setPaymentTransactionIDStorage,
  ] = useStorageState(localStorage, 'paymentTransactionID')
  const [trademarkAppIDStorage, setTrademarkAppIDStorage] = useStorageState(
    localStorage,
    'trademarkAppID'
  )
  const [generatedPaymentStorage, setGeneratedPaymentStorage] = useStorageState(
    localStorage,
    'generatedPayment'
  )
  const [trademarkAppStorage, setTrademarkAppStorage] = useStorageState(
    localStorage,
    'trademarkApp'
  )
  const [currentStep, setCurrentStep] = useState(currentStepStorage)
  const [brand, setBrand] = useState(brandStorage)
  const [filesUploaded, setFilesUploaded] = useState(filesUploadedStorage)
  const [preApplicationID, setPreApplicationID] = useState(
    preApplicationIDStorage
  )
  const [productsServicesCreated, setProductsServicesCreated] = useState(
    productsServicesCreatedStorage
  )
  const [paymentTransactionID, setPaymentTransactionID] = useState(
    paymentTransactionIDStorage
  )
  const [trademarkAppID, setTrademarkAppID] = useState(trademarkAppIDStorage)
  const [generatedPayment, setGeneratedPayment] = useState(
    generatedPaymentStorage
  )
  const [trademarkApp, setTrademarkApp] = useState(trademarkAppStorage)
  const [brandClassesItems, setBrandClassesItems] = useState(null)
  const [classFormIsDirty, setClassFormIsDirty] = useState(null)
  const [files, setFiles] = useState(null)

  const {
    setLoading,
    setFirstSignUp,
    currentUser,
    setContactSpeedDialEnabled,
    setWhatsappContactStatus,
    setEmailContactStatus,
  } = useLayoutContext()

  const openCancelTradeMarkRegistrationModal = () => {
    openModal({
      title: 'Cancelar registro de marca',
      content: (
        <div className={classes.infoText}>
          ¿Estás seguro de cancelar el proceso de registro para tu marca?
        </div>
      ),
      confirmationText: 'Confirmar',
      cancellationText: 'Cerrar',
    })
      .then(() => {
        setLoading(true)
        setTimeout(() => {
          setBrand(null)
          setLoading(false)
        }, 200)
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (brand) {
      // https://ultimatecourses.com/blog/remove-object-properties-destructuring
      const {
        // eslint-disable-next-line no-unused-vars
        generalData: { brandFiles, ...other },
      } = brand
      setBrandStorage({ generalData: { ...other, brandFiles: [] } })
    } else {
      setBrandStorage(null)
      setCurrentStep(null)
      setFilesUploaded(null)
      setPreApplicationID(null)
      setProductsServicesCreated(null)
      setPaymentTransactionID(null)
      setTrademarkAppID(null)
      setGeneratedPayment(null)
      setFirstSignUp(true)
      setTrademarkApp(null)
      setBrandClassesItems(null)
      setClassFormIsDirty(null)
      setFiles(null)
    }
  }, [brand, setBrandStorage, setFirstSignUp])

  useEffect(() => {
    setCurrentStepStorage(currentStep)
  }, [currentStep, setCurrentStepStorage])

  useEffect(() => {
    setFilesUploadedStorage(filesUploaded)
  }, [filesUploaded, setFilesUploadedStorage])

  useEffect(() => {
    setPreApplicationIDStorage(preApplicationID)
  }, [preApplicationID, setPreApplicationIDStorage])

  useEffect(() => {
    setProductsServicesCreatedStorage(productsServicesCreated)
  }, [productsServicesCreated, setProductsServicesCreatedStorage])

  useEffect(() => {
    setPaymentTransactionIDStorage(paymentTransactionID)
  }, [paymentTransactionID, setPaymentTransactionIDStorage])

  useEffect(() => {
    setTrademarkAppIDStorage(trademarkAppID)
  }, [trademarkAppID, setTrademarkAppIDStorage])

  useEffect(() => {
    setGeneratedPaymentStorage(generatedPayment)
  }, [generatedPayment, setGeneratedPaymentStorage])

  useEffect(() => {
    setTrademarkAppStorage(trademarkApp)
  }, [trademarkApp, setTrademarkAppStorage])

  const reconfigureSpeedDialValues = () => {
    let whatsapp = null
    let email = null
    switch (currentStep) {
      case 0:
        setContactSpeedDialEnabled(true)
        whatsapp = whatsappContactMessages.unauthenticatedUserBrandRegistration(
          brand?.generalData?.brandName,
          brand?.generalData?.email
        )

        email = emailContactMessages.authenticatedUserBrandRegistration(
          brand?.generalData?.brandName,
          brand?.generalData?.email ?? currentUser.username
        )

        setWhatsappContactStatus({
          number: WHATSAPP_PHONE,
          text: whatsapp,
        })

        setEmailContactStatus({
          email: MAILTO_ADDRESS,
          subject: email?.subject,
          body: email?.message,
        })
        break
      case 1:
        setContactSpeedDialEnabled(true)
        whatsapp = whatsappContactMessages.unauthenticatedUserPayment(
          brand?.generalData?.brandName,
          brand?.generalData?.email
        )

        email = emailContactMessages.authenticatedUserPayment(
          trademarkAppID,
          brand?.generalData?.brandName,
          brand?.generalData?.email
        )

        setWhatsappContactStatus({
          number: WHATSAPP_PHONE,
          text: whatsapp,
        })

        setEmailContactStatus({
          email: MAILTO_ADDRESS,
          subject: email?.subject,
          body: email?.message,
        })
        break
      default:
        break
    }
  }

  return (
    <TrademarkRegistrationFlowContextProvider
      value={{
        currentStep,
        setCurrentStep,
        brand,
        setBrand,
        filesUploaded,
        setFilesUploaded,
        preApplicationID,
        setPreApplicationID,
        productsServicesCreated,
        setProductsServicesCreated,
        paymentTransactionID,
        setPaymentTransactionID,
        trademarkAppID,
        setTrademarkAppID,
        generatedPayment,
        setGeneratedPayment,
        trademarkApp,
        setTrademarkApp,
        brandClassesItems,
        setBrandClassesItems,
        classFormIsDirty,
        setClassFormIsDirty,
        defaultBrandData,
        defaultTrademarkApp,
        openCancelTradeMarkRegistrationModal,
        reconfigureSpeedDialValues,
        files,
        setFiles,
      }}>
      {children}
    </TrademarkRegistrationFlowContextProvider>
  )
}

TrademarkRegistrationFlowProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

TrademarkRegistrationFlowProvider.defaultProps = {
  children: undefined,
}

export const TrademarkRegistrationFlowContextWrapper = (props) => {
  const { children } = props
  return (
    <TrademarkRegistrationFlowProvider>
      {children}
    </TrademarkRegistrationFlowProvider>
  )
}

TrademarkRegistrationFlowContextWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

TrademarkRegistrationFlowContextWrapper.defaultProps = {
  children: undefined,
}

export const withTrademarkRegistrationFlowContex = (Component) => {
  const innerWithTrademarkRegistrationFlowContex = (props) => {
    return (
      <TrademarkRegistrationFlowContextWrapper>
        <Component {...props} />
      </TrademarkRegistrationFlowContextWrapper>
    )
  }
  innerWithTrademarkRegistrationFlowContex.propTypes = {}
  return innerWithTrademarkRegistrationFlowContex
}
