import React from 'react'
import Amplify, { Auth } from 'aws-amplify'
// eslint-disable-next-line import/no-extraneous-dependencies
import { I18n } from '@aws-amplify/core'
import { ThemeProvider } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import MainRoutes from './config/MainRoutes'
import theme from './config/theme'
import awsConfig from './aws-exports'
import dict from './localization'
import 'react-credit-cards/lib/styles.scss'
import { APP_ID } from './config'

I18n.putVocabularies(dict)
I18n.setLanguage('es')

const AUTHMODE = 'AMAZON_COGNITO_USER_POOLS'

Amplify.configure({
  ...awsConfig,
  aws_appsync_authenticationType: AUTHMODE,
})

Auth.configure({
  ...awsConfig,
  aws_appsync_authenticationType: AUTHMODE,
  clientMetadata: {
    appID: APP_ID,
  },
})

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Trademark Solutions</title>
            <link rel="canonical" href="https://www.ppm.com.mx/" />
          </Helmet>
          <MainRoutes />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  )
}

export default App
