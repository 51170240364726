import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
// eslint-disable-next-line no-unused-vars
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import EmailIcon from '@material-ui/icons/Email'
import { useLayoutContext } from '../context'

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  whatsapp: {
    backgroundColor: '#25D366',
  },
  email: {
    backgroundColor: '#0072C6',
  },
}))

const CustomSpeedDial = () => {
  const classes = useStyles()
  const {
    whatsappContactStatus,
    emailContactStatus,
    authenticated,
    // eslint-disable-next-line no-unused-vars
    showAuthUI,
    showTrademarks,
    showNotifications,
    showTrademarkDetail,
    contactSpeedtDialEnabled,
    firstSignUp,
  } = useLayoutContext()
  // eslint-disable-next-line no-unused-vars
  const [direction, setDirection] = React.useState('up')
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [hidden, setHidden] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const location = useLocation()

  useEffect(() => {}, [contactSpeedtDialEnabled, showTrademarks])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const actions = {
    whatsapp: {
      icon: <WhatsAppIcon style={{ color: '#25D366' }} />,
      name: 'Whatsapp',
    },
    email: {
      icon: <EmailIcon style={{ color: '#0072C6' }} />,
      name: 'Email',
    },
  }

  const handleWhatsappAction = () => {
    const url = `https://wa.me/${whatsappContactStatus?.number}/?text=${whatsappContactStatus?.text}`
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    setOpen(false)
  }
  const handleEmailAction = () => {
    const url = `mailto:${emailContactStatus.email}?subject=${emailContactStatus.subject}&body=${emailContactStatus.body}`

    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    setOpen(false)
  }

  const handleAction = (action) => {
    switch (action) {
      case 'Whatsapp':
        handleWhatsappAction()
        return
      case 'Email':
        handleEmailAction()
        return
      default:
        setOpen(false)
    }
  }

  const renderEmailDialAction = () => {
    return (
      <SpeedDialAction
        key={actions.email.name}
        icon={actions.email.icon}
        tooltipTitle={actions.email.name}
        onClick={() => handleAction(actions.email.name)}
      />
    )
  }

  const renderWhatsappDialAction = () => {
    return (
      <SpeedDialAction
        key={actions.whatsapp.name}
        icon={actions.whatsapp.icon}
        tooltipTitle={actions.whatsapp.name}
        onClick={() => handleAction(actions.whatsapp.name)}
      />
    )
  }

  if (!contactSpeedtDialEnabled) return null

  return (!firstSignUp &&
    !showTrademarks &&
    !showNotifications &&
    contactSpeedtDialEnabled) ||
    showTrademarkDetail ? (
    <SpeedDial
      ariaLabel="SpeedDial example"
      className={classes.speedDial}
      hidden={hidden}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction={direction}>
      {authenticated ? renderEmailDialAction() : renderWhatsappDialAction()}
    </SpeedDial>
  ) : null
}

export default CustomSpeedDial
